import { Clone } from 'iresults-ts-core';
import { ClassTypeOptions, ra } from '@cundd/rest-adapter';
import { BaseModel } from '../BaseModel';

@ra(ClassTypeOptions.AddUnknownFields)
export class Address extends BaseModel implements Clone {
    public street: string;
    public houseNumber: string;
    public zip: string;
    public city: string;
    public country: string;

    constructor(
        street: string,
        houseNumber: string | number,
        zip: string | number,
        city: string,
        country: string
    ) {
        super();
        this.country = country;
        this.city = city;
        this.zip = '' + zip;
        this.street = street;
        this.houseNumber = '' + houseNumber;
    }

    public clone<T extends Clone = this>(): this | T {
        return new Address(
            this.street,
            this.houseNumber,
            this.zip,
            this.city,
            this.country
        );
    }
}
