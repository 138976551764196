import { Offer } from '../Model/Offer/Offer';
import { AbstractExportService } from './Export/AbstractExportService';
import { ExportOffer } from './Export/ExportOffer';

export class PrintService extends AbstractExportService {
    public printOffer(offer: Offer) {
        this.performExport(offer);
    }

    protected getApiPath(exportOffer: ExportOffer): string {
        return 'pdf/offer';
    }

    protected handleResponse(exportOffer: ExportOffer, promise: Promise<Response>): void {
        this.savePdf(promise, 'offerte');
    }

    /**
     * Convert the downloaded PDF stream into a blob and open it
     *
     * @link https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
     * @param {Promise<Response>} p
     * @param {string} name
     */
    private savePdf(p: Promise<Response>, name: string) {
        const windowReference = window.navigator['standalone'] !== true ? window.open() : null;

        p.then(r => r.blob())
            .then(blob => {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([blob], {type: 'application/pdf'});

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                if (windowReference) {
                    windowReference.location.href = data;
                } else {
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = name + '.pdf';
                    link.click();
                }
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
            });
    }
}
