import * as React from 'react';
import { AEContainer } from './AE/AEContainer';

export class MiddleSection extends React.Component {
    public render() {
        return (
            <section className="offer-new-section offer-new-middle-section">
                <div className="offer-new-section-inner">
                    <AEContainer/>
                </div>
            </section>
        );
    }
}
