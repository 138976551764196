export enum SideEnum {
    Right = 'right',
    Left = 'left',
    Both = 'both'
}

const descriptionForSide = (side: SideEnum | undefined): string => {
    if (!side) {
        return '';
    }
    switch (side) {
        case SideEnum.Right:
            return 'Rechts';
        case SideEnum.Left:
            return 'Links';
        case SideEnum.Both:
            return '';
    }
};
const shortDescriptionForSide = (side: SideEnum | undefined): string => {
    if (!side) {
        return '';
    }
    switch (side) {
        case SideEnum.Right:
            return 'R';
        case SideEnum.Left:
            return 'L';
        case SideEnum.Both:
            return '';
    }
};

export {
    descriptionForSide,
    shortDescriptionForSide
};
