import * as React from 'react';
import { createRef, FormEvent, SyntheticEvent } from 'react';
import { BaseModel } from '../../Model/BaseModel';
import { DomainObject } from '../../Model/DomainObject';
import { Spinner, SpinnerType } from '../Spinner';
import {
    AbstractAjaxFormField,
    AjaxFormFieldProps,
    AjaxFormFieldState,
    AjaxOption,
    ItemRenderer as ItemRendererX,
    OptionNameFetcher as OptionNameFetcherX,
    OptionValueFetcher as OptionValueFetcherX,
    OnSelectHandler as OnSelectHandlerX,
    OnChangeHandler as OnChangeHandlerX,
} from './AjaxFormField';

export type OptionNameFetcher<T extends DomainObject> =OptionNameFetcherX<T>;
export type OptionValueFetcher<T extends DomainObject> =OptionValueFetcherX<T>;
export type OnSelectHandler<T extends DomainObject> =OnSelectHandlerX<T>;
export type OnChangeHandler = OnChangeHandlerX;


export type ItemRenderer<T extends DomainObject> =ItemRendererX<T>;

export interface SuggestProps<T extends DomainObject> extends AjaxFormFieldProps<T> {
    clearTextFieldAfterSelect?: boolean
}

interface SuggestState<T> extends AjaxFormFieldState<T> {
    showOptions: boolean
}

export class Suggest<T extends BaseModel> extends AbstractAjaxFormField<T, SuggestProps<T>, SuggestState<T>> {
    private minimumNumberOfCharacters = 1;
    private blurTimer: number;
    private ref = createRef<HTMLInputElement>();

    constructor(props: SuggestProps<T>, context: any) {
        super(props, context);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleFocus = this.handleFocus.bind(this);

        this.state = {
            options: [] as T[],
            value: props.value,
            stringValue: props.value ? this.getStateStringValueForItem(props.value) : '',
            showSpinner: false,
            showOptions: false
        };
    }

    public render() {
        const props = this.props;
        const state = this.state;
        const showSpinner = state.showSpinner && !props.disableSpinner;

        return (
            <div className={props.className || 'suggest'}>
                <label className={props.labelClassName || 'suggest-inner'}>
                    {props.children}
                    <input type="text"
                           value={state.stringValue}
                           autoComplete="off"
                           autoCorrect="off"
                           spellCheck={false}
                           onChange={this.handleKeyUp}
                           ref={this.ref}
                           onFocus={this.handleFocus}
                           onBlur={this.handleBlur}
                    />
                    {showSpinner ? <Spinner type={SpinnerType.Custom}/> : null}
                </label>
                {this.renderOptions(this.filteredOptions)}
            </div>
        );
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        if (this.blurTimer) {
            clearTimeout(this.blurTimer);
        }
    }

    protected handleDidFetch(options: T[]): void {
        super.handleDidFetch(options);
        this.setState({showOptions: true});
    }

    protected handleChooseOption(item: T, event: SyntheticEvent) {
        // Set the chosen option as value and clear the options
        this.setState({options: [], showOptions: false});

        super.handleChooseOption(item, event);
    }

    protected renderOptions(options: T[] | undefined): JSX.Element | null {
        if (!this.state.showOptions) {
            return null;
        }

        if (!options || options.length === 0) {
            console.log(`[${this.constructor.name}] No options`);

            return null;
        }

        console.debug(`[${this.constructor.name}] Found ${options.length} options`, options);
        const optionWrapperClassNameFinal = this.props.optionWrapperClassName || 'suggest-suggestions';
        const getItemKey = this.defaultGetItemKeyCallback();
        const itemRenderer = this.props.itemRenderer;
        if (itemRenderer) {
            return (
                <ul className={optionWrapperClassNameFinal}>
                    {options.map((itemRenderer as unknown) as ItemRenderer<T>)}
                </ul>
            );
        } else {
            return (
                <ul className={optionWrapperClassNameFinal}>
                    {options.map(item => <AjaxOption
                        key={getItemKey(item)}
                        onChooseOption={this.handleChooseOption}
                        item={item}
                        getOptionName={(this.props.getOptionName || this.defaultGetOptionNameCallback()) as any }
                        {...this.props}
                    />)}
                </ul>
            );
        }
    }

    protected getStateStringValueForItem(item: T): string {
        if (this.props.clearTextFieldAfterSelect) {
            return '';
        }
        return this.getOptionName(item);
    }

    protected handleKeyUp(event: FormEvent<HTMLInputElement> | any) {
        const props = this.props;
        const value = event.target.value;
        this.setState({stringValue: value, showOptions: true});

        if (props.onChange) {
            props.onChange(event);
        }

        if (value.length === 0) {
            this.setState({options: []});
        }

        // If the repository is set and `value` is not empty fetch the options
        const trimmedValue = value.trim();
        if (props.repository && trimmedValue.length >= this.minimumNumberOfCharacters) {
            this.fetchOptions(trimmedValue, props.additionalQueryParameters);
        }
    }

    protected handleBlur(event: SyntheticEvent) {
        if (this.state.stringValue === '') {
            this.handleClear(event);
        }
        this.blurTimer = setTimeout(() => {
            this.setState({showOptions: false});
        }, 500) as unknown as number;
    }

    protected handleClear(event: SyntheticEvent) {
        const onSelect = this.props.onSelect;
        if (onSelect) {
            onSelect(undefined, event);
        }
        this.setState({value: undefined});
    }

    protected handleFocus(event: SyntheticEvent) {
        const current = this.ref.current;
        if (!current) {
            return;
        }

        const insideViewport = current.closest('.popup-content-wrapper') !== null;
        if (!insideViewport) {
            event.stopPropagation();
            event.preventDefault();
            setTimeout(() => {
                const y = current.getBoundingClientRect().top;
                window.scroll(0, y);
            }, 10);
        }
    }
}
