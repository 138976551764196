import * as React from 'react';
import { SyntheticEvent } from 'react';

interface ViewProps {
    onSaveClick: (event: SyntheticEvent) => void,
}

export class Footer extends React.Component<ViewProps> {
    public render() {
        return (
            <footer className="footer-block">
                <div className="economy-option-footer-inner">
                    {this.renderSaveButton()}
                </div>
            </footer>
        );
    }

    private renderSaveButton() {
        const disabled = false;

        return <button onClick={this.props.onSaveClick} disabled={disabled}>
            <i className="material-icons">check</i>
            <span className="title">Übernehmen</span>
        </button>;
    }
}
