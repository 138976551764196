import { Clone } from 'iresults-ts-core';
import { ClassTypeOptions, PropertyTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { BaseModel } from '../BaseModel';
import { Address } from './Address';
import { RefractionPair } from './RefractionPair';

@ra(ClassTypeOptions.AddUnknownFields)
export class Customer extends BaseModel implements Clone {
    @ra_property(Address, PropertyTypeOptions.Multiple)
    public addresses: Address[];

    @ra_property(RefractionPair, PropertyTypeOptions.Multiple)
    public refractionPairs: RefractionPair[];

    @ra_property(String)
    public firstName: string;

    @ra_property(String)
    public lastName: string;

    /**
     * @deprecated use refractionPairs instead
     */
    @ra_property(RefractionPair, 'refractionPair')
    protected _refractionPair?: RefractionPair;

    constructor(firstName: string, lastName: string, addresses: Address[]) {
        super();
        this.lastName = lastName;
        this.firstName = firstName;
        this.addresses = addresses;
    }

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    /**
     * @deprecated use refractionPairs instead
     */
    get refractionPair(): RefractionPair | undefined {
        console.warn('[Customer] Property refractionPair is deprecated');

        return this._refractionPair;
    }

    public clone<T extends Clone = this>(): this | T {
        return new Customer(
            this.firstName,
            this.lastName,
            this.addresses.map(a => a.clone())
        );
    }
}
