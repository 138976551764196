import { Repository } from '@cundd/rest-adapter';
import * as React from 'react';
import { AppContext, AppContextType } from '../../../../../AppContextType';
import { Lenses } from '../../../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { DomainObject } from '../../../../../Model/DomainObject';
import { Refraction } from '../../../../../Model/Refraction';
import { RefractionService } from '../../../../../Service/RefractionService';
import { ResultantPrism } from '../../../../../ValueObject/ResultantPrism';
import { OnSelectHandler } from '../../../../Form/Suggest';
import { SideEnum } from '../../../SideEnum';
import { FormRowProps } from './FormRowProps';

/**
 * A shortcut for the type of arguments that need a ResultantPrism
 */
export type ResultantPrismArgument = ResultantPrism | undefined ;

export abstract class AbstractRow<T extends DomainObject,
    P extends FormRowProps<T> = FormRowProps<T>,
    S = {}>
    extends React.Component<P, S> {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        return (
            <div className="row-container lenses-configuration-row lenses-coating">
                <header className="grid-3 offset-1">{this.renderHeader()}</header>
                <div className="grid-4">{this.renderRightColumn()}</div>
                <div className="grid-4">{this.renderLeftColumn()}</div>
            </div>
        );
    }

    protected abstract renderHeader(): JSX.Element;

    protected abstract renderRightColumn(): JSX.Element;

    protected abstract renderLeftColumn(): JSX.Element;

    protected abstract renderInput(
        onSelect: OnSelectHandler<T>,
        value: T | undefined,
        product: Product | undefined,
        refraction: Refraction,
        resultantPrism: ResultantPrismArgument,
        lenses: Lenses | undefined
    ): JSX.Element;

    protected getRefraction(side: SideEnum): Refraction | undefined {
        const refractionPair = this.props.refractionPair;
        if (!refractionPair) {
            return undefined;
        }

        if (side === SideEnum.Right) {
            return refractionPair.right;
        }
        if (side === SideEnum.Left) {
            return refractionPair.left;
        }
        throw new TypeError(`Side ${side} not supported here. Only Right and Left`);
    }

    protected getMergedRightRefraction(): Refraction | undefined {
        const variant = this.props.variant;
        const leftRefraction = this.getRefraction(SideEnum.Left);
        const rightRefraction = this.getRefraction(SideEnum.Right);
        if (leftRefraction === undefined && rightRefraction === undefined) {
            return undefined;
        }

        if (variant.symmetrical) {
            const refractionService = new RefractionService();
            return refractionService.mergeRefractions(rightRefraction, leftRefraction);
        } else {
            return rightRefraction;
        }
    }

    protected detectHigherResultantPrism(): ResultantPrism | undefined {
        const variant = this.props.variant;
        const leftRefraction = this.getRefraction(SideEnum.Left);
        const rightRefraction = this.getRefraction(SideEnum.Right);
        if (leftRefraction === undefined && rightRefraction === undefined) {
            return undefined;
        }

        if (variant.symmetrical) {
            const refractionService = new RefractionService();
            return refractionService.detectHigherResultantPrism(rightRefraction, leftRefraction);
        } else if (rightRefraction) {
            return rightRefraction.resultantPrism;
        } else {
            return undefined;
        }
    }

    protected renderDisabledValue(value: any = '') {
        return (
            <div className="suggest lenses-suggest -disabled">
                <label className="suggest-inner">
                    <input type="text"
                           className="-disabled"
                           disabled={true}
                           value={value}/>
                </label>
            </div>
        );
    }

    protected renderPlaceholderField(message: any = 'bitte Grundglas wählen') {
        return this.renderDisabledValue(message);
    }

    protected suggestOptionName(item: T) {
        return item['name'];
    }

    protected getRepository(): Repository<T> {
        return this.context.serviceLocator.get('lensesAdditionRepository');
    }
}
