import { Assert } from 'iresults-ts-core';
import { ra_property } from '@cundd/rest-adapter';

export class VariantPrice {
    @ra_property(Number)
    public readonly base: number;

    @ra_property(Number)
    public readonly total: number;

    constructor(base: number, total: number) {
        this.total = total;
        this.base = base;
        Assert.isNumber(base);
        Assert.isNumber(total);
    }
}
