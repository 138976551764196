import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';

@ra(ClassTypeOptions.AddUnknownFields)
export class VariantError {
    @ra_property(String)
    public message: string;

    @ra_property(Number)
    public code: number;

    @ra_property()
    public data: object;
}
