import { PropertyTypeOptions, ra_property } from '@cundd/rest-adapter';
import { Variant } from './Variant';
import { VariantError } from './VariantError';

export class VariantResult {
    @ra_property(Variant)
    public readonly variant: Variant;

    @ra_property(VariantError, PropertyTypeOptions.Multiple)
    public readonly errors: VariantError[];
}
