import { Clone } from 'iresults-ts-core';
import { ra_property } from '@cundd/rest-adapter';
import { Addition } from '../../Catalog/Lenses/Addition';
import { Lenses } from '../../Catalog/Lenses/Lenses';

function nameFromCatalogModel(model: Lenses | Addition): string {
    if (model instanceof Lenses) {
        if (model.inhousePriceId && model.diameter) {
            return model.name + ' (⌀' + model.diameter + ')';
        }
    }

    return model.name;
}

export class Entry implements Clone {
    public static fromCatalogModel(model: Lenses | Addition): Entry {
        if (!model) {
            throw new ReferenceError(`Argument 'model' must not be undefined`);
        }

        return new Entry(model.uid, nameFromCatalogModel(model), model.vendorUid);
    }

    @ra_property()
    public readonly uid: string;

    @ra_property()
    public readonly name: string;

    @ra_property(String, 'vendorUid')
    private readonly _vendorUid: string;

    constructor(uid: string | number, name: string, vendorUid: string) {
        this.uid = '' + uid;
        this.name = name;
        this._vendorUid = vendorUid;
    }

    get vendorUid(): string {
        return this._vendorUid || '';
    }

    get description(): string {
        if (this.vendorUid) {
            return this.name + ' (' + this.vendorUid.toUpperCase() + ')';
        } else {
            return this.name;
        }
    }

    public clone<T extends Clone = this>(): this | T {
        return new Entry(this.uid, this.name, this.vendorUid);
    }

    public toString = (): string => {
        return this.name;
    };

    public toLocalString = (): string => {
        return this.name;
    };
}
