import { Refraction } from '../Model/Refraction';
import { ResultantPrism } from '../ValueObject/ResultantPrism';

function biggest(a: number, b: number): number ;
function biggest(a: number | undefined, b: number | undefined): number | undefined;
function biggest(a: number | undefined, b: number | undefined): number | undefined {
    if (a === undefined && b === undefined) {
        return undefined;
    }
    if (a === undefined) {
        return b;
    }
    if (b === undefined) {
        return a;
    }

    return (Math.abs(a) > Math.abs(b)) ? a : b;
}

function first<T>(a: T | undefined, b: T | undefined): T | undefined {
    if (a === undefined && b === undefined) {
        return undefined;
    }
    return a === undefined ? b : a;
}

export class RefractionService {
    public mergeRefractions(
        refractionA: Refraction | undefined,
        refractionB: Refraction | undefined
    ): Refraction | undefined {
        if (refractionA && refractionB) {
            return new Refraction(
                refractionA.side,
                biggest(refractionA.spherical, refractionB.spherical),
                biggest(refractionA.cylindrical, refractionB.cylindrical),
                biggest(refractionA.axis, refractionB.axis),
                biggest(refractionA.add, refractionB.add),
                biggest(refractionA.prism1, refractionB.prism1),
                first(refractionA.base1, refractionB.base1),
                biggest(refractionA.prism2, refractionB.prism2),
                first(refractionA.base2, refractionB.base2),
                biggest(refractionA.vcc, refractionB.vcc),
                biggest(refractionA.hsa, refractionB.hsa),
                biggest(refractionA.nab, refractionB.nab),
            );
        }

        return refractionA ? refractionA : refractionB;
    }

    /**
     * Calculate the Resultant Prism of both Refractions and return the higher/extremer one
     *
     * @param refractionA
     * @param refractionB
     */
    public detectHigherResultantPrism(
        refractionA: Refraction | undefined,
        refractionB: Refraction | undefined
    ): ResultantPrism | undefined {
        if (refractionA && refractionB) {
            const resultantPrismA = refractionA.resultantPrism;
            const resultantPrismB = refractionB.resultantPrism;

            if (resultantPrismA && resultantPrismB) {
                return resultantPrismA.prism > resultantPrismB.prism ? resultantPrismA : resultantPrismB;
            } else {
                return resultantPrismA || resultantPrismB;
            }
        }

        if (refractionA && refractionA.resultantPrism) {
            return refractionA.resultantPrism;
        }
        if (refractionB && refractionB.resultantPrism) {
            return refractionB.resultantPrism;
        }

        return undefined;
    }
}
