import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Variant } from '../../../../Model/Offer/Variant';
import { AddButton } from '../../Favourite/AddButton';
import { FormProps } from './FormProps';

interface ViewProps extends FormProps {
    onSaveClick: (event: SyntheticEvent) => void,
    onAddFavouriteClick?: (event: SyntheticEvent) => void,
}

export class Footer extends React.Component<ViewProps> {
    public render() {
        const variant = this.props.variant;

        return (
            <footer className="footer-block">
                <div className="variant-footer-inner">
                    {this.renderAddFavourite(variant)}
                    {this.renderSaveButton(variant)}
                </div>
            </footer>
        );
    }

    private renderSaveButton(variant: Variant) {
        const disabled = !variant.isValid();
        const buttonClass = this.getButtonClass(variant, disabled);

        return <button className={buttonClass} onClick={this.props.onSaveClick} disabled={disabled}>
            <i className="material-icons">check</i>
            <span className="title">Übernehmen</span>
        </button>;
    }

    private renderAddFavourite(variant: Variant) {
        const disabled = !variant.isValid();
        const buttonClass = this.getButtonClass(variant, disabled);

        return <AddButton variant={variant} className={buttonClass} disabled={disabled}/>;
    }

    private getButtonClass(variant: Variant, disabled: boolean) {
        return ''
            + (variant.symmetrical ? ' -symmetrical' : ' -non-symmetrical')
            + (disabled ? ' -disabled' : ' -blue');
    }
}
