import * as React from 'react';

interface ErrorViewProps {
    error: Error | undefined | null
    message?: string
}

export class ErrorView extends React.Component<ErrorViewProps> {
    public render() {
        const props = this.props;
        const error = props.error;
        if (!error) {
            return null;
        }

        const isDevelopmentEnv = process.env.NODE_ENV === 'development';
        const detail = props.message && isDevelopmentEnv
            ? <div className="error-details">{error.message}</div>
            : null;

        return <div className="error">
            <div className="error-message">
                {props.message || error.message}
            </div>
            {detail}
        </div>;
    }
}
