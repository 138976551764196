import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Addition } from '../../../../../Model/Catalog/Lenses/Addition';
import { Lenses } from '../../../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { Refraction } from '../../../../../Model/Refraction';
import { FindAdditionQueryParameters } from '../../../../../Query/FindAdditionQueryParameters';
import { Suggest } from '../../../../Form/Suggest';
import { SideEnum } from '../../../SideEnum';
import { AbstractProductDependantRow } from './AbstractProductDependantRow';
import { ResultantPrismArgument } from './AbstractRow';
import { FormRowProps } from './FormRowProps';
import { MiscellaneousItem } from './MiscellaneousItem';

type Miscellaneous = Addition;

type OnAddHandler = (item: Miscellaneous, event: SyntheticEvent) => void;
type OnRemoveHandler = (item: Miscellaneous, event: SyntheticEvent) => void;

interface ViewProps extends FormRowProps<Miscellaneous> {
    onAddRight: OnAddHandler
    onRemoveRight: OnRemoveHandler
    onAddLeft: OnAddHandler
    onRemoveLeft: OnRemoveHandler
}

export class MiscellaneousRow extends AbstractProductDependantRow<Miscellaneous, ViewProps> {
    constructor(props: ViewProps, context: any) {
        super(props, context);
        this.filterOptions = this.filterOptions.bind(this);
    }

    protected renderHeader(): JSX.Element {
        return <h4 className="center">Diverses</h4>;
    }

    protected renderRightColumn(): JSX.Element {
        const props = this.props;
        const refraction = this.getMergedRightRefraction();
        if (!refraction) {
            return this.renderPlaceholderField('Keine Refraktion');
        }

        const resultantPrism = this.detectHigherResultantPrism();
        const variant = props.variant;
        if (variant.rightProduct && variant.rightLenses) {
            const rightMiscellaneous = variant.rightMiscellaneous;

            return <>
                {this.renderMiscellaneousInput(
                    props.onAddRight,
                    rightMiscellaneous,
                    variant.rightProduct,
                    variant.rightLenses,
                    refraction,
                    resultantPrism
                )}
                {this.renderSelectedAdditions(rightMiscellaneous, props.onRemoveRight)}
            </>;
        } else {
            return this.renderPlaceholderField();
        }
    }

    protected renderLeftColumn(): JSX.Element {
        const props = this.props;
        const variant = props.variant;
        if (variant.symmetrical) {
            const miscellaneous = variant.rightMiscellaneous;
            const value = miscellaneous
                ? miscellaneous.map(this.suggestOptionName).join(', ')
                : '';

            return this.renderDisabledValue(value);
        }

        // If the Variant is not symmetrical check if the Product has been selected
        if (variant.leftProduct && variant.leftLenses) {
            const miscellaneous = variant.leftMiscellaneous;

            const refraction = this.getRefraction(SideEnum.Left);
            if (!refraction) {
                return this.renderPlaceholderField('Keine Refraktion');
            }

            return <>
                {this.renderMiscellaneousInput(
                    props.onAddLeft,
                    miscellaneous,
                    variant.leftProduct,
                    variant.leftLenses,
                    refraction,
                    refraction.resultantPrism
                )}
                {this.renderSelectedAdditions(miscellaneous, props.onRemoveLeft)}
            </>;
        } else {
            return this.renderPlaceholderField();
        }
    }

    /**
     * The Miscellaneous input needs access to all the selected Miscellaneous Additions so a special `renderInput()`
     * method must be used
     *
     * @deprecated
     */
    protected renderInput(
        onSelect: any,
        value: Miscellaneous | undefined,
        product: Product
    ): JSX.Element {
        return <div>Do not render</div>;
    }

    protected renderMiscellaneousInput(
        onAdd: OnAddHandler,
        value: Miscellaneous[],
        product: Product,
        lenses: Lenses,
        refraction: Refraction,
        resultantPrism: ResultantPrismArgument
    ): JSX.Element {
        console.debug(`[MiscellaneousRow] Render input`, value);
        const additionalQueryParameters: FindAdditionQueryParameters = {
            resultantPrism,
            refraction,
            lensesUid: lenses.uid
        };
        if (product) {
            additionalQueryParameters.additionMaterial = product.additionMaterial;
        }
        const onSelect = ((item: Miscellaneous | undefined, e: SyntheticEvent) => {
            if (item) {
                onAdd(item, e);
            }
        });
        const optionFilter = (i: Miscellaneous) => this.filterOptions(value, i);

        return <Suggest
            className="suggest lenses-suggest lenses-miscellaneous-suggest"
            onSelect={onSelect}
            getOptionName={this.suggestOptionName as any}
            additionalQueryParameters={additionalQueryParameters}
            repository={this.getRepository() as any}
            optionFilter={optionFilter}
            clearTextFieldAfterSelect={true}
        />;
    }

    private renderSelectedAdditions(additions: Miscellaneous[], onRemove: OnRemoveHandler) {
        return (
            <ul className="lenses-miscellaneous-select">
                {additions.map(
                    addition => <MiscellaneousItem key={addition.guid} onClick={onRemove} addition={addition}/>)}
            </ul>
        );
    }

    private filterOptions(selectedAdditions: Miscellaneous[], addition: Miscellaneous): boolean {
        return -1 === selectedAdditions.findIndex(item => item.equals(addition));
    }
}
