import { InvalidPriceError } from '../Error/InvalidPriceError';
import { Frame } from '../Model/Catalog/Frame';
import { FramePrice } from '../Model/Offer/FramePrice';
import { Offer } from '../Model/Offer/Offer';
import { PriceModel } from '../Model/Offer/PriceModel';
import { Price } from '../Price';

interface OfferWithFrame {
    frame: Frame;
}

interface FilledOffer extends OfferWithFrame {
    priceModel: PriceModel;
}

export class FramePriceCalculator {
    /**
     * Calculate the Frame price for `offer`
     *
     * If the Offer's Frame or Price Model are not set `NaN` is returned.
     * If `PriceModel.Lifetime` is selected the Lifetime price is returned.
     * If `PriceModel.Economy` is selected the price includes the Economy base price plus all Economy Option prices
     *
     * @param {Offer} offer
     * @return {Price}
     */
    public calculateFramePriceForOffer(offer: Offer): Price {
        if (!this.isFilledOfferWithPriceModel(offer)) {
            return new Price(NaN);
        }

        return this.calculateFramePriceForOfferWithPriceModel(offer, offer.priceModel);
    }

    /**
     * Build the Price instance for `offer`
     *
     * If the Offer's Frame or Price Model are not set `undefined` is returned.
     * If `PriceModel.Lifetime` is selected the Lifetime price is returned.
     * If `PriceModel.Economy` is selected the price includes the Economy base price plus all Economy Option prices
     *
     * @param {Offer} offer
     * @return {FramePrice}
     */
    public buildPriceForOffer(offer: Offer): FramePrice | undefined {
        if (!this.isFilledOfferWithPriceModel(offer)) {
            return undefined;
        }

        return this.buildPriceForOfferWithPriceModel(offer, offer.priceModel);
    }

    /**
     * Calculate the Frame price for `offer` and the given `priceModel`
     *
     * If `priceModel` is `PriceModel.Lifetime` the Lifetime price is returned.
     * If `priceModel` is `PriceModel.Economy` the price includes the Economy base price plus all Economy Option prices
     *
     * @param {Offer} offer
     * @param {PriceModel} priceModel
     * @return {Price}
     */
    public calculateFramePriceForOfferWithPriceModel(offer: Offer, priceModel: PriceModel): Price {
        if (!this.isFilledOffer(offer)) {
            return new Price(NaN);
        }
        const basePrice = this.getBasePriceForPriceModel(offer, priceModel);
        if (priceModel === PriceModel.lifetime) {
            return basePrice;
        }

        if (offer.frame.isCustomerFrame) {
            return new Price(0.0);
        }

        const serviceFee = offer.serviceFeeOption.price || 0.0;
        const optionPriceSum = offer.economyOptions.reduce(
            (accu, option) => accu + (option.price || 0.0),
            0.0
        );

        return new Price(basePrice.valueOf() + optionPriceSum + serviceFee);
    }

    /**
     * Build the Price instance for `offer` and the given `priceModel`
     *
     * If `priceModel` is `PriceModel.Lifetime` the Lifetime price is returned.
     * If `priceModel` is `PriceModel.Economy` the price includes the Economy base price plus all Economy Option prices
     *
     * @param {Offer} offer
     * @param {PriceModel} priceModel
     * @return {FramePrice}
     */
    public buildPriceForOfferWithPriceModel(offer: Offer, priceModel: PriceModel): FramePrice | undefined {
        if (!this.isFilledOffer(offer)) {
            return undefined;
        }
        if (!this.hasBasePriceForPriceModel(offer, priceModel)) {
            return undefined;
        }
        try {
            const basePrice = this.getBasePriceForPriceModel(offer, priceModel);
            const total = this.calculateFramePriceForOfferWithPriceModel(offer, priceModel);

            return new FramePrice(basePrice, total, priceModel);
        } catch (e) {
            console.error(e);
        }

        return undefined;
    }

    private getBasePriceForPriceModel(offer: OfferWithFrame, priceModel: PriceModel): Price {
        const frame = offer.frame;
        if (frame.isCustomerFrame) {
            return new Price(0.0);
        }

        const price = priceModel === PriceModel.lifetime ? frame.priceLifetime : frame.priceEconomy;
        if (!Price.isValidPrice(price)) {
            throw new InvalidPriceError(`Could not fetch price for price model ${priceModel}`);
        }

        return new Price(price);
    }

    private hasBasePriceForPriceModel(offer: OfferWithFrame, priceModel: PriceModel): boolean {
        const frame = offer.frame;
        if (frame.isCustomerFrame) {
            return true;
        }

        const price = priceModel === PriceModel.lifetime
            ? frame.priceLifetime
            : frame.priceEconomy;

        return Price.isValidPrice(price);
    }

    private isFilledOffer(offer: Partial<Offer>): offer is OfferWithFrame {
        return !!offer.frame;
    }

    private isFilledOfferWithPriceModel(offer: Partial<Offer>): offer is FilledOffer {
        return !!offer.priceModel && this.isFilledOffer(offer);
    }
}
