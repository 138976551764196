import { PropertyTypeOptions, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { CollectionService } from '../../Service/CollectionService';
import { FramePriceCalculator } from '../../Service/FramePriceCalculator';
import { BaseModel } from '../BaseModel';
import { EconomyOption } from '../Catalog/EconomyOption';
import { Frame } from '../Catalog/Frame';
import { ServiceFeeOption } from '../Catalog/ServiceFeeOption';
import { Customer } from '../Customer/Customer';
import { RefractionPair } from '../Customer/RefractionPair';
import { assertCustomerHasRefractionPair } from '../Error/CustomerRefractionError';
import { Service } from '../Service';
import { User } from '../User';
import { FramePrice } from './FramePrice';
import { PriceModel } from './PriceModel';
import { Variant } from './Variant';

/**
 * @deprecated Will be removed in version 2.0
 * @param {Variant} variant
 * @param {Offer} offer
 */
function patchVariantAE(variant: Variant, offer: Offer): Variant {
    return variant
        ._assignLensesDeterminationPrice(offer.lensesDeterminationPrice)
        ._assignReworking(offer.reworking);
}

export class Offer extends BaseModel implements Clone {
    @ra_property(String, 'id')
    public uid: string;

    @ra_property(Date, 'modifiedAt')
    protected _modifiedAt: Date;

    @ra_property(Date, 'createdAt')
    protected _createdAt: Date;

    @ra_property(User, 'login')
    protected _login?: User;

    @ra_property(Customer, 'customer')
    protected _customer?: Customer;

    @ra_property(Frame, 'frame')
    protected _frame?: Frame;

    @ra_property('priceModel')
    protected _priceModel?: PriceModel;

    @ra_property(EconomyOption, PropertyTypeOptions.Multiple, 'economyOptions')
    protected _economyOptions: EconomyOption[];

    @ra_property(Variant, PropertyTypeOptions.Multiple, 'variants')
    protected _variants: Variant[];

    @ra_property(ServiceFeeOption, 'serviceFeeOption')
    protected _serviceFeeOption: ServiceFeeOption;

    @ra_property(RefractionPair, 'refractionPair')
    protected _refractionPair: RefractionPair | undefined;

    @ra_property('isImported')
    protected _isImported: boolean;

    @ra_property(Service, 'reworking')
    protected _reworking?: Service;

    @ra_property('materialFee')
    protected _materialFee?: number;

    @ra_property('lensesDeterminationPrice')
    protected _lensesDeterminationPrice?: number;

    constructor(
        uid: string | number,
        customer?: Customer,
        frame?: Frame,
        priceModel?: PriceModel,
        login?: User,
        economyOptions: EconomyOption[] = [],
        serviceFeeOption?: ServiceFeeOption,
        variants?: Variant[],
        createdAt?: Date,
        modifiedAt?: Date,
        refractionPair?: RefractionPair,
        isImported?: boolean,
        reworking?: Service,
        lensesDeterminationPrice?: number,
        materialFee?: number
    ) {
        super();
        this.uid = '' + uid;
        this._customer = customer;
        this._frame = frame;
        this._priceModel = priceModel ? ('' + priceModel as PriceModel) : undefined;
        this._login = login;
        this._economyOptions = economyOptions;
        this._variants = variants || [];
        this._serviceFeeOption = serviceFeeOption || ServiceFeeOption.getServiceFeeOption(0);

        const now = new Date();
        this._createdAt = createdAt || now;
        this._modifiedAt = modifiedAt || createdAt || now;
        this._refractionPair = refractionPair;
        this._isImported = isImported || false;
        this._reworking = reworking;
        this._lensesDeterminationPrice = lensesDeterminationPrice;
        this._materialFee = materialFee;
    }

    get login(): User | undefined {
        return this._login;
    }

    get customer(): Customer | undefined {
        return this._customer;
    }

    get frame(): Frame | undefined {
        return this._frame;
    }

    get priceModel(): PriceModel | undefined {
        return this._priceModel;
    }

    get economyOptions(): EconomyOption[] {
        return this._economyOptions;
    }

    get serviceFeeOption(): ServiceFeeOption {
        return this._serviceFeeOption;
    }

    get price(): FramePrice {
        const calc = new FramePriceCalculator();

        const price = calc.buildPriceForOffer(this);
        if (!price) {
            throw new TypeError();
        }
        return price;
    }

    get variants(): Variant[] {
        return this._variants;
    }

    get modifiedAt(): Date {
        return this._modifiedAt;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get refractionPair(): RefractionPair | undefined {
        if (this._refractionPair) {
            return this._refractionPair;
        }
        if (this._customer) {
            const refractionPair = this._customer.refractionPair;
            if (refractionPair) {
                console.warn('[Offer] Legacy Offer instance without the Refraction Pair set');

                return refractionPair;
            }
        }

        return undefined;
    }

    get isImported(): boolean {
        return this._isImported;
    }

    /**
     * Information about the Offer built from it's creation date in the format '%d-%m-%Y %H:%M'
     */
    get info(): string {
        const length2 = (input: number): string => {
            if (input < 10) {
                return '0' + input.toFixed(0);
            } else {
                return input.toFixed(0);
            }
        };
        const createdAt = this.createdAt;

        return ''
            + length2(createdAt.getDate())
            + '-'
            + length2(createdAt.getMonth() + 1)
            + '-'
            + createdAt.getFullYear()
            + ' '
            + length2(createdAt.getHours())
            + ':'
            + length2(createdAt.getMinutes())
            ;
    }

    get reworking(): Service | undefined {
        return this._reworking;
    }

    get lensesDeterminationPrice(): number | undefined {
        return this._lensesDeterminationPrice;
    }

    get materialFee(): number | undefined {
        return this._materialFee;
    }

    /**
     * Return a copy of the Offer with the given Customer set
     *
     * @param {Customer} customer
     * @return {Offer}
     * @deprecated use withCustomerAndRefractionPair() instead. Will be removed in version 2.0
     * @throws {CustomerRefractionError} if `refractionPair` property is set and it does **not** belong to the Customer
     */
    public withCustomer(customer: Customer): Offer {
        const clone = this.clone();
        clone._customer = customer;

        console.warn('[Offer] withCustomer() is deprecated. Use withCustomerAndRefractionPair() instead');

        const refractionPair = clone._refractionPair;
        if (refractionPair) {
            assertCustomerHasRefractionPair(customer, refractionPair);
        }

        return clone;
    }

    /**
     * Return a copy of the Offer with the given Refraction Pair set
     *
     * @param {RefractionPair} value
     * @return {Offer}
     * @throws {CustomerRefractionError} if `customer` property is set and the Refraction Pair does **not** belong to the Customer
     */
    public withRefractionPair(value: RefractionPair): Offer {
        const clone = this.clone();
        clone._refractionPair = value;

        const customer = clone._customer;
        if (customer) {
            assertCustomerHasRefractionPair(customer, value);
        }

        return clone;
    }

    /**
     * Return a copy of the Offer with the given Refraction Pair and Customer set (or unset)
     *
     * @param {Customer | undefined} customer
     * @param {RefractionPair | undefined} refractionPair
     * @return {Offer}
     * @throws {CustomerRefractionError} if `refractionPair` and `customer` are given and they do **not** belong to each other
     */
    public withCustomerAndRefractionPair(
        customer: Customer | undefined,
        refractionPair: RefractionPair | undefined
    ): Offer {
        if (customer && refractionPair) {
            assertCustomerHasRefractionPair(customer, refractionPair);
        }
        const clone = this.clone();
        clone._customer = customer;
        clone._refractionPair = refractionPair;

        return clone;
    }

    public withFrame(frame: Frame | undefined): Offer {
        const clone = this.clone();
        clone._frame = frame;

        return clone;
    }

    public withPriceModel(priceModel: PriceModel): Offer {
        const clone = this.clone();
        clone._priceModel = '' + priceModel as PriceModel;

        return clone;
    }

    public withLogin(login: User): Offer {
        const clone = this.clone();
        clone._login = login;

        return clone;
    }

    public withEconomyOptions(economyOptions: EconomyOption[]): Offer {
        const clone = this.clone();
        clone._economyOptions = economyOptions;

        return clone;
    }

    public withServiceFeeOption(serviceFeeOption: ServiceFeeOption): Offer {
        const clone = this.clone();
        clone._serviceFeeOption = serviceFeeOption;

        return clone;
    }

    public withVariants(variants: Variant[]): Offer {
        const clone = this.clone();
        clone._variants = variants;
        clone.patchVariantsAE();

        return clone;
    }

    public withAddedVariant(variant: Variant): Offer {
        const clone = this.clone();
        const variantsClone = this._variants.slice();
        this.addVariant(variantsClone, variant);
        clone._variants = variantsClone;
        clone.patchVariantsAE();

        return clone;
    }

    public withAddedVariants(variants: Variant[]): Offer {
        const clone = this.clone();
        const variantsClone = this._variants.slice();
        for (const variant of variants) {
            this.addVariant(variantsClone, variant);
        }
        clone._variants = variantsClone;
        clone.patchVariantsAE();

        return clone;
    }

    private addVariant(container: Variant[], variant: Variant) {
        if (undefined !== container.find(i => i.uid === variant.uid)) {
            throw new RangeError(`Variant with UID ${variant.uid} already exists in array`);
        }
        container.push(variant);
    }

    public withRemovedVariant(variant: Variant): Offer {
        const clone = this.clone();
        clone._variants = CollectionService.removeItemFromCollection(variant, this._variants);

        return clone;
    }

    public withCreatedAt(value: Date): Offer {
        const clone = this.clone();
        clone._createdAt = value;

        return clone;
    }

    public withModifiedAt(value: Date): Offer {
        const clone = this.clone();
        clone._modifiedAt = value;

        return clone;
    }

    public withIsImported(isImported: boolean): Offer {
        const clone = this.clone();
        clone._isImported = isImported;

        return clone;
    }

    public withReworking(reworking: Service): Offer {
        const clone = this.clone();
        clone._reworking = reworking;
        clone.patchVariantsAE();

        return clone;
    }

    public withoutReworking(): Offer {
        const clone = this.clone();
        clone._reworking = undefined;
        clone.patchVariantsAE();

        return clone;
    }

    public withLensesDeterminationPrice(lensesDeterminationPrice: number): Offer {
        const clone = this.clone();
        clone._lensesDeterminationPrice = lensesDeterminationPrice;
        clone.patchVariantsAE();

        return clone;
    }

    public withoutLensesDeterminationPrice(): Offer {
        const clone = this.clone();
        clone._lensesDeterminationPrice = undefined;
        clone.patchVariantsAE();

        return clone;
    }

    public withMaterialFee(materialFee: number): Offer {
        const clone = this.clone();
        clone._materialFee = materialFee;

        return clone;
    }

    public withoutMaterialFee(): Offer {
        const clone = this.clone();
        clone._materialFee = undefined;

        return clone;
    }

    /**
     * Build a new Offer from the given Offer
     *
     * @param offer
     */
    public static buildNewOffer(offer: Offer): Offer {
        const clone = offer.clone();
        const now = new Date();
        clone.uid = '' + (+now);
        clone._createdAt = now;
        clone._modifiedAt = now;
        clone._isImported = false;

        return clone;
    }

    public detectHighestVariantUid(): number {
        const max = Math.max(...this.variants.map(v => parseInt(v.uid, 10)));

        if (Number.isFinite(max)) {
            return max;
        } else {
            return 0;
        }
    }

    /**
     * Return if the Offer has the given Economy Option set
     *
     * @param {EconomyOption} option
     * @return {boolean}
     */
    public hasEconomyOption(option: EconomyOption): boolean {
        for (const activeOption of this.economyOptions) {
            if (option.equals(activeOption)) {
                return true;
            }
        }
        return false;
    }

    public clone<T extends Clone = this>(): this | T {
        return new Offer(
            this.uid,
            this._customer,
            this._frame,
            this._priceModel,
            this._login,
            this._economyOptions.slice(),
            this._serviceFeeOption,
            this._variants.slice(),
            this._createdAt,
            this._modifiedAt,
            this._refractionPair,
            this._isImported,
            this._reworking,
            this._lensesDeterminationPrice,
            this._materialFee,
        );
    }

    /**
     * Loop through the Variants and copy the AE values form the Offer to the Variant.
     * This is used as a legacy layer to export the values according to the old export.xsd
     * @deprecated Will be removed in version 2.0
     */
    private patchVariantsAE() {
        const newVariants: Variant[] = [];
        for (const variant of this.variants) {
            newVariants.push(patchVariantAE(variant, this));
        }

        this._variants = newVariants;
    }
}
