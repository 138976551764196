import { ClassTypeOptions, PropertyTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Picture } from '../Picture';
import { AbstractCatalogModel } from './AbstractCatalogModel';
import { CatalogModelInterface } from './CatalogModelInterface';
import { Collection } from './Collection';
import { Color } from './Color';
import { Model } from './Model';

export enum FrameType {
    default = 'default',
    sunglasses = 'sunglasses',
    night = 'night',
}

@ra(ClassTypeOptions.AddUnknownFields)
export class Frame extends AbstractCatalogModel implements CatalogModelInterface {
    public manufacturer: string;
    public colorVariant: string;

    @ra_property(Collection)
    public collection: Collection;

    @ra_property(Model)
    public model: Model;

    @ra_property(Color)
    public frameColor?: Color;

    @ra_property(Picture, PropertyTypeOptions.Multiple)
    public pictures: Picture[];

    @ra_property(Number)
    public stock: number;

    @ra_property()
    public type: FrameType;

    @ra_property()
    public name: string;

    @ra_property()
    public price: number;

    @ra_property()
    public isCustomerFrame: boolean;

    @ra_property('priceLifetime')
    private _priceLifetime: number;

    @ra_property('priceEconomy')
    private _priceEconomy: number;

    get priceLifetime(): number {
        const price = this._priceLifetime;

        // noinspection SuspiciousTypeOfGuard
        if (typeof price === 'string') {
            return this._priceLifetime = parseFloat(price);
        }
        return price;
    }

    set priceLifetime(value: number) {
        this._priceLifetime = value;
    }

    get priceEconomy(): number {
        const price = this._priceEconomy;
        // noinspection SuspiciousTypeOfGuard
        if (typeof price === 'string') {
            return this._priceEconomy = parseFloat(price);
        }
        return price;
    }

    set priceEconomy(value: number) {
        this._priceEconomy = value;
    }
}
