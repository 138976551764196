import * as React from 'react';
import { RefractionPair } from '../../../../Model/Customer/RefractionPair';
import { HeaderRowProps } from './HeaderRowProps';

export class HeaderRow extends React.PureComponent<HeaderRowProps> {
    public render() {
        const refractionPair = this.props.refractionPair;
        const className = 'customer-refraction-pair-item-header-row'
            + (this.props.isSelected ? ' -selected' : ' -not-selected');

        return <tr key={refractionPair.uid + '-header'} className={className} onClick={this.props.onClick}>
            <td key={refractionPair.uid + '-header-uid'}
                colSpan={9}>Ref.Nr: {refractionPair.uid}&nbsp;&nbsp;&nbsp;{this.formatDate(refractionPair)}</td>
        </tr>;
    }

    private formatDate(refractionPair: RefractionPair): string {
        const date = this.parseDate(refractionPair);

        return date ? date.toLocaleDateString() : '';
    }

    private parseDate(refractionPair: RefractionPair): Date | undefined {
        let date = new Date(refractionPair.creationDate);
        if (!isNaN(date.getTime())) {
            return date;
        }

        const parts = refractionPair.creationDate.split(/[- :]/);
        date = new Date(
            parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10),
            parseInt(parts[3], 10),
            parseInt(parts[4], 10),
            parseInt(parts[5], 10),
        );

        return !isNaN(date.getTime()) ? date : undefined;
    }
}
