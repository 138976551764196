import { Repository } from '@cundd/rest-adapter';
import { ActionHandler } from 'action-handler';
import { App } from './App';
import { Frame } from './Model/Catalog/Frame';
import { Customer } from './Model/Customer/Customer';
import { RefractionPair } from './Model/Customer/RefractionPair';
import { Offer } from './Model/Offer/Offer';
import { PriceModel } from './Model/Offer/PriceModel';
import { Variant } from './Model/Offer/Variant';
import { Settings } from './Model/Settings/Settings';
import { User } from './Model/User';
import { ExportService } from './Service/ExportService';
import { LatestOfferStorage } from './Service/LatestOfferStorage';
import { OfferStorage } from './Service/OfferStorage';
import { PrintService } from './Service/PrintService';
import { ServiceLocator } from './ServiceLocator';

export interface AppState {
    offer: Offer;
    settings: Settings,
    showFavouritesPopup: boolean
    isAuthenticated: boolean
    selectedVariantIdentifiers: string[]
}

export class MainHandler extends ActionHandler<App, AppState> {
    public readonly offerStorage: OfferStorage;
    public readonly latestOfferStorage: LatestOfferStorage;
    private readonly _serviceLocator: ServiceLocator;

    constructor(serviceLocator: ServiceLocator) {
        super();
        this._serviceLocator = serviceLocator;
        this.offerStorage = serviceLocator.get('offerStorage');
        this.latestOfferStorage = serviceLocator.get('latestOfferStorage');
        this.handleFrameChange = this.handleFrameChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleMainVariantChange = this.handleMainVariantChange.bind(this);
    }

    get offer(): Offer {
        return this.state.offer;
    }

    get settings(): Settings {
        return this.state.settings;
    }

    get login(): User | undefined {
        return this.offer.login;
    }

    get selectedVariantIdentifiers(): string[] {
        return this.state.selectedVariantIdentifiers;
    }

    public getInitialState(): AppState {
        return {
            offer: new Offer(+new Date()),
            settings: Settings.default,
            showFavouritesPopup: false,
            isAuthenticated: false,
            selectedVariantIdentifiers: []
        };
    }

    public setOffer(offer: Offer) {
        this.setState({offer});
        this.didUpdateOffer(offer);
    }

    public setSettings(settings: Settings) {
        this.setState({settings});
    }

    public findAllUsers(): Promise<Map<string, User>> {
        const userRepository: Repository<User> = this._serviceLocator.get('userRepository');

        return (userRepository.findAll() as any);
    }

    public findUser(username: string) {
        return this.findAllUsers().then((a) => {
            if (!a.has(username)) {
                throw new RangeError(`User "${username}" could not be found`);
            }
            return a.get(username);
        });
    }

    public loginUser(username: string) {
        return this.findUser(username).then(user => {
            if (user) {
                this.setState((prevState: Readonly<AppState>) => {
                    const offer = prevState.offer.withLogin(user);

                    return {offer};
                });
            }

            return user;
        });
    }

    public clearOffer() {
        console.debug('[MainHandler] Clear offer');
        this.setState(previousState => {
            return {
                offer: new Offer(
                    +new Date(),
                    undefined,
                    undefined,
                    undefined,
                    previousState.offer.login
                ),
                selectedVariantIdentifiers: []
            };
        });
    }

    public handleFrameChange(frame: Frame | undefined) {
        console.debug('[MainHandler] Select frame', frame);
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withFrame(frame);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleCustomerChange(customer: Customer) {
        console.debug('[MainHandler] Select customer', customer);
        this.setState((prevState: Readonly<AppState>) => {
            const previousOffer = prevState.offer;

            const previousRefractionPair = previousOffer.refractionPair;
            const refractionPair = previousRefractionPair && previousRefractionPair.customerUid === customer.uid
                ? previousRefractionPair
                : undefined;

            const offer = previousOffer.withCustomerAndRefractionPair(customer, refractionPair);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleRefractionPairChange(refractionPair: RefractionPair) {
        console.debug('[MainHandler] Select Refraction Pair', refractionPair);
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withRefractionPair(refractionPair);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handlePriceModelChange(priceModel: PriceModel) {
        console.debug(`[MainHandler] Select price model '${priceModel}'`);
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withPriceModel(priceModel);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleAddVariant(variant: Variant) {
        console.debug('[MainHandler] Add variant');
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withAddedVariant(variant);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleAddVariants(variants: Variant[]) {
        console.debug('[MainHandler] Add variant');
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withAddedVariants(variants);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleReplaceVariant(oldVariant: Variant | string, newVariant: Variant) {
        console.debug('[MainHandler] Replace variant');
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer;
            const newOffer = this.replaceVariantOfOffer(offer, oldVariant, newVariant);
            this.didUpdateOffer(newOffer);

            return {offer: newOffer};
        });
    }

    public handleRemoveVariant(variant: Variant) {
        console.debug('[MainHandler] Remove variant');
        this.setState((prevState: Readonly<AppState>) => {
            const offer = prevState.offer.withRemovedVariant(variant);
            this.didUpdateOffer(offer);

            return {offer};
        });
    }

    public handleMainVariantChange(variant: Variant) {
        console.debug('[MainHandler] Change main variant');

        this.setState((prevState: Readonly<AppState>) => {
            const newMainVariant = variant.withIsMain(true);

            const previousOffer = prevState.offer;
            let newOffer = this.replaceVariantOfOffer(previousOffer, variant, newMainVariant);
            const previousMainVariant = previousOffer.variants.find(v => v.isMain);
            if (previousMainVariant) {
                newOffer = this.replaceVariantOfOffer(
                    newOffer,
                    previousMainVariant,
                    previousMainVariant.withIsMain(false)
                );
            }
            this.didUpdateOffer(newOffer);

            return {offer: newOffer};
        });
    }

    public handleToggleVariantSelection(variant: Variant) {
        console.debug('[MainHandler] Toggle variant selection');

        this.setState(prevState => {
            const selectedVariantIdentifiers = prevState.selectedVariantIdentifiers.slice();

            const index = selectedVariantIdentifiers.findIndex(v => v === variant.uid);
            if (-1 < index) {
                selectedVariantIdentifiers.splice(index, 1);

                return {selectedVariantIdentifiers};
            } else {
                selectedVariantIdentifiers.push(variant.uid);

                return {selectedVariantIdentifiers};
            }
        });
    }

    public handleSelectVariant(variant: Variant) {
        console.debug('[MainHandler] Select variant');
        this.setState(prevState => {
            const selectedVariants = prevState.selectedVariantIdentifiers.slice();
            selectedVariants.push(variant.uid);

            return {selectedVariantIdentifiers: selectedVariants};
        });
    }

    public handleUnselectVariant(variant: Variant) {
        console.debug('[MainHandler] Unselect variant');
        this.setState(prevState => {
            const selectedVariantIdentifiers = prevState.selectedVariantIdentifiers.slice();

            const index = selectedVariantIdentifiers.findIndex(v => v === variant.uid);
            if (-1 < index) {
                selectedVariantIdentifiers.splice(index, 1);

                return {selectedVariantIdentifiers};
            } else {
                return {selectedVariantIdentifiers};
            }
        });
    }

    public printSelectedVariants() {
        console.debug('[MainHandler] Print selected variants');
        const printService: PrintService = this._serviceLocator.get('printService');

        // Sort the selected Variants according to the order inside the Offer
        const allVariants = this.offer.variants;
        const sortedVariants: Variant[] = [];

        this.selectedVariantIdentifiers.map(selected => {
            const n = allVariants.findIndex(v => v.uid === selected);

            // Get the current version of the previously selected variant
            sortedVariants[n] = allVariants[n];

            return undefined;
        });

        printService.printOffer(this.offer.withVariants(sortedVariants.filter(i => !!i)));
    }

    public printOffer(offer: Offer) {
        console.debug('[MainHandler] Print offer');
        const printService: PrintService = this._serviceLocator.get('printService');
        printService.printOffer(offer);
    }

    public exportOffer(offer: Offer) {
        const exportService: ExportService = this._serviceLocator.get('exportService');
        exportService.exportOffer(offer);
    }

    /**
     * Load the given Offer
     *
     * The Offer's Login user will be set to the current logged in User if there is one
     * The Offer will be marked as imported
     *
     * @param {Offer} offer
     */
    public loadOffer(offer: Offer) {
        console.debug('[MainHandler] Load offer');
        const importedOffer = offer.withIsImported(true);
        if (this.login) {
            this.setOffer(importedOffer.withLogin(this.login));
        } else {
            this.setOffer(importedOffer);
        }
    }

    private didUpdateOffer(offer: Offer) {
        console.debug('[MainHandler] Update Offer');
        this.latestOfferStorage.store(offer).then();
        this.offerStorage.store(offer).then();
    }

    private replaceVariantOfOffer(offer: Offer, oldVariant: Variant | string, newVariant: Variant) {
        const allVariants = offer.variants.slice();
        const oldUid = typeof oldVariant === 'string' ? oldVariant : oldVariant.uid;
        const index = allVariants.findIndex(v => oldUid === v.uid);
        if (index === -1) {
            throw new RangeError('Could not find old variant in the current variants');
        }

        allVariants[index] = newVariant;

        return offer.withVariants(allVariants);
    }
}
