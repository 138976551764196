import { RepositoryInterface } from '@cundd/rest-adapter';

export class RepositoryEnhancement<T> {
    constructor(private readonly repository: RepositoryInterface<T>) {}

    /**
     * Merge multiple `findByIdentifier()` calls into a single promise
     *
     * @param {string[] | number[]} identifiers
     * @return {Promise<[any, any, any, any, any, any, any, any, any, any]>}
     */
    public findMultipleByIdentifier(identifiers: string[] | number[]) {
        const promises: Promise<any>[] = [];

        for (const identifier of identifiers) {
            promises.push(this.repository.findByIdentifier(identifier));
        }

        return Promise.all(promises);
    }
}
