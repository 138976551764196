type ClassConstructorType<T> = new (...args: any[]) => T;

interface InstanceWithResolveDependencies {
    didResolveDependencies: () => void
}

interface InstanceWithNeeds {
    needs?: () => void;

    [key: string]: any;
}

interface ServicesMap {
    [key: string]: object;
}

type ObjectFactory<T extends object> = (argument?: any) => T;

interface ServicesFactoryMap {
    [key: string]: ClassConstructorType<object> | ObjectFactory<object>;
}

export class ServiceLocator {
    private readonly services: ServicesMap = {};
    private readonly serviceFactory: ServicesFactoryMap = {};
    private recursionLevel: number = 0;

    /**
     * Initialize the Service Locator
     */
    constructor() {
        this.services = {};
        this.serviceFactory = {};

        this.set('serviceLocator', this);
    }

    /**
     * Register multiple factory/constructor-identifier combinations
     *
     * @param {ServicesFactoryMap|object} configuration
     * @return {this}
     */
    public registerMultiple(configuration: ServicesFactoryMap): this {
        const identifiers = Object.keys(configuration);
        for (let i = 0; i < identifiers.length; i++) {
            const identifier = identifiers[i];
            this.register(identifier, configuration[identifier]);
        }

        return this;
    }

    /**
     * Register the factory/constructor for the given service identifier
     *
     * @param {string} identifier
     * @param {ClassConstructorType<T>} constructor
     * @return {this}
     */
    public register<T extends object>(
        identifier: string,
        constructor: ClassConstructorType<T> | ObjectFactory<T>
    ): this {
        this._assertIdentifier(identifier);
        this._assertFactory(constructor);
        this.serviceFactory[identifier] = constructor;

        return this;
    }

    /**
     * Set the instance for the given service identifier
     *
     * @param {string} identifier
     * @param {object} instance
     * @return {this}
     */
    public set(identifier: string, instance: object): this {
        this._assertIdentifier(identifier);
        this.services[identifier] = instance;

        return this;
    }

    /**
     * Return the instance for the given service identifier
     *
     * If a service instance for the given identifier is already registered, it will be returned. If no instance is
     * found a matching service factory is looked up. If none is found an exception will be thrown
     *
     * @param {string} identifier
     * @returns {object}
     */
    public get<T>(identifier: string): T {
        this._assertIdentifier(identifier);

        let instance = this.services[identifier];
        if (!instance) {
            instance = this.create(identifier);
            this.set(identifier, instance);
        }
        return instance as any as T;
    }

    /**
     * Create a new instance for the given service identifier and will invoke `didResolveDependencies` if it exists
     *
     * @param {string} identifier
     * @param {*} [additionalArgument]
     * @returns {T}
     */
    public create<T extends object>(identifier: string, additionalArgument: any = undefined): T {
        this._assertIdentifier(identifier);

        let instance: T;
        const withArgument = arguments.length > 1;

        if (arguments.length > 2) {
            throw new RangeError('Too many arguments');
        }

        const _serviceFactoryCallback = this.serviceFactory[identifier];
        if (!_serviceFactoryCallback) {
            throw new ReferenceError('Could not find service with identifier ' + identifier);
        }
        if (_serviceFactoryCallback.prototype && _serviceFactoryCallback.prototype.constructor) {
            instance = this.createInstance(
                withArgument,
                _serviceFactoryCallback as ClassConstructorType<T>,
                additionalArgument
            );
        } else {
            instance = this.invokeFactory(
                withArgument,
                _serviceFactoryCallback as ObjectFactory<T>,
                additionalArgument
            );
        }

        if (typeof (instance as InstanceWithResolveDependencies).didResolveDependencies === 'function') {
            (instance as InstanceWithResolveDependencies).didResolveDependencies();
        }

        return instance;
    }

    /**
     * Resolves the dependencies defined in the prototype's "needs" property
     *
     * @param {T} instance
     * @param {ClassConstructorType<T>} serviceClass
     * @return {T}
     */
    public resolveDependencies<T extends InstanceWithNeeds>(instance: T, serviceClass: ClassConstructorType<T>): T {
        let dependencies:string[]|null = null;

        if (instance && typeof instance.needs === 'object') {
            dependencies = instance.needs;
        }

        // @ts-ignore
        if (serviceClass['needs'] && typeof serviceClass['needs'] === 'function') {
            // @ts-ignore
            dependencies = serviceClass['needs']();
        }

        if (dependencies) {
            const dependenciesLength = dependencies.length;

            if (++this.recursionLevel > 1000) {
                throw new RangeError('Maximum recursion level exceeded');
            }
            for (let i = 0; i < dependenciesLength; i++) {
                const dependency = dependencies[i].split(':', 2);
                const dependencyIdentifier: string = dependency[0];
                const dependencyProperty: string = (dependency[1] || dependencyIdentifier);
                // @ts-ignore
                instance[dependencyProperty] = this.get(dependencyIdentifier);
            }
            this.recursionLevel--;
        }

        return instance;
    }

    private invokeFactory<T extends object>(
        withArgument: boolean,
        _serviceFactoryCallback: ObjectFactory<T>,
        additionalArgument: any
    ): T {
        return withArgument ? _serviceFactoryCallback(additionalArgument) : _serviceFactoryCallback();
    }

    private createInstance<T extends object>(
        withArgument: boolean,
        _serviceFactoryCallback: ClassConstructorType<T>,
        additionalArgument: any
    ): T {
        return this.resolveDependencies(
            withArgument ? new _serviceFactoryCallback(additionalArgument) : new _serviceFactoryCallback(),
            _serviceFactoryCallback
        );
    }

    /**
     * Tests if the given name is a valid service identifier
     *
     * @param {*} identifier
     * @private
     */
    private _assertIdentifier(identifier: string) {
        if (typeof identifier !== 'string') {
            throw new ReferenceError('Given service name is not of type string');
        }
    }

    /**
     * Tests if the given value is a valid service factory
     *
     * @param {*} constructor
     * @private
     */
    private _assertFactory<T extends object>(constructor: ClassConstructorType<T> | ObjectFactory<T>) {
        if (typeof constructor !== 'function') {
            throw new ReferenceError('Given service constructor is not callable');
        }
    }
}
