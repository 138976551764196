import { DomainObject } from '../Model/DomainObject';

export class CollectionService {
    public static removeItemFromCollection<T extends DomainObject>(
        item: T,
        collection: T[],
        useGuid: boolean = false
    ): T[] {
        const collectionClone = collection.slice();
        const indexOfItem = CollectionService.findIndex(item, collectionClone, useGuid);
        if (indexOfItem >= 0) {
            collectionClone.splice(indexOfItem, 1);
        } else {
            console.error('Could not find item in collection. This warning will be changed into a JavaScript Error');
        }

        return collectionClone;
    }

    public static isContainedIn<T extends DomainObject>(
        item: T,
        collection: T[] | string[],
        useGuid: boolean = false
    ): boolean {
        return -1 < CollectionService.findIndex(item, collection, useGuid);
    }

    public static findIndex<T extends DomainObject>(
        item: T,
        collection: T[] | string[],
        useGuid: boolean = false
    ): number {
        const testValue = useGuid ? item.guid : item.uid;

        return collection.findIndex((current: T | string) => {
            if (typeof current === 'string') {
                return testValue === current;
            }

            if (current === null || current === undefined) {
                return false;
            }

            if (useGuid) {
                return testValue === current.guid;
            } else {
                return testValue === current.uid;
            }
        });
    }
}
