import { Offer } from '../Model/Offer/Offer';
import { AbstractExportService } from './Export/AbstractExportService';
import { ExportOffer } from './Export/ExportOffer';

export class ExportService extends AbstractExportService {
    public exportOffer(offer: Offer) {
        this.performExport(offer);
    }

    protected getApiPath(exportOffer: ExportOffer): string {
        return 'export/' + exportOffer.offer.uid;
    }

    protected handleResponse(exportOffer: ExportOffer, promise: Promise<Response>): void {
        promise
            .then(() => window.alert('Die Offerte wurde erfolgreich exportiert'))
            .catch(e => {
                console.error(e);
                if (!navigator.onLine) {
                    window.alert('Ein Fehler ist aufgetreten: Keine Verbindung zum Internet');
                } else {
                    window.alert('Ein Fehler ist aufgetreten: ' + e.toString());
                }
            });
    }
}
