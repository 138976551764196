import * as React from 'react';
import { SortEnd } from 'react-sortable-hoc';
import { AppContext, AppContextType } from '../../../AppContextType';
import { SortableContainer } from '../../../DragNDrop/SortableContainer';
import { SortableHelper } from '../../../DragNDrop/SortableHelper';
import { Favourite } from '../../../Model/Offer/Favourite';
import { getGroupName, Group } from '../../../Model/Offer/Favourite/Group';
import { FavouriteGroup } from '../../../Model/Offer/FavouriteGroup';
import { User } from '../../../Model/User';
import { FavouriteServiceInterface } from '../../../Service/FavouriteServiceInterface';
import { VariantResultView } from '../Variant/VariantResultView';
import { FavouriteHandler, FavouriteView } from './FavouriteView';

export interface GroupViewProps {
    group: Group,
    selectedFavourites: Favourite[],
    close: () => void,
    onSelect: FavouriteHandler,
    onUnselect: FavouriteHandler,
}

interface GroupViewState {
    items: Favourite[],
    favouriteGroup?: FavouriteGroup | undefined
}

export class GroupView extends React.Component<GroupViewProps, GroupViewState> {
    public static contextType = AppContextType;
    public context: AppContext;
    private readonly favouriteService: FavouriteServiceInterface;

    constructor(props: GroupViewProps, context: AppContext) {
        super(props, context);
        this.favouriteService = context.serviceLocator.get('favouriteService');

        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.handleClickRemove = this.handleClickRemove.bind(this);
        this.handleClickLoad = this.handleClickLoad.bind(this);

        this.state = {items: []};
    }

    public render() {
        const group = this.props.group;

        return <div className="favourite-group-container">
            <header><h3>{getGroupName(group)}</h3></header>
            <div className="favourite-container">
                {this.renderInner()}
            </div>
        </div>;
    }

    public componentDidMount(): void {
        const user = this.context.offer.login;
        if (user) {
            this.favouriteService.getFavouriteGroup(user, this.props.group).then(favouriteGroup => {
                if (favouriteGroup) {
                    this.setState(
                        {
                            favouriteGroup,
                            items: favouriteGroup.items
                        }
                    );
                }
            });
        }
    }

    private renderInner() {
        const items = this.state.items;

        if (items.length > 0) {
            return <SortableContainer className="favourite-container-inner"
                                      childClassName="favourite-container-item grid-3"
                                      helperClass="-dragging"
                                      onSortEnd={this.handleSortEnd}
                                      useDragHandle={true}>
                {items.map((f, i) => <FavouriteView key={i}
                                                    selected={this.isSelected(f)}
                                                    onSelect={this.props.onSelect}
                                                    onUnselect={this.props.onUnselect}
                                                    onClickLoad={this.handleClickLoad}
                                                    onClickRemove={this.handleClickRemove}
                                                    favourite={f}/>)}
            </SortableContainer>;
        } else {
            return <p>Keine Favoriten vorhanden</p>;
        }
    }

    private handleSortEnd(sortingUpdate: SortEnd) {
        const items = SortableHelper.reorderElements(this.state.items, sortingUpdate);

        const favouriteGroup = this.state.favouriteGroup;
        if (!favouriteGroup) {
            throw new ReferenceError(`Favourite Group for ${this.props.group} could not be found`);
        }

        const user = this.context.offer.login as User;
        this.favouriteService.storeGroup(user, favouriteGroup.withFavourites(items));

        this.setState({items});
    }

    private handleClickRemove(favourite: Favourite) {
        const user = this.context.offer.login as User;
        this.favouriteService.removeFavourite(user, favourite).then(favouriteGroup => {
            this.setState({items: favouriteGroup.items});
        });
    }

    private handleClickLoad(favourite: Favourite) {
        const context = this.context;
        const handler = context.handler;
        const refractionPair = this.context.offer.refractionPair;
        const refractions = refractionPair ? refractionPair.collectRefractions() : [];
        console.debug(`[GroupView] Try to load favourite`, favourite);

        this.favouriteService.buildVariant(favourite, refractions).then(
            variantResult => {
                VariantResultView({results: [variantResult]});
                const variant = variantResult.variant;
                variant.uid = '' + (handler.offer.detectHighestVariantUid() + 1);
                handler.handleAddVariant(variant);
                this.props.close();
            });
    }

    private isSelected(f: Favourite): boolean {
        return this.props.selectedFavourites.indexOf(f) > -1;
    }
}
