import { PropertyTypeOptions, ra_property } from '@cundd/rest-adapter';
import { CollectionService } from '../../Service/CollectionService';
import { Favourite } from './Favourite';
import { Group } from './Favourite/Group';

export class FavouriteGroup {
    get group(): Group {
        return this._group;
    }

    get items(): Favourite[] {
        if (typeof this._items === 'undefined') {
            return [];
        }
        return this._items;
    }

    get length(): number {
        return this._items.length;
    }

    @ra_property(String, 'group')
    private readonly _group: Group;

    @ra_property(Favourite, PropertyTypeOptions.Multiple, 'items')
    private _items: Favourite[];

    constructor(group: Group, items: Favourite[]) {
        this._items = items;
        this._group = group;
    }

    public postConstruct() {
        this._items = this._items.map((favourite, index) => patchFavouriteUid(favourite, index));
    }

    public withAddedFavourite(favourite: Favourite): FavouriteGroup {
        this.assertFavourite(favourite);

        const items = this._items.slice();
        items.push(favourite);

        return new FavouriteGroup(this._group, items);
    }

    public withRemovedFavourite(favourite: Favourite): FavouriteGroup {
        this.assertFavourite(favourite);

        const items = CollectionService.removeItemFromCollection(favourite, this._items);

        return new FavouriteGroup(this._group, items);
    }

    public withFavourites(items: Favourite[]): FavouriteGroup {
        return new FavouriteGroup(this._group, items);
    }

    private assertFavourite(favourite: Favourite) {
        if (!(favourite instanceof Favourite)) {
            throw new TypeError(`Argument 'favourite' must be an instance of 'Favourite'`);
        }
    }
}

/**
 * UID of Favourites have previously been copied from the Variant. This lead to multiple Favourite entries with the
 * same UID in the database.
 *
 * The method will fix the given Favourite's UID by adding the current timestamp to the UID value, if it is below a
 * threshold.
 *
 * @param {Favourite} favourite
 * @param {number} index
 * @return {Favourite}
 */
function patchFavouriteUid(favourite: Favourite, index: number): Favourite {
    (favourite as PatchedFavourite)['_originalUid'] = favourite.uid;

    const uidNumber = parseInt(favourite.uid, 10);
    if (isNaN(uidNumber) || uidNumber < 1567083987300) {
        console.debug('[FavouriteGroup] Patch loaded Favourite UID');
        favourite.uid = '' + ((+new Date()) + index);
    }

    return favourite;
}

class PatchedFavourite extends Favourite {
    _originalUid: string;
}
