import { Offer } from '../Model/Offer/Offer';
import { Variant } from '../Model/Offer/Variant';
import { PrismService } from './PrismService';

export class VariantBuilder {
    private readonly prismService: PrismService;

    constructor(prismService: PrismService) {
        this.prismService = prismService;
    }

    /**
     * Prepare a new Variant for the given Offer
     *
     * @param offer
     */
    public buildNewVariant(offer: Offer): Variant | undefined {
        const refractionPair = offer.refractionPair;
        if (!refractionPair) {
            console.warn(`[${this.constructor.name}] Can not show New Variant popup without a Refraction Pair`);
            return;
        }

        const hasPrismRight = refractionPair.right && this.prismService.hasPrism(refractionPair.right);
        const hasPrismLeft = refractionPair.left && this.prismService.hasPrism(refractionPair.left);
        const symmetrical = hasPrismLeft === hasPrismRight;

        const uid = offer.detectHighestVariantUid() + 1;

        return new Variant(uid, 'Variante ' + uid, undefined, undefined, symmetrical);
    }
}
