import * as React from 'react';
import { Redirect } from 'react-router';
import { AppContext, AppContextType } from '../../AppContextType';

export class ClearActionView extends React.Component {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        /* Workaround for state change during rendering */
        setTimeout(() => {
            this.context.handler.clearOffer();
        }, 10);

        return <Redirect to={`${process.env.PUBLIC_URL}/offer/new`}/>;
    }
}
