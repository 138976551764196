export function unwrap<T>(input: T | undefined): T {
    if (input === undefined) {
        throw new TypeError('Called unwrap on `undefined`');
    }
    return input;
}

export function unwrapNotNull<T>(input: T | null | undefined): T {
    if (input === undefined) {
        throw new TypeError('Called unwrap on `undefined`');
    }
    if (input === null) {
        throw new TypeError('Called unwrap on `NULL`');
    }
    return input;
}
