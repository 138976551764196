import { History } from 'history';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Config } from '../../../Config';
import { Customer } from '../../../Model/Customer/Customer';
import { Offer } from '../../../Model/Offer/Offer';
import { CustomerOfferService } from '../../../Service/CustomerOfferService';
import { Popup, PopupStyle } from '../../Popup';
import { OfferRow } from './OfferRow';

export interface OfferPopupProps {
    history: History
}

interface OfferPopupState {
    offers: Offer[]
    showModal: boolean
}

export class OfferPopup extends React.Component<OfferPopupProps, OfferPopupState> {
    public static contextType = AppContextType;
    public context: AppContext;
    private readonly customerOfferService: CustomerOfferService;

    private redirectIfNoLogin = false;

    constructor(props: OfferPopupProps, context: AppContext) {
        super(props, context);
        this.close = this.close.bind(this);
        this.handleClickLoad = this.handleClickLoad.bind(this);

        this.customerOfferService = context.serviceLocator.get('customerOfferService');

        this.state = {offers: [], showModal: false};
    }

    public render() {
        const user = this.context.offer.login;
        const config = new Config();
        const basePath = config.basePath;

        if (!user && this.redirectIfNoLogin) {
            return <Redirect to={`${basePath}/`}/>;
        }

        return <Popup style={PopupStyle.Fullscreen} onDismiss={this.close} header={this.renderHeader()}>
            <div className="import-offer-container">
                {this.renderInner(this.state.offers)}
            </div>
        </Popup>;
    }

    public componentDidMount(): void {
        this.loadOffers();
    }

    public componentWillReceiveProps(nextProps: Readonly<OfferPopupProps>, nextContext: any): void {
        this.loadOffers();
    }

    private loadOffers() {
        const customer = this.customer;
        if (customer) {
            this.customerOfferService.findByCustomer(customer).then(exportOffers => this.setState(() => {
                const offers = exportOffers.map(i => i.offer);

                return {offers};
            }));
        }
    }

    private renderInner(offers: Offer[]): JSX.Element {
        if (!this.customer) {
            return <div className="import-offer-notice">
                Bitte wählen Sie einen&nbsp;
                <button className="btn">
                    <Link to='/offer/new'>Kunden</Link>
                </button>
            </div>;
        }
        if (offers.length === 0) {
            return <div className="import-offer-notice">Keine Offerten gefunden</div>;
        }

        const offersSorted = offers.sort((a, b) => {
            const bTimestamp = b.createdAt.valueOf();
            const aTimestamp = a.createdAt.valueOf();
            if (aTimestamp === bTimestamp) {
                return 0;
            }
            return aTimestamp > bTimestamp ? -1 : 1;
        });

        return <>
            <table className="import-offer-table">
                <thead>
                <tr>
                    <th/>
                    <th>Datum</th>
                    <th>Brillenfassung</th>
                    <th>Varianten</th>
                </tr>
                </thead>
                <tbody>
                {offersSorted.map(o => <OfferRow key={o.uid} offer={o} onClick={this.handleClickLoad}/>)}
                </tbody>
            </table>
        </>;
    }

    private get customer(): Customer | undefined {
        return this.context.handler.offer.customer;
    }

    private close() {
        this.props.history.goBack();
    }

    private renderHeader() {
        const customer = this.context.handler.offer.customer;

        if (customer) {
            return <div className="offer-load">Offerten für {customer.fullName}</div>;
        } else {
            return null;
        }
    }

    // noinspection JSUnusedLocalSymbols
    private handleClickLoad(event: SyntheticEvent, offer: Offer) {
        console.debug(`[${this.constructor.name}] Handle click load`, offer);
        this.context.handler.loadOffer(offer);
        this.props.history.push('/offer/new');
    }
}
