import * as React from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { Frame } from '../../Model/Catalog/Frame';
import { SearchSuggest } from '../Form/SearchSuggest';

export class FrameSelectorView extends React.Component {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        return (
            <label className="catalog-frame-selector">
                <h1>Brillenfassung</h1>
                <SearchSuggest
                    getOptionName={this.getOptionName}
                    onSelect={this.context.handler.handleFrameChange}
                    repository={this.context.serviceLocator.get('frameRepository')}/>
            </label>
        );
    }

    private getOptionName(frame: Frame|undefined): string {
        if (frame === undefined) {
            return '';
        }
        if (frame.isCustomerFrame) {
            return `${frame.collection.name}: ${frame.model.name}`;
        }
        const stock = frame.stock > 0 ? frame.stock.toFixed(0) : 'nicht auf Lager';

        return `${frame.uid} - ${frame.collection.name} ${frame.model.name}, ${frame.colorVariant} (${stock})`;
    }
}
