export interface GuidServiceInterface {
    uuidv4(): string;
}

export class GuidService {
    /**
     * Return the shared Guid Service instance
     *
     * @return {GuidServiceInterface}
     */
    public static get sharedInstance(): GuidServiceInterface {
        if (!GuidService._instance) {
            GuidService._instance = new GuidService();
        }
        return GuidService._instance;
    }

    /**
     * Return the shared Guid Service instance
     *
     * @return {GuidServiceInterface}
     */
    public static get default(): GuidServiceInterface {
        return new GuidService();
    }

    /**
     * Define the shared Guid Service instance to use
     *
     * @param {GuidServiceInterface} instance
     */
    public static setSharedInstance(instance: GuidServiceInterface): void {
        GuidService._instance = instance;
    }

    private static _instance: GuidServiceInterface;

    private constructor() {
    }

    public uuidv4(): string {
        // The GUID is not cryptographically relevant, so we fall back to a `Math.random()` based solution
        if (typeof window !== 'undefined' && window.crypto) {
            // @ts-ignore
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                // eslint-disable-next-line no-mixed-operators
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        } else {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                const r = Math.random() * 16 | 0;
                // eslint-disable-next-line no-mixed-operators
                const v = c === 'x' ? r : (r & 0x3 | 0x8);

                return v.toString(16);
            });
        }
    }
}
