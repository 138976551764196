import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { CatalogModelInterface } from '../CatalogModelInterface';
import { AbstractLenses } from './AbstractLenses';
import { Lenses } from './Lenses';

@ra(ClassTypeOptions.AddUnknownFields)
export class Product extends AbstractLenses implements CatalogModelInterface, Clone {
    @ra_property()
    public name: string;

    @ra_property()
    public price: number;

    public static fromLenses(lenses: Lenses | Product): Product {
        const instance = new Product();
        const groupUid = lenses.groupUid;
        instance.uid = groupUid;
        instance.groupUid = groupUid;
        instance.colorName = lenses.colorName;
        instance.additionMaterial = lenses.additionMaterial;
        instance.name = lenses.name;
        instance.price = lenses.price;
        instance.vendorUid = lenses.vendorUid;
        instance.inhousePriceId = lenses.inhousePriceId;
        instance.nameInternal = lenses.nameInternal;
        instance.diameter = lenses.diameter;

        return instance;
    }

    public clone<T extends Clone = this>(): this | T {
        return Product.fromLenses(this);
    }
}
