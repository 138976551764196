import * as React from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Favourite } from '../../../Model/Offer/Favourite';
import { Group } from '../../../Model/Offer/Favourite/Group';
import { Variant } from '../../../Model/Offer/Variant';
import { User } from '../../../Model/User';
import { FavouriteService } from '../../../Service/FavouriteService';
import { GroupItem } from './GroupItem';

export interface AddButtonProps {
    variant: Variant,
    className?: string,
    disabled?: boolean,
    onAddFavouriteClick?: string,
}

export interface AddButtonState {
    showGroups: boolean
}

export class AddButton extends React.Component<AddButtonProps, AddButtonState> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: AddButtonProps, context: any) {
        super(props, context);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);

        this.state = {
            showGroups: false
        };
    }

    public render() {
        const showGroups = this.state.showGroups;
        const containerClass = 'favourite-button-add-container' + (showGroups ? ' -open' : '');

        return <div className={containerClass}>
            <button className={this.props.className} onClick={this.handleAddClick} disabled={!!this.props.disabled}>
                <i className="material-icons">star</i>
                <span className="title">Als Favorit speichern</span>
            </button>
            {showGroups ? this.renderGroups() : null}
        </div>;
    }

    private renderGroups() {
        return <ul className="favourite-button-add-groups">{
            Object.keys(Group).map(
                g => <GroupItem key={g} group={Group[g]} onSelectGroup={this.handleSelectGroup}/>
            )
        }</ul>;
    }

    private handleAddClick() {
        this.setState(previousState => {
            return {showGroups: !previousState.showGroups};
        });
    }

    private handleSelectGroup(group: Group) {
        const variant = this.props.variant;
        const favourite = Favourite.fromVariant(
            +new Date(),
            group,
            variant
        );
        const context: AppContext = this.context;
        const favouriteService: FavouriteService = context.serviceLocator.get('favouriteService');
        favouriteService.appendFavourite(context.offer.login as User, favourite);
        this.setState({showGroups: false});
    }
}
