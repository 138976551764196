import { ra_property } from '@cundd/rest-adapter';
import { Group } from './Favourite/Group';
import { FavouriteGroup } from './FavouriteGroup';

type FavouriteGroupCollectionKeys = 'username' | 'singleVision' | 'varifocal' | 'activity' | 'sunglasses' | 'special';

export class FavouriteGroupCollection {
    @ra_property(String)
    public username: string | undefined;
    @ra_property(FavouriteGroup)
    protected singleVision: FavouriteGroup | undefined;
    @ra_property(FavouriteGroup)
    protected varifocal: FavouriteGroup | undefined;
    @ra_property(FavouriteGroup)
    protected activity: FavouriteGroup | undefined;
    @ra_property(FavouriteGroup)
    protected sunglasses: FavouriteGroup | undefined;
    @ra_property(FavouriteGroup)
    protected special: FavouriteGroup | undefined;

    constructor(
        username: string | undefined,
        singleVision?: FavouriteGroup,
        varifocal?: FavouriteGroup,
        activity?: FavouriteGroup,
        sunglasses?: FavouriteGroup,
        special?: FavouriteGroup
    ) {
        this.username = username;
        this.singleVision = singleVision;
        this.varifocal = varifocal;
        this.activity = activity;
        this.sunglasses = sunglasses;
        this.special = special;
    }

    public withGroup(group: FavouriteGroup): FavouriteGroupCollection {
        const clone = new FavouriteGroupCollection(
            this.username,
            this.singleVision,
            this.varifocal,
            this.activity,
            this.sunglasses,
            this.special,
        );

        clone.setGroup(group);

        return clone;
    }

    public setGroup(group: FavouriteGroup) {
        (this as any)[this.groupToProperty(group.group)] = group;
    }

    public getGroup(groupEnum: Group): FavouriteGroup | undefined {
        return (this as any)[this.groupToProperty(groupEnum)];
    }

    private groupToProperty(groupEnum: Group): FavouriteGroupCollectionKeys {
        const id = '' + groupEnum;

        return id.charAt(0).toLowerCase() + id.slice(1) as FavouriteGroupCollectionKeys;
    }
}
