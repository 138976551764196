import { FavouriteGroupCollection } from '../Model/Offer/FavouriteGroupCollection';
import { AbstractStorage } from './AbstractStorage';

export class FavouriteGroupStorage extends AbstractStorage<FavouriteGroupCollection> {
    protected getInstanceUid(instance: FavouriteGroupCollection) {
        return instance.username;
    }

    protected getNamespace(): string {
        return 'spectacles-favouritegroup';
    }

    protected getType(): { new(...args: any[]): FavouriteGroupCollection } {
        return FavouriteGroupCollection;
    }
}
