/**
 * A wrapper for a basic price
 */
import { InvalidPriceError } from './Error/InvalidPriceError';

export class Price {
    private readonly _price: number;

    /**
     * Return if the given value is a valid price
     *
     * @param {number|Price} price
     * @return {boolean}
     */
    public static isValidPrice(price: number | Price | any): boolean {
        return (price instanceof Price) || (typeof price === 'number');
    }

    /**
     * Check if the given value is a valid price
     *
     * @param {number|Price} price
     * @param {boolean} throwOnError
     * @return {boolean}
     * @throws {InvalidPriceError} if the assertion fails and `throwOnError` is TRUE
     */
    public static assertValidPrice(
        price: number | Price,
        throwOnError: boolean = true
    ): boolean {
        if (Price.isValidPrice(price)) {
            return true;
        }
        const message = 'Given value is not a valid price';
        if (throwOnError) {
            throw new InvalidPriceError(message);
        }

        return false;
    }

    constructor(price: number) {
        Price.assertValidPrice(price);
        this._price = price;
    }

    public valueOf(): number {
        return this._price;
    }

    public isNaN(): boolean {
        return isNaN(this._price);
    }
}
