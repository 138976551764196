import { Frame, FrameType } from '../Model/Catalog/Frame';
import { Picture } from '../Model/Picture';

export class FrameImageService {
    /**
     * Detect the picture to use as the main image for the given frame
     *
     * @param {Frame} frame
     * @return {Picture}
     */
    public getMainImage(frame: Frame): Picture {
        const imageId = frame.type === FrameType.default ? '02' : '01';

        const image = frame.pictures.find((image: Picture) => {
            const fileName = image.path.split('\\')[2];
            const reg = new RegExp('^00' + frame.uid + '(-1)?-' + imageId);

            return reg.test(fileName);
        });

        return image || frame.pictures[0];
    }
}
