import * as React from 'react';
import { Frame } from '../../../Model/Catalog/Frame';
import { label, PriceModel } from '../../../Model/Offer/PriceModel';
import { FramePriceCalculator } from '../../../Service/FramePriceCalculator';
import { Currency } from '../../Formatter/Currency';
import { Popup } from '../../Popup';
import { EconomyOptionView } from '../EconomyOptionView';
import { AbstractPriceModelBox, PriceModelBoxState } from './AbstractPriceModelBox';
import { EconomyOptionDetail } from './Detail/EconomyOptionDetail';
import { ServiceFeeOptionDetail } from './Detail/ServiceFeeOptionDetail';

export class Economy extends AbstractPriceModelBox {
    protected get priceModel(): PriceModel {
        return PriceModel.economy;
    }

    protected renderPopup(frame: Frame, state: PriceModelBoxState): JSX.Element | null {
        if (state.showPopup) {
            return <Popup onDismiss={this.handleOptionsDismiss}>
                <EconomyOptionView onSaveClick={this.handleOptionsDismiss} frame={frame}/>
            </Popup>;
        } else {
            return null;
        }
    }

    protected renderButton(): JSX.Element {
        if (this.isActive()) {
            // Render the split economy Buttons
            return (
                <div className="button-group -multiple">
                    <button className="select-button"
                            onClick={this.handleSelectClick}>{label(this.priceModel)}</button>
                    <button className="options-button" onClick={this.handleOptionsClick}>+</button>
                </div>
            );
        } else {
            return super.renderButton();
        }
    }

    protected renderContentBelow(): JSX.Element | null {
        return (
            <div className="frame-price-box-below grid-12">
                <ul>
                    <ServiceFeeOptionDetail option={this.offer.serviceFeeOption}/>
                    {this.offer.economyOptions.map(o => <EconomyOptionDetail key={o.uid} option={o}/>)}
                </ul>
            </div>
        );
    }

    protected renderPriceSection(): JSX.Element | null {
        const calc = new FramePriceCalculator();
        const framePrice = calc.buildPriceForOfferWithPriceModel(this.offer, PriceModel.economy);

        if (!framePrice) {
            return null;
        }

        return (
            <div className="frame-price-box-price -multi-row grid-4">
                <div className="price-row base">
                    <span className="currency">CHF </span>
                    <span className="price"><Currency value={framePrice.base}/></span>
                </div>
                <div className="price-row total">
                    <span className="currency">CHF </span>
                    <span className="price"><Currency value={framePrice.total}/></span>
                </div>
            </div>
        );
    }
}
