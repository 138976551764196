import * as React from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Frame } from '../../../Model/Catalog/Frame';
import { PriceModel } from '../../../Model/Offer/PriceModel';
import { Confirm } from '../../Modal/Confirm';
import { PriceModelBoxStatus } from '../PriceModelBox/PriceModelBoxStatus';
import { DetailWidgetProps } from './DetailWidgetProps';
import { FrameImage } from './FrameImage';

interface DetailWidgetState {
    showDeleteModal: boolean
}

export abstract class AbstractDetailWidget extends React.Component<DetailWidgetProps, DetailWidgetState> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: DetailWidgetProps, context: any) {
        super(props, context);
        this.handleClearOfferClick = this.handleClearOfferClick.bind(this);
        this.handleModalCancel = this.handleModalCancel.bind(this);
        this.handleModalConfirm = this.handleModalConfirm.bind(this);

        this.state = {
            showDeleteModal: false
        };
    }

    public render() {
        const frame = this.props.frame;

        return (
            <section className={this.getSectionClass()}>
                <h1>Brillenfassung</h1>
                <div className="row-container">
                    <div className="catalog-frame-info grid-4" title={frame.uid}>
                        <FrameImage wrapperClassName="catalog-frame-info-image" frame={frame}/>
                        {this.renderFrameInfo(frame)}
                        {this.renderConfirm(frame)}
                    </div>
                    <div className="catalog-frame-pricing grid-8">
                        {frame.isCustomerFrame ? null : this.renderPricing()}
                    </div>
                </div>
            </section>
        );
    }

    protected abstract getSectionClass(): string;

    protected abstract renderPricing(): JSX.Element;

    protected renderFrameInfo(frame: Frame) {
        if (frame.isCustomerFrame) {
            return <>
                <a className="h3" onClick={this.handleClearOfferClick}>{frame.collection.name}</a>
                <p>{frame.model.name}</p>
            </>;
        } else {
            return <>
                <a className="h3" onClick={this.handleClearOfferClick}>{frame.collection.name}</a>
                <p>{frame.model.name}, {frame.colorVariant}</p>
            </>;
        }
    }

    protected renderConfirm(_frame: Frame) {
        return <Confirm show={this.state.showDeleteModal}
                        message={`Auswahl löschen und neue Fassung wählen?`}
                        onCancel={this.handleModalCancel}
                        onConfirm={this.handleModalConfirm}
                        cancelClassName="favourite-remove-confirm -confirm-no"
                        confirmClassName="favourite-remove-confirm -confirm-yes -danger"/>;
    }

    protected getStatusForPriceModel(priceModel: PriceModel): PriceModelBoxStatus {
        const offer = this.context.handler.offer;
        const activePriceModel = offer.priceModel;

        if (priceModel === activePriceModel) {
            return PriceModelBoxStatus.active;
        } else {
            return PriceModelBoxStatus.inactive;
        }
    }

    private handleClearOfferClick() {
        this.setState({showDeleteModal: true});
    }

    private handleModalCancel() {
        this.setState({showDeleteModal: false});
    }

    private handleModalConfirm() {
        this.setState({showDeleteModal: false});
        this.context.handler.handleFrameChange(undefined);
    }
}
