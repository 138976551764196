import * as React from 'react';
import { BaseModel } from '../../Model/BaseModel';
import { Suggest, SuggestProps } from './Suggest';

export class SearchSuggest<T extends BaseModel> extends React.Component<SuggestProps<T>> {
    public render() {
        return (
            <Suggest {...this.props} className="suggest search-suggest">
                <i className="material-icons" aria-hidden="true">search</i>
            </Suggest>
        );
    }
}
