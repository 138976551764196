import * as React from 'react';
import { PriceModel } from '../../../Model/Offer/PriceModel';
import { Economy } from '../PriceModelBox/Economy';
import { Lifetime } from '../PriceModelBox/Lifetime';
import { PriceModelBoxStatus } from '../PriceModelBox/PriceModelBoxStatus';
import { AbstractDetailWidget } from './AbstractDetailWidget';

export class Collapsed extends AbstractDetailWidget {
    protected getSectionClass(): string {
        return 'catalog-frame-detail-widget -collapsed';
    }

    protected renderPricing(): JSX.Element {
        const frame = this.props.frame;

        return <>
            <div className="row-container">
                <Lifetime status={this.getStatusForPriceModel(PriceModel.lifetime)}
                          frame={frame}/>
                {frame.priceEconomy > 0
                    ? <Economy status={this.getStatusForPriceModel(PriceModel.economy)} frame={frame}/>
                    : null}
            </div>
        </>;
    }

    protected getStatusForPriceModel(priceModel: PriceModel): PriceModelBoxStatus {
        if (super.getStatusForPriceModel(priceModel) === PriceModelBoxStatus.inactive) {
            return PriceModelBoxStatus.minified;
        } else {
            return PriceModelBoxStatus.active;
        }
    }
}
