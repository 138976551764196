import * as React from 'react';
import { refractionProperties } from '../../../../Model/Refraction';
import { HeaderRowProps } from './HeaderRowProps';

export class ColumnHeaderRow extends React.PureComponent<HeaderRowProps> {
    public render() {
        const refractionPair = this.props.refractionPair;
        const className = 'customer-refraction-pair-item-column-header-row'
            + (this.props.isSelected ? ' -selected' : ' -not-selected');

        return <tr key={refractionPair.uid + '-column-header'} className={className} onClick={this.props.onClick}>
            {Object.keys(refractionProperties).map(
                key => <th key={refractionPair.uid + '-column-header-' + key}>{refractionProperties[key]}</th>
            )}
        </tr>;
    }
}
