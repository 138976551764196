import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppContext, AppContextType } from '../AppContextType';
import logo from './../logo.svg';
import logoSmall from './../logo_small.svg';

interface ViewProps {
    big?: boolean
    className?: string
}

export class Header extends React.Component<ViewProps> {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        const props = this.props;
        const big = props.big;
        const className = big ? 'grid-4' : 'grid-7';

        const logoColumn = (
            <div className={className}>
                <Link className="logo-link" to={`${process.env.PUBLIC_URL}/`}>
                    <img src={big ? logo : logoSmall} className="main-logo" alt="Federer Augenoptik"/>
                </Link>
            </div>
        );

        const headerClass = 'main-header container ' + (big ? '-big ' : '-small ') + (props.className || '');

        return (
            <header className={headerClass}>
                {logoColumn}
                {props.children}
            </header>
        );
    }
}

