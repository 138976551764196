import { Addition } from '../../Catalog/Lenses/Addition';
import { Lenses } from '../../Catalog/Lenses/Lenses';
import { EyeConfiguration as VariantEyeConfiguration } from '../Variant/EyeConfiguration';
import { Entry } from './Entry';

const getEntryOrUndefined = (v: Lenses | Addition | undefined): Entry | undefined => {
    return !v ? undefined : Entry.fromCatalogModel(v);
};

export interface EyeConfiguration {
    lenses: Entry | undefined,
    coating: Entry | undefined,
    miscellaneous: Entry[]
}

export function favouriteDataFromEye(eye: VariantEyeConfiguration | undefined): EyeConfiguration {
    if (!eye) {
        return {lenses: undefined, coating: undefined, miscellaneous: []};
    }
    const lenses = getEntryOrUndefined(eye.lenses);
    const coating = getEntryOrUndefined(eye.coating);
    const miscellaneous = eye.miscellaneous.map(a => Entry.fromCatalogModel(a));

    return {lenses, coating, miscellaneous};
}
