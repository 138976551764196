import { Equal } from 'iresults-ts-core';
import { ra_property } from '@cundd/rest-adapter';
import { Refraction } from '../Refraction';

export class RefractionPair implements Equal {
    @ra_property()
    public uid: string;

    @ra_property()
    public customerUid: string;

    @ra_property()
    public creationDate: string;

    @ra_property()
    public notice: string;

    @ra_property(Refraction)
    public right?: Refraction;

    @ra_property(Refraction)
    public left?: Refraction;

    public collectRefractions(): Refraction[] {
        const right = this.right;
        const left = this.left;

        if (right && left) {
            return [right, left];
        }
        if (right) {
            return [right];
        }
        if (left) {
            return [left];
        }
        return [];
    }

    public equals<T>(other: T): boolean {
        if (other instanceof RefractionPair) {
            return this.uid === other.uid;
        } else {
            return false;
        }
    }
}
