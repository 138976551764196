import * as React from 'react';
import { Settings } from '../../Model/Settings/Settings';
import { UpperSectionStyle } from '../../Model/Settings/UpperSectionStyle';
import { Collapsed } from './DetailWidget/Collapsed';
import { Detailed } from './DetailWidget/Detailed';
import { DetailWidgetProps as BaseDetailWidgetProps } from './DetailWidget/DetailWidgetProps';

interface DetailWidgetProps extends BaseDetailWidgetProps {
    settings: Settings
}

export class DetailWidget extends React.Component<DetailWidgetProps> {
    public render() {
        if (this.props.settings.upperSectionStyle === UpperSectionStyle.DETAILED) {
            return <Detailed {...this.props}/>;
        } else {
            return <Collapsed{...this.props}/>;
        }
    }
}
