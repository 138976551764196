import * as React from 'react';
import { Favourite } from '../../../../Model/Offer/Favourite';
import { Entry } from '../../../../Model/Offer/Favourite/Entry';
import { FavouriteViewProps } from '../FavouriteViewProps';

interface ViewProps extends FavouriteViewProps {
    showEmpty?: boolean
}

export class MiscellaneousRow extends React.Component<ViewProps> {

    public render() {
        const favourite = this.props.favourite;
        if (favourite.symmetrical) {
            return <>
                {this.renderHeader()}
                {this.renderSymmetrical(favourite)}
            </>;
        } else {
            return <>
                {this.renderHeader()}
                {this.renderAsymmetrical(favourite)}
            </>;
        }
    }

    private renderHeader() {
        return <tr className="header">
            <th colSpan={2}>Diverses</th>
        </tr>;
    }

    private renderSymmetrical(favourite: Favourite) {
        const captionClassName = 'caption';

        if (!favourite.rightMiscellaneous) {
            return null;
        }

        return <tr>
            <td className={captionClassName} colSpan={2}>
                <ul>
                    {this.renderItems(favourite.rightMiscellaneous)}
                </ul>
            </td>
        </tr>;
    }

    private renderAsymmetrical(favourite: Favourite) {
        const captionClassName = 'caption';

        return <>
            <tr>
                <td className={captionClassName}>
                    <ul>
                        {this.renderItems(favourite.rightMiscellaneous)}
                    </ul>
                </td>
                <td className={captionClassName}>
                    <ul>
                        {this.renderItems(favourite.leftMiscellaneous)}
                    </ul>
                </td>
            </tr>
        </>;
    }

    private renderItems(miscellaneous: Entry[] | undefined) {
        if (!miscellaneous) {
            return null;
        } else {
            return miscellaneous.map(m => <li key={m.uid}>{m.name}</li>);
        }
    }
}
