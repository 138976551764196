import * as React from 'react';
import { Refraction } from '../../../../Model/Refraction';
import { RefractionSide } from '../../../../Model/RefractionSide';

const prepareRefractionValue = (refraction: Refraction | undefined, property: keyof Refraction): string => {
    if (!refraction) {
        return '';
    }

    const value = refraction[property];
    if (value === null || value === undefined) {
        return '';
    }
    if (property === 'add' && value === 0.0) {
        return '';
    }

    if (property === 'side') {
        if (value === RefractionSide.Right) {
            return 'R';
        }
        if (value === RefractionSide.Left) {
            return 'L';
        }
        return '';
    }

    if (typeof value === 'number') {
        if (property === 'axis') {
            return value.toFixed(0);
        }

        return value.toFixed(2);
    }

    if (typeof value === 'string') {
        return value;
    }

    return '';
};

interface CellProps {
    property: keyof Refraction
    refraction: Refraction | undefined
}

export class Cell extends React.PureComponent<CellProps> {
    public render() {
        const props = this.props;

        return <>
            {prepareRefractionValue(props.refraction, props.property as keyof Refraction)}
        </>;
    }
}
