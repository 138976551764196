import * as React from 'react';
import { VariantContainer } from './VariantContainer';

export class LowerSection extends React.Component {
    public render() {
        return (
            <section className="offer-new-section offer-new-lower-section">
                <div className="offer-new-section-inner">
                    <h1>Brillengläser</h1>
                    <VariantContainer/>
                </div>
            </section>
        );
    }
}
