import { AuthenticationController } from '../Authentication/AuthenticationController';
import { Credentials } from '../ValueObject/Credentials';
import { BrowserStorage } from './BrowserStorage';
import { authorizationHeaderFromCredentials } from './RestAdapterFactory';

const STORE_NAMESPACE_AUT = 'spectacles-auth';

export class AuthenticationService {
    private _isAuthenticated: boolean;
    private _credentials: Credentials | undefined;
    private readonly authenticationController: AuthenticationController;
    private readonly browserStorage: BrowserStorage;

    constructor() {
        this._credentials = undefined;
        this.authenticationController = new AuthenticationController(document.getElementById('login') as Element);
        this.browserStorage = new BrowserStorage();
    }

    get credentials(): Credentials | undefined {
        return this._credentials;
    }

    get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    /**
     * Return a Promise that will fulfill if the User is authenticated
     *
     * @return {Promise<{}>}
     */
    public async authenticate(): Promise<unknown> {
        if (this._isAuthenticated) {
            return new Promise(resolve => {
                setTimeout(() => resolve(true), 600);
            });
        } else {
            const isAuthenticated = await this.tryAuthenticationMethods();
            this._isAuthenticated = isAuthenticated;

            return isAuthenticated;
        }
    }

    public async tryAnonymousAccess(): Promise<boolean> {
        return this.tryWithRequestSettings(undefined);
    }

    private async tryAuthenticationMethods() {
        const anonymousAccess = await this.tryAnonymousAccess();
        if (anonymousAccess) {
            return true;
        }

        if (this.hasStoredCredential()) {
            const storedCredentialAccess = await this.tryStoredCredentialAccess();
            if (storedCredentialAccess) {
                return true;
            }
        }


        return await this.tryCredentialAccess();
    }

    public async tryCredentialAccess(): Promise<boolean> {
        return new Promise(resolve => {
            const authenticationController = this.authenticationController;

            authenticationController.showForm(async credentials => {
                authenticationController.removeError();
                const valid = await this.tryCredentials(credentials);
                if (valid) {
                    authenticationController.hideForm();

                    resolve(true);
                } else {
                    authenticationController.setError('Login inkorrekt');
                }
            });
        });
    }

    private async tryStoredCredentialAccess(): Promise<boolean> {
        const browserStorage = this.browserStorage;

        const username = browserStorage.getItem(STORE_NAMESPACE_AUT, 'username') as string;
        const password = browserStorage.getItem(STORE_NAMESPACE_AUT, 'password') as string;
        const credentials = new Credentials(username, password);

        return await this.tryCredentials(credentials);
    }

    private async tryCredentials(credentials: Credentials) {
        const requestSettings: RequestInit = {
            headers: {'Authorization': authorizationHeaderFromCredentials(credentials)}
        };

        const validCredentials = await this.tryWithRequestSettings(requestSettings);
        if (validCredentials) {
            this._credentials = credentials;
        }

        return validCredentials;
    }

    private async tryWithRequestSettings(requestSettings: RequestInit | undefined): Promise<boolean> {
        const authorizationUri = `${process.env.REACT_APP_API_URL}authorization`;
        const response = await fetch(authorizationUri, requestSettings);

        return response.ok;
    }

    private hasStoredCredential(): boolean {
        const browserStorage = this.browserStorage;

        return browserStorage.hasItem(STORE_NAMESPACE_AUT, 'username') && browserStorage.hasItem(STORE_NAMESPACE_AUT, 'password');
    }
}
