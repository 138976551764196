import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone, Equal } from 'iresults-ts-core';
import { AbstractCatalogModel } from '../AbstractCatalogModel';
import { CatalogModelInterface } from '../CatalogModelInterface';

@ra(ClassTypeOptions.AddUnknownFields)
export class Addition extends AbstractCatalogModel implements CatalogModelInterface, Equal, Clone {
    @ra_property()
    public vendorUid: string;

    @ra_property()
    public code: string;

    @ra_property()
    public type: string;

    @ra_property()
    public costPrice: string;

    @ra_property()
    public salesPrice: string;

    @ra_property()
    public salesPriceL2: string;

    @ra_property()
    public recommendedRetailPrice: string;

    @ra_property()
    public name: string;

    get price(): number {
        return parseFloat(this.salesPrice);
    }

    public equals<T>(addition: T): boolean {
        if (!(addition instanceof Addition)) {
            return false;
        }

        return (
            this.uid === addition.uid
            && this.costPrice === addition.costPrice
            && this.salesPrice === addition.salesPrice
            && this.salesPriceL2 === addition.salesPriceL2
            && this.recommendedRetailPrice === addition.recommendedRetailPrice
        );
    }

    public clone<T extends Clone = this>(): this | T {
        const clone = new Addition();
        clone.vendorUid = this.vendorUid;
        clone.code = this.code;
        clone.type = this.type;
        clone.costPrice = this.costPrice;
        clone.salesPrice = this.salesPrice;
        clone.salesPriceL2 = this.salesPriceL2;
        clone.recommendedRetailPrice = this.recommendedRetailPrice;
        clone.name = this.name;

        return clone;
    }
}
