import { Service } from '../Service';
import { ResultInterface } from './ResultInterface';

/**
 * Result of loading Services from the API with methods to prepare the Economy Options
 */
export class Result<T extends Service> implements ResultInterface<T> {
    constructor(private _services: T[]) {}

    get length(): number {
        return this._services.length;
    }

    get allServices(): T[] {
        return this._services;
    }

    /**
     * Return the Service with the given UID if found
     *
     * @param {string | number} serviceUid
     * @return {Service | undefined}
     */
    public getService(serviceUid: string | number): T | undefined {
        return this._services.find(s => '' + s.uid === '' + serviceUid);
    }
}
