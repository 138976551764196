import * as React from 'react';
import { Link } from 'react-router-dom';
import { User as UserModel } from '../../Model/User';

interface UserItemProps {
    user: UserModel,
    action: string
}

export class UserItemView extends React.Component<UserItemProps> {
    public render() {
        const props = this.props;
        const user = props.user;
        const to = `${process.env.PUBLIC_URL}/login/${props.action}/${user.username}`;

        return (
            <Link to={to} className="user">
                <div className="user-inner">
                    <div className="user-image">
                        {user.image ? <img src={user.image} alt={user.firstName}/> : null}
                    </div>
                    <div className="user-name">
                        {user.firstName}&nbsp;
                        {user.lastName}
                    </div>
                </div>
            </Link>
        );
    }
}
