import { AdapterConfiguration, RestAdapter } from '@cundd/rest-adapter';
import { ServiceLocator } from '../ServiceLocator';
import { Credentials } from '../ValueObject/Credentials';
import { AuthenticationService } from './AuthenticationService';

export function authorizationHeaderFromCredentials(credentials: Credentials): string {
    return 'Basic ' + btoa(unescape(encodeURIComponent(credentials.username + ':' + credentials.password)));
}

export class RestAdapterFactory {

    private readonly serviceLocator: ServiceLocator;
    private readonly authenticationService: AuthenticationService;

    constructor(serviceLocator: ServiceLocator, authenticationService: AuthenticationService) {
        this.authenticationService = authenticationService;
        this.serviceLocator = serviceLocator;
    }

    public get(): RestAdapter {
        const adapterConfiguration = this.buildAdapterConfiguration();

        return new RestAdapter(adapterConfiguration);
    }

    public buildAdapterConfiguration(): AdapterConfiguration {
        const controller: AbortController = this.serviceLocator.get('abortController');

        const signal = controller.signal;
        const requestSettings: RequestInit = {
            // credentials: 'include',
            // mode: 'no-cors',
            signal,
            // headers: {'X-Requested-With': 'XMLHttpRequest'}
        };

        if (!this.authenticationService.isAuthenticated) {
            throw new Error('Not authenticated');
        }
        const credentials = this.authenticationService.credentials;
        if (credentials) {
            if (!requestSettings.headers) {
                requestSettings.headers = {};
            }
            requestSettings.headers['Authorization'] = authorizationHeaderFromCredentials(credentials);
        }

        const adapterConfiguration = AdapterConfiguration.fromUrl(
            `${process.env.REACT_APP_API_URL}`,
            requestSettings
        );
        adapterConfiguration.fetchCallback = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
            return fetch(input, init);
        };
        return adapterConfiguration;
    }
}
