import { Clone } from 'iresults-ts-core';
import { PropertyTypeOptions, ra_property } from '@cundd/rest-adapter';
import { shortDescriptionForSide, SideEnum } from '../../Components/Offer/SideEnum';
import { BaseModel } from '../BaseModel';
import { Entry } from './Favourite/Entry';
import { favouriteDataFromEye } from './Favourite/EyeConfiguration';
import { Group } from './Favourite/Group';
import { FlatVariantInterface } from './FlatVariantInterface';
import { Variant } from './Variant';

const assertEntryCollection = (collection: (Entry)[]) => {
    collection.forEach(m => {
        // noinspection SuspiciousTypeOfGuard
        if (!(m instanceof Entry)) {
            throw new TypeError('Collection must contain only `Favourite Entry` objects');
        }
    });
};

export class Favourite extends BaseModel implements FlatVariantInterface<Entry, Entry> {
    /**
     * Build a Favourite with the data from the given Variant
     *
     * @param {string | number} uid
     * @param {Group} group
     * @param {Variant} variant
     * @return {Favourite}
     */
    public static fromVariant(uid: string | number, group: Group, variant: Variant): Favourite {
        const rightConfiguration = favouriteDataFromEye(variant.right);
        const leftConfiguration = favouriteDataFromEye(variant.left);

        return new Favourite(
            '' + uid,
            group,
            variant.symmetrical,
            rightConfiguration.lenses,
            rightConfiguration.coating,
            rightConfiguration.miscellaneous,
            leftConfiguration.lenses,
            leftConfiguration.coating,
            leftConfiguration.miscellaneous,
        );
    }

    public static fromValues(
        uid: string | number,
        group: Group,
        symmetrical: boolean,
        rightLenses: string | number | Entry | undefined,
        rightCoating: string | number | Entry | undefined,
        rightMiscellaneous: (string | number | Entry)[],
        leftLenses: string | number | Entry | undefined,
        leftCoating: string | number | Entry | undefined,
        leftMiscellaneous: (string | number | Entry)[],
    ) {
        const toEntry = (i: string | number | Entry | undefined) => {
            if (typeof i === 'undefined') {
                return undefined;
            } else {
                return i instanceof Entry ? i : new Entry(i, '' + i, '' + i);
            }
        };

        return new Favourite(
            '' + uid,
            group,
            symmetrical,
            toEntry(rightLenses),
            toEntry(rightCoating),
            rightMiscellaneous.map(toEntry).filter(i => !!i) as Entry[],
            toEntry(leftLenses),
            toEntry(leftCoating),
            leftMiscellaneous.map(toEntry).filter(i => !!i) as Entry[],
        );
    }

    @ra_property()
    public symmetrical: boolean;

    @ra_property(Entry)
    public rightCoating: Entry | undefined;

    @ra_property(Entry)
    public rightLenses: Entry | undefined;

    @ra_property(Entry, PropertyTypeOptions.Multiple, 'rightMiscellaneous')
    public rightMiscellaneous: Entry[];

    @ra_property(Entry)
    public leftCoating: Entry | undefined;

    @ra_property(Entry)
    public leftLenses: Entry | undefined;

    @ra_property(Entry, PropertyTypeOptions.Multiple, 'leftMiscellaneous')
    public leftMiscellaneous: Entry[];

    @ra_property()
    public group: Group;

    constructor(
        uid: string | number,
        group: Group,
        symmetrical: boolean,
        rightLenses: Entry | undefined,
        rightCoating: Entry | undefined,
        rightMiscellaneous: (Entry)[],
        leftLenses: Entry | undefined,
        leftCoating: Entry | undefined,
        leftMiscellaneous: (Entry)[],
    ) {
        super();

        assertEntryCollection(rightMiscellaneous);
        assertEntryCollection(leftMiscellaneous);

        this.uid = '' + uid;
        this.symmetrical = symmetrical;
        this.rightCoating = rightCoating;
        this.rightLenses = rightLenses;
        this.rightMiscellaneous = rightMiscellaneous;
        this.leftCoating = leftCoating;
        this.leftLenses = leftLenses;
        this.leftMiscellaneous = leftMiscellaneous;
        this.group = group;
    }

    get description(): string {
        const right = this.rightLenses;
        const left = this.leftLenses;

        if (right && left) {
            return ''
                + shortDescriptionForSide(SideEnum.Right) + ' ' + right.description
                + ' '
                + shortDescriptionForSide(SideEnum.Left) + ' ' + left.description;
        } else if (right) {
            return right.description;
        } else if (left) {
            return left.description;
        } else {
            return '';
        }
    }

    public clone<T extends Clone = this>(): this | T {
        return new Favourite(
            this.uid,
            this.group,
            this.symmetrical,
            this.rightLenses,
            this.rightCoating,
            this.rightMiscellaneous,
            this.leftLenses,
            this.leftCoating,
            this.leftMiscellaneous,
        );
    }
}
