import * as React from 'react';
import { Fragment } from 'react';
import { match } from 'react-router-dom';
import { ApiError } from '@cundd/rest-adapter';
import { AppContext, AppContextType } from '../../AppContextType';
import { MainHandler } from '../../MainHandler';
import { User, User as UserModel } from '../../Model/User';
import { AppHeader } from '../AppHeader';
import { ErrorView } from '../ErrorView';

interface UserItemProps {
    user: UserModel,
    action: string,
    match: match<{ username: string }>
}

export class ShowView extends React.Component<UserItemProps, { user?: User, error?: ApiError }> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: UserItemProps, context: MainHandler) {
        super(props, context);
        this.state = {};
    }

    public render() {
        const user = this.state.user;
        let inner:Element|any|null = null;
        if (user) {
            inner = this.renderUser(user);
        } else if (this.state.error) {
            inner = <ErrorView error={this.state.error}/>;
        } else {
            inner = <div className="loading">Benutzerdaten werden geladen</div>;
        }

        return (
            <Fragment>
                <AppHeader/>
                <main className="login-show">
                    <div className="login">{inner}</div>
                </main>
            </Fragment>
        );
    }

    public componentDidMount() {
        const props = this.props;
        const promise = this.context.handler.loginUser(props.match.params.username);

        promise
            .then(user => {
                this.setState({user});
            })
            .catch(error => {
                this.setState({error});
            });
    }

    protected renderUser(user: User) {
        const fullName = user.firstName + ' ' + user.lastName;
        return (
            <div className="user-inner">
                <div className="user-image">
                    {user.image ? <img src={user.image} aria-hidden={true} alt={fullName}/> : null}
                </div>
                <div className="user-name">
                    {fullName}
                </div>
            </div>
        );
    }
}
