import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { EconomyOption } from '../../Model/Catalog/EconomyOption';
import { Provider } from '../../Model/Catalog/EconomyOption/Provider';
import { Result } from '../../Model/Catalog/EconomyOption/Result';
import { Frame } from '../../Model/Catalog/Frame';
import { ServiceFeeOption } from '../../Model/Catalog/ServiceFeeOption';
import { Offer } from '../../Model/Offer/Offer';
import { FramePriceCalculator } from '../../Service/FramePriceCalculator';
import { Currency } from '../Formatter/Currency';
import { CheckboxRow } from './EconomyOption/CheckboxRow';
import { EconomyOptionProps } from './EconomyOption/EconomyOptionProps';
import { Footer } from './EconomyOption/Footer';
import { ServiceFeeRow } from './EconomyOption/ServiceFeeRow';

interface EconomyOptionViewProps extends EconomyOptionProps {
    onSaveClick: (event: SyntheticEvent) => void,
}

export class EconomyOptionView extends React.Component<EconomyOptionViewProps, { optionsResult: Result }> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: EconomyOptionViewProps, context: any) {
        super(props, context);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleServiceFeeChange = this.handleServiceFeeChange.bind(this);

        this.state = {
            optionsResult: new Result([])
        };
    }

    public render() {
        const offer = this.context.handler.offer;
        const frame = offer.frame;
        if (!frame) {
            return null;
        }

        const calc = new FramePriceCalculator();
        const total = calc.calculateFramePriceForOffer(offer);
        const result = this.state.optionsResult;

        return (
            <div className="economy-option-price-list offset-1 grid-10">
                <h1>Service-Preisliste „economy“</h1>

                <section className="economy-option-section economy-option-frame">
                    <div className="row-container">
                        <h3 className="grid-10">Brillenfassung economy</h3>
                        <div className="grid-2 price"><Currency value={frame.priceEconomy} trailingZeros={true}/></div>
                    </div>
                </section>
                <section className="economy-option-section economy-option-service">
                    <h3>Dienstleistungskosten nach Zeitaufwand</h3>

                    <ServiceFeeRow option={offer.serviceFeeOption}
                                   frame={frame}
                                   onChange={this.handleServiceFeeChange}/>
                    {this.renderServiceOptions(result, offer, frame)}
                </section>
                <section className="economy-option-section economy-option-extra-material">
                    <h3>Materialkosten pro Paar</h3>

                    {this.renderExtraMaterialOptions(result, offer, frame)}
                </section>
                <section className="economy-option-section economy-option-total">
                    <div className="row-container">
                        <h3 className="grid-10">Total Fassung economy</h3>
                        <div className="grid-2 price"><Currency value={total} trailingZeros={true}/></div>
                    </div>
                </section>

                <Footer onSaveClick={this.props.onSaveClick}/>
            </div>
        );
    }

    public componentDidMount(): void {
        const economyOptionProvider: Provider = this.context.serviceLocator.get('economyOptionProvider');
        economyOptionProvider.retrieveOptions().then(optionsResult => this.setState({optionsResult}));
    }

    private renderExtraMaterialOptions(result: Result, offer: Offer, frame: Frame) {
        // If no results are loaded yet
        if (result.length === 0) {
            return null;
        }
        return result.getExtraMaterialOptions().map(
            option => <CheckboxRow key={option.uid}
                                   option={option}
                                   onChange={this.handleOptionChange}
                                   checked={offer.hasEconomyOption(option)}
                                   frame={frame}/>
        );
    }

    private renderServiceOptions(result: Result, offer: Offer, frame: Frame) {
        // If no results are loaded yet
        if (result.length === 0) {
            return null;
        }
        return result.getServiceOptions().map(
            option => <CheckboxRow key={option.uid}
                                   option={option}
                                   onChange={this.handleOptionChange}
                                   checked={offer.hasEconomyOption(option)}
                                   frame={frame}/>
        );
    }

    private handleOptionChange(option: EconomyOption, event: any) {
        const handler = this.context.handler;
        const offer = handler.offer;
        const options = offer.economyOptions;
        const setOption = event.target.checked;
        const offerHasEconomyOptionSet = offer.hasEconomyOption(option);
        if (setOption === false && offerHasEconomyOptionSet) {
            // Remove
            options.splice(options.findIndex(currentOption => currentOption.equals(option)), 1);
        } else if (setOption === true && !offerHasEconomyOptionSet) {
            // Add
            options.push(option);
        } else {
            /* nothing to do */
        }

        handler.setOffer(offer.withEconomyOptions(options));
    }

    private handleServiceFeeChange(serviceFeeOption: ServiceFeeOption, timeRequired: number, _event: SyntheticEvent) {
        const handler = this.context.handler;
        const newServiceFeeOption = serviceFeeOption.withTimeRequired(timeRequired);
        handler.setOffer(handler.offer.withServiceFeeOption(newServiceFeeOption));
    }
}
