import * as React from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Frame } from '../../../Model/Catalog/Frame';
import { Offer } from '../../../Model/Offer/Offer';
import { label, PriceModel } from '../../../Model/Offer/PriceModel';
import { Currency } from '../../Formatter/Currency';
import { PriceModelBoxStatus } from './PriceModelBoxStatus';

export interface PriceModelBoxProps {
    frame: Frame;
    status: PriceModelBoxStatus,
}

export interface PriceModelBoxState {
    showPopup: boolean;
}

export abstract class AbstractPriceModelBox extends React.Component<PriceModelBoxProps, PriceModelBoxState> {
    public static contextType = AppContextType;
    public context: AppContext;

    protected constructor(props: PriceModelBoxProps, context: any) {
        super(props, context);

        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleOptionsClick = this.handleOptionsClick.bind(this);
        this.handleOptionsDismiss = this.handleOptionsDismiss.bind(this);
        this.state = {
            showPopup: false
        };
    }

    protected get offer(): Offer {
        return this.context.handler.offer;
    }

    public render() {
        const props = this.props;
        const frame = props.frame;

        const activePriceModel = this.context.handler.offer.priceModel;
        const isActive = this.isActive();
        const className = ['flex-container', 'frame-price-box', 'frame-price-box-' + this.priceModel];
        if (isActive) {
            className.push('-active');
        } else if (activePriceModel) {
            className.push('-inactive');
        } else {
            className.push('-non-active');
        }

        return (
            <div className={className.join(' ')}>
                <div className="frame-price-box-button grid-6">
                    {this.renderButton()}
                </div>
                {this.renderPriceSection()}
                {this.renderPopup(frame, this.state)}
                {this.renderContentBelow()}
            </div>
        );
    }

    public isActive(): boolean {
        const activePriceModel = '' + this.context.handler.offer.priceModel;

        return this.priceModel === activePriceModel;
    }

    public isMinified(): boolean {
        return this.props.status === PriceModelBoxStatus.minified;
    }

    protected abstract renderPopup(frame: Frame, state: PriceModelBoxState): JSX.Element | null;

    protected abstract get priceModel(): PriceModel;

    protected renderPriceSection(): JSX.Element | null {
        const props = this.props;
        const frame = props.frame;
        const price = this.getPrice(frame);

        return (
            <div className="frame-price-box-price -single-row grid-4">
                <div className="price-row base">
                    <span className="currency">CHF </span>
                    <span className="price total"><Currency value={price}/></span>
                </div>
            </div>
        );
    }

    protected renderButton(): JSX.Element {
        // Render the simple block Button
        const buttonGroup = 'button-group -single' + (this.isMinified() ? ' -minified' : '');

        if (this.isMinified()) {
            return (
                <div className={buttonGroup}>
                    <button className="select-button"
                            onClick={this.handleSelectClick}>{label(this.priceModel).charAt(0)}</button>
                </div>
            );
        } else {
            return (
                <div className={buttonGroup}>
                    <button className="select-button"
                            onClick={this.handleSelectClick}>{label(this.priceModel)}</button>
                </div>
            );
        }
    }

    protected renderContentBelow(): JSX.Element | null {
        return null;
    }

    protected getPrice(frame: Frame): number {
        if (this.priceModel === PriceModel.lifetime) {
            return frame.priceLifetime;
        } else {
            return frame.priceEconomy;
        }
    }

    protected handleSelectClick() {
        this.context.handler.handlePriceModelChange(this.priceModel);
    }

    protected handleOptionsClick() {
        console.debug('[PriceModelBox] Show Economy Options popup');
        this.setState({showPopup: true});
    }

    protected handleOptionsDismiss() {
        console.debug('[PriceModelBox] Close Economy Options popup');
        this.setState({showPopup: false});
    }
}
