import { Repository } from '@cundd/rest-adapter';
import { ServiceLocator } from '../../ServiceLocator';
import { Service } from '../Service';
import { Result } from './Result';
import { ResultInterface } from './ResultInterface';

/**
 * Provider for Service models
 *
 * This is **not** a general Service Provider, but a helper to fetch Service instances from the API
 */
export abstract class AbstractServiceProvider<T extends Service = Service,
    R extends ResultInterface<T> = Result<T>> {
    protected services: T[];
    private loadPromise: Promise<T[]>;

    constructor(private readonly serviceLocator: ServiceLocator) {
        this.services = [];
    }

    /**
     * @return {Promise<Service[]>}
     */
    public retrieveOptions(): Promise<R> {
        const promise = this.getOptionsPromise();

        return promise.then(() => {
            return new Promise<R>(resolve => {
                setTimeout(() => resolve(this.getResult()), 10);
            });
        });
    }

    protected abstract getSearchTerm(): string;

    /**
     * Return the Result implementation for the concrete provider
     * @return {R}
     */
    protected getResult(): R {
        return new Result(this.services) as ResultInterface<T> as R;
    }

    protected getSubPath(searchTerm: string): string {
        return `find/?q=${searchTerm}&type=contains&limit=25&group=1728`;
    }

    private getOptionsPromise(): Promise<Service[]> {
        if (this.services.length > 0) {
            return new Promise<Service[]>(resolve => {
                setTimeout(() => resolve(this.services), 10);
            });
        } else if (this.loadPromise) {
            return this.loadPromise;
        } else {
            return this.loadOptions();
        }
    }

    private loadOptions(): Promise<T[]> {
        const serviceRepository: Repository<T> = this.serviceLocator.get('serviceRepository');
        const searchTerm = this.getSearchTerm();

        return this.loadPromise = serviceRepository
            .execute(this.getSubPath(searchTerm))
            .then((services: T[] | Map<string, T> | T | null) => {
                /* If the services are not already set */
                if (this.services.length === 0) {
                    this.services = services as T[];
                }

                return services as T[];
            });
    }
}
