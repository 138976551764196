import * as React from 'react';
import { Offer } from '../../../../Model/Offer/Offer';
import { Variant } from '../../../../Model/Offer/Variant';
import { Currency } from '../../../Formatter/Currency';
import { VariantViewProps } from '../VariantViewProps';

interface ViewProps extends VariantViewProps {
    offer: Offer
}

export class ReworkingRow extends React.Component<ViewProps> {
    public render() {
        const props = this.props;
        const offer = props.offer;
        const variant = props.variant;
        const double = this.getDoublePrefix(variant);

        if (offer.reworking) {
            return <tr>
                <td className="caption">Atelier / Einschleifarbeiten</td>
                <td className="value">
                    {double}
                    <Currency value={offer.reworking.price} trailingZeros={true}/>
                </td>
            </tr>;
        } else {
            return null;
        }
    }

    private getDoublePrefix(variant: Variant): string {
        if (variant.symmetrical) {
            return '2 x ';
        } else if (variant.leftLenses && variant.rightLenses) {
            return '2 x ';
        } else {
            return '';
        }
    }
}
