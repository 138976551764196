import { ra_property } from '@cundd/rest-adapter';
import { AbstractCatalogModel } from '../AbstractCatalogModel';

export abstract class AbstractLenses extends AbstractCatalogModel {
    @ra_property()
    public groupUid: string;

    @ra_property()
    public colorName: string;

    @ra_property()
    public vendorUid: string;

    @ra_property()
    public additionMaterial: string;

    @ra_property()
    public inhousePriceId?: string;

    @ra_property()
    public nameInternal?: string;

    // @ra_property()
    // public name: string;
    //
    // @ra_property()
    // public price: number;

    /**
     * Inhouse Products have an associated diameter. External Products do have multiple diameters defined in associated
     * Lenses
     */
    @ra_property()
    public diameter?: string;
}
