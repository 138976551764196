import * as React from 'react';
import { Favourite } from '../../../../Model/Offer/Favourite';
import { LensesAndCoatingRow } from './LensesAndCoatingRow';
import { MiscellaneousRow } from './MiscellaneousRow';

export interface TableProps {
    favourite: Favourite
}

export class Table extends React.Component<TableProps> {
    public render() {
        const favourite = this.props.favourite;

        return <table className="favourite-box-table">
            <tbody>
            <LensesAndCoatingRow favourite={favourite}/>
            <MiscellaneousRow favourite={favourite} showEmpty={true}/>
            </tbody>
        </table>;
    }
}
