import * as React from 'react';
import { SyntheticEvent } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Offer } from '../../../Model/Offer/Offer';
import { Variant } from '../../../Model/Offer/Variant';
import { TotalPriceCalculator } from '../../../Service/TotalPriceCalculator';
import { VariantPriceCalculator } from '../../../Service/VariantPriceCalculator';
import { Currency } from '../../Formatter/Currency';
import { DiscountRow } from './Box/DiscountRow';
import { LensesAndCoatingRow } from './Box/LensesAndCoatingRow';
import { LensesDeterminationRow } from './Box/LensesDeterminationRow';
import { MaterialFeeRow } from './Box/MaterialFeeRow';
import { MiscellaneousRow } from './Box/MiscellaneousRow';
import { ReworkingRow } from './Box/ReworkingRow';

type ToolButtonHandler = (variant: Variant, event: SyntheticEvent) => void;

interface BoxProps {
    variant: Variant
    index: number
    offer: Offer
    isSelected: boolean
    onClickEdit: ToolButtonHandler
    onClickRemove: ToolButtonHandler
    onToggleSelection: ToolButtonHandler
}

type SortTextHandleProps = { variant: Variant, index: number };
const SortTextHandle = SortableHandle<SortTextHandleProps>(
    (props: SortTextHandleProps) => <h3 className="sort-handler">Variante {props.index + 1}</h3>
);

export class Box extends React.Component<BoxProps> {
    constructor(props: BoxProps, context: any) {
        super(props, context);
        this.handleClickEdit = this.handleClickEdit.bind(this);
        this.handleClickRemove = this.handleClickRemove.bind(this);
        this.handleToggleSelection = this.handleToggleSelection.bind(this);
    }

    public render() {
        const props = this.props;
        const index = props.index;
        const variant = props.variant;
        const offer = this.props.offer;
        const variantPriceCalculator = new VariantPriceCalculator();
        const totalPriceCalculator = new TotalPriceCalculator();

        const grandTotal = totalPriceCalculator.calculatePriceForVariantAndOffer(variant, props.offer);
        const lensesTotal = variantPriceCalculator.calculateLensesPrice(variant);
        const grandTotalLabel = this.offerHasFrame() ? 'Total inkl. Fassung' : 'Total';

        return (
            <>
                <div className="variant-box">
                    <header>
                        {this.renderSelector()}

                        <SortTextHandle variant={variant} index={index}/>

                        <button onClick={this.handleClickRemove} className="icon-button variant-remove">
                            <i className="material-icons">delete</i>
                        </button>
                        <button onClick={this.handleClickEdit} className="icon-button variant-edit">
                            <i className="material-icons">edit</i>
                        </button>
                    </header>

                    <table className="variant-box-table">
                        <tbody>
                        <LensesAndCoatingRow variant={variant}/>
                        <MiscellaneousRow variant={variant} showEmpty={false}/>


                        <tr className="header">
                            <th className="caption">Total Gläser</th>
                            <td className="value"><Currency value={lensesTotal} trailingZeros={true}/></td>
                        </tr>

                        <ReworkingRow variant={variant} offer={offer}/>
                        <LensesDeterminationRow variant={variant} offer={offer}/>
                        <MaterialFeeRow offer={offer}/>
                        <DiscountRow variant={variant}/>

                        </tbody>
                        <tfoot>
                        <tr>
                            <td className="caption">{grandTotalLabel}</td>
                            <td className="value"><Currency value={grandTotal} trailingZeros={true}/></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </>
        );
    }

    private offerHasFrame(): boolean {
        const offer = this.props.offer;
        const frame = offer.frame;

        return !!frame && !frame.isCustomerFrame && !!offer.priceModel;
    }

    private handleClickEdit(event: SyntheticEvent) {
        console.log(`[${this.constructor.name}] Handle click edit`, event);
        const props = this.props;
        props.onClickEdit(props.variant, event);
    }

    private handleClickRemove(event: SyntheticEvent) {
        console.log(`[${this.constructor.name}] Handle click remove`, event);
        const props = this.props;
        props.onClickRemove(props.variant, event);
    }

    private handleToggleSelection(event: SyntheticEvent) {
        console.log(`[${this.constructor.name}] Handle toggle selection`, event);
        const props = this.props;
        props.onToggleSelection(props.variant, event);
    }

    private renderSelector() {
        const className = 'discreet variant-select select'
            + (this.props.isSelected || this.props.variant.isMain ? ' -selected' : '');

        return <span onClick={this.handleToggleSelection} className={className}/>;
    }
}
