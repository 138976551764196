import * as React from 'react';
import { Link } from 'react-router-dom';
import { SortEnd, SortEvent } from 'react-sortable-hoc';
import { AppContext, AppContextType } from '../../AppContextType';
import { SortableContainer } from '../../DragNDrop/SortableContainer';
import { SortableHelper } from '../../DragNDrop/SortableHelper';
import { Variant } from '../../Model/Offer/Variant';
import { CollectionService } from '../../Service/CollectionService';
import { VariantBuilder } from '../../Service/VariantBuilder';
import { Popup } from '../Popup';
import { Box } from './Variant/Box';
import { EditVariant } from './Variant/EditVariant';
import { NewVariant } from './Variant/NewVariant';

interface State {
    showPopup: boolean,
    newVariant?: Variant
    editingVariant?: Variant
    selected: Variant[]
}

export class VariantContainer extends React.Component<{}, State> {
    public static contextType = AppContextType;
    public context!: AppContext;
    private variantBuilder: VariantBuilder;

    constructor(props: {}, context: AppContext) {
        super(props, context);
        this.handleClickAdd = this.handleClickAdd.bind(this);
        this.handleDismissPopup = this.handleDismissPopup.bind(this);
        this.handleClickEdit = this.handleClickEdit.bind(this);
        this.handleClickRemove = this.handleClickRemove.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.handleToggleSelection = this.handleToggleSelection.bind(this);

        this.variantBuilder = context.serviceLocator.get('variantBuilder');
        this.state = {
            showPopup: false,
            selected: []
        };
    }

    public render() {
        const offer = this.context.offer;
        const empty = offer.variants.length === 0;
        const className = 'offer-variant-container ' + (empty ? '-empty' : '-not-empty');

        return (
            <div className={className}>
                {this.renderButtons()}
                {empty ? this.renderEmpty() : this.renderVariants()}
                {this.renderPopup()}
            </div>
        );
    }

    protected renderPopup(): JSX.Element | null {
        const newVariant = this.state.newVariant;
        const editingVariant = this.state.editingVariant;
        const offer = this.context.offer;
        const customer = offer.customer;
        const refractionPair = offer.refractionPair;
        if (!customer) {
            if (newVariant || editingVariant) {
                console.error(`[${this.constructor.name}] Can not add/edit Variants without a customer`);
            }

            return null;
        }
        if (newVariant) {
            const variantNumber = 1 + offer.variants.length;

            return (
                <Popup onDismiss={this.handleDismissPopup}>
                    <NewVariant
                        refractionPair={refractionPair}
                        variant={newVariant}
                        variantNumber={variantNumber}
                        onClickSave={this.handleDismissPopup}/>
                </Popup>
            );
        }
        if (editingVariant) {
            const variantNumber = 1 + offer.variants.findIndex((variant) => variant === editingVariant);

            return (
                <Popup onDismiss={this.handleDismissPopup}>
                    <EditVariant
                        refractionPair={refractionPair}
                        variant={editingVariant}
                        variantNumber={variantNumber}
                        onClickSave={this.handleDismissPopup}/>
                </Popup>
            );
        } else {
            return null;
        }
    }

    private renderEmpty() {
        const disabled = !this.hasCustomer();
        const className = 'discreet offer-variant-add-big' + (disabled ? ' -disabled' : '');

        return (
            <div className="offer-variant-container-inner">
                <button className={className} disabled={disabled} onClick={this.handleClickAdd}>
                    <span className="offer-variant-add-inner">
                        <i className="material-icons">add</i>
                        <span className="text">Neue Variante<br/>erstellen</span>
                    </span>
                </button>
            </div>
        );
    }

    private renderVariants() {
        const context = this.context;
        const offer = context.offer;
        const selectedVariantIdentifiers = context.handler.selectedVariantIdentifiers;

        return <SortableContainer className="offer-variant-container-inner"
                                  childClassName="offer-variant-container-item variant-box-container grid-4"
                                  onSortEnd={this.handleSortEnd}
                                  useDragHandle={true}
        >
            {offer.variants.map(
                (variant, index) => <Box key={variant.guid}
                                         index={index}
                                         variant={variant}
                                         offer={offer}
                                         isSelected={CollectionService.isContainedIn(variant, selectedVariantIdentifiers)}
                                         onToggleSelection={this.handleToggleSelection}
                                         onClickEdit={this.handleClickEdit}
                                         onClickRemove={this.handleClickRemove}/>
            )}
        </SortableContainer>;
    }

    private handleSortEnd(sortingUpdate: SortEnd, _event: SortEvent) {
        const offer = this.context.offer;
        const variants = SortableHelper.reorderElements(offer.variants, sortingUpdate);

        this.context.handler.setOffer(offer.withVariants(variants));
    }

    private renderButtons() {
        const to = `${this.context.config.basePath}/offer/favourite/show`;

        if (!this.hasCustomer()) {
            return null;
        } else {
            return <div className="offer-variant-container-toolbar">
                <Link to={to} className="discreet offer-favourite-show">
                    <span className="offer-favourite-show-inner">
                        <i className="material-icons">star</i>
                        <span className="text">Favoriten</span>
                    </span>
                </Link>
                <button className="discreet offer-variant-add-small" onClick={this.handleClickAdd}>
                    <span className="offer-variant-add-inner">
                        <i className="material-icons">add</i>
                        <span className="text">Variante erstellen</span>
                    </span>
                </button>
            </div>;
        }
    }

    private handleDismissPopup() {
        console.debug(`[${this.constructor.name}] Close Variant popup`);
        this.setState(
            {
                showPopup: false,
                editingVariant: undefined,
                newVariant: undefined
            }
        );
    }

    private handleClickAdd() {
        console.debug(`[${this.constructor.name}] Show New Variant popup`);
        const variant = this.variantBuilder.buildNewVariant(this.context.handler.offer);
        if (variant) {
            this.setState(
                {
                    showPopup: true,
                    newVariant: variant,
                    editingVariant: undefined
                }
            );
        }
    }

    private handleClickEdit(variant: Variant) {
        console.debug(`[${this.constructor.name}] Show Edit Variant popup`);
        this.setState(
            {
                showPopup: true,
                newVariant: undefined,
                editingVariant: variant
            }
        );
    }

    private handleClickRemove(variant: Variant) {
        this.context.handler.handleRemoveVariant(variant);
    }

    private handleToggleSelection(variant: Variant) {
        this.context.handler.handleMainVariantChange(variant);
    }

    private hasCustomer(): boolean {
        return !!this.context.offer.customer;
    }
}
