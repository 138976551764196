import { BrowserStorage } from '../BrowserStorage';
import { StorageAdapterInterface } from './StorageAdapterInterface';

/**
 * Async wrapper around the Browser Storage
 */
export class AsyncBrowserStorageAdapter<T> implements StorageAdapterInterface<T> {
    private readonly storage: BrowserStorage;

    constructor() {
        this.storage = new BrowserStorage();
    }

    public getItem(namespace: string, uid: string): Promise<string | null> {
        const storedValue = this.storage.getItem(namespace, uid);

        return Promise.resolve(storedValue ? storedValue.substr(14) : null);
    }

    public setItem<I = T>(namespace: string, uid: string, serialized: string, item: I): Promise<T | null> {
        this.storage.setItem(namespace, uid, this.buildEntry(serialized));

        return Promise.resolve(item as unknown as T);
    }

    protected buildEntry(item: string) {
        const timeTag = this.buildTimeTag();

        return timeTag + ' ' + item;
    }

    protected buildTimeTag(): string {
        return (+new Date()).toFixed(0);
    }
}
