import { ra_property } from '@cundd/rest-adapter';
import { GuidService } from '../Service/GuidService';
import { DomainObject } from './DomainObject';

export abstract class BaseModel implements DomainObject {
    @ra_property()
    public uid: string;

    @ra_property()
    public guid: string;

    constructor() {
        this.postConstruct();
    }

    public postConstruct() {
        this.guid = GuidService.sharedInstance.uuidv4();
    }
}
