import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../../Model/User';
import { Confirm } from '../Modal/Confirm';

export interface ToolbarProps extends RouteComponentProps {
    user: User
}

interface ToolbarState {
    showModal: boolean
}

class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
    constructor(props: ToolbarProps, context: any) {
        super(props, context);
        this.handleClearOfferClick = this.handleClearOfferClick.bind(this);
        this.handleModalCancel = this.handleModalCancel.bind(this);
        this.handleModalConfirm = this.handleModalConfirm.bind(this);

        this.state = {
            showModal: false
        };
    }

    public render() {
        const user = this.props.user;
        const basePath = this.getBasePath();
        const userShow = `${basePath}/login/show/${user.username}`;

        return (
            <div className="toolbar">
                {this.renderConfirm()}
                <Link to={userShow} className="item login-info">
                    <span>{user.firstName} {user.lastName}</span>
                    <i className="material-icons" aria-hidden="true">person_outline</i>
                </Link>
                <Link to={`${basePath}/offer/import/show`} className="item offer-open">
                    <i className="material-icons" aria-hidden="true">folder_open</i>
                </Link>
                <button onClick={this.handleClearOfferClick} className="item offer-new discreet">
                    <i className="material-icons mirror-vertically" aria-hidden="true">refresh</i>
                </button>
            </div>
        );
    }

    private renderConfirm() {
        return <Confirm show={this.state.showModal}
                        message={`Daten löschen und eine neue Offerte anlegen?`}
                        onCancel={this.handleModalCancel}
                        onConfirm={this.handleModalConfirm}
                        cancelClassName="favourite-remove-confirm -confirm-no"
                        confirmClassName="favourite-remove-confirm -confirm-yes -danger"/>;
    }

    private getBasePath() {
        return `${process.env.PUBLIC_URL}`;
    }

    private handleClearOfferClick() {
        this.setState({showModal: true});
    }

    private handleModalCancel() {
        this.setState({showModal: false});
    }

    private handleModalConfirm() {
        this.props.history.push(this.getBasePath() + '/offer/clear');
        this.setState({showModal: false});
    }
}

const ToolbarWithRouter = withRouter(Toolbar);
export {
    ToolbarWithRouter as Toolbar
};
