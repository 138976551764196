import * as React from 'react';
import { Fragment } from 'react';
import { ApiError } from '@cundd/rest-adapter';
import { AppContext, AppContextType } from '../../AppContextType';
import { User } from '../../Model/User';
import { AppHeader } from '../AppHeader';
import { ErrorView } from '../ErrorView';
import { UserItemView } from './UserItemView';

interface LoginState {
    users: User[]
    error?: ApiError;
}

export class Overview extends React.Component<{}, LoginState> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: {}) {
        super(props);

        this.state = {
            users: [],
            error: undefined,
        };
    }

    public render() {
        const users = this.state.users;

        return (
            <Fragment>
                <AppHeader big={true}/>
                <main className="full-width">
                    <div className="login">
                        <ErrorView error={this.state.error} message="Die Userdaten konnten nicht geladen werden"/>
                        {this.renderUsers(users)}
                    </div>
                </main>
            </Fragment>
        );
    }

    public componentDidMount() {
        const promise = this.context.handler.findAllUsers();

        promise
            .then(users => {
                this.setState({users: Array.from(users.values())});
            })
            .catch(error => {
                this.setState({error});
            });
    }

    private renderUsers(users: User[]) {
        if (users.length === 0) {
            return null;
        }

        const usersSorted = users.slice().sort((u1, u2) => {
            const u1sorting = u1.sorting;
            const u2sorting = u2.sorting;
            if (u1sorting < u2sorting) {
                return -1;
            } else if (u1sorting === u2sorting) {
                return 0;
            } else {
                return 1;
            }
        });

        return (
            <ul className="users">
                {usersSorted.map(user => <UserItemView key={user.username} action="login" user={user}/>)}
            </ul>
        );
    }
}
