import { ra_property } from '@cundd/rest-adapter';
import { Price } from '../../Price';
import { PriceModel } from './PriceModel';

export class FramePrice {
    @ra_property(Number)
    public readonly base: number;

    @ra_property(Number)
    public readonly total: number;

    public readonly model: PriceModel;

    constructor(
        base: Price | number,
        total: Price | number,
        model: PriceModel
    ) {
        Price.assertValidPrice(base);
        Price.assertValidPrice(total);
        this.model = model;
        this.total = (total instanceof Price) ? total.valueOf() : total;
        this.base = (base instanceof Price) ? base.valueOf() : base;
    }
}
