export enum Group {
    SingleVision = 'SingleVision',
    Varifocal = 'Varifocal',
    Activity = 'Activity',
    Sunglasses = 'Sunglasses',
    Special = 'Special',
}

export function getGroupName(group: Group): string | undefined {
    const groupString = '' + group;

    switch (groupString) {
        case Group.SingleVision:
            return 'Einstärken';
        case Group.Varifocal:
            return 'Mehrstärken';
        case Group.Activity:
            return 'Activity';
        case Group.Sunglasses:
            return 'Sonnenbrillen';
        case Group.Special:
            return 'Spezielles';
        default:
            return undefined;
    }
}
