import { Offer } from '../Model/Offer/Offer';
import { Variant } from '../Model/Offer/Variant';
import { EyeConfiguration } from '../Model/Offer/Variant/EyeConfiguration';
import { VariantPrice } from '../Model/Offer/VariantPrice';

const preparePrice = (price: number): number => {
    return price || 0.0;
};

export class VariantPriceCalculator {
    /**
     * Build the Price instance for `variant`
     *
     * @param {Variant} variant
     * @param {Offer} offer
     * @return {VariantPrice | undefined}
     */
    public buildPriceForVariant(variant: Variant, offer: Offer): VariantPrice | undefined {
        if (!variant.isValid()) {
            return undefined;
        }
        const basePrice = this.calculateLensesPrice(variant);
        const total = this.calculateTotalPrice(variant, offer);

        return new VariantPrice(basePrice, total);
    }

    /**
     * Calculate the total price for the Lenses incl. coatings and other additions
     *
     * @param {Variant} variant
     * @return {number}
     */
    public calculateLensesPrice(variant: Variant): number {
        if (!variant.isValid()) {
            // throw new Error('Variant is not valid');
            return NaN;
        }

        const rightPrice = variant.right ? this.calculateEyeConfigurationPrice(variant.right) : 0;
        const leftPrice = variant.left ? this.calculateEyeConfigurationPrice(variant.left) : 0;
        if (variant.symmetrical) {
            return 2 * rightPrice;
        } else {
            return rightPrice + leftPrice;
        }
    }

    /**
     * Calculate the total price for the Variant incl. Lenses, Reworking, Determination, coatings and other additions
     *
     * @param {Variant} variant
     * @param {Offer} offer
     * @return {number}
     */
    public calculateTotalPrice(variant: Variant, offer: Offer): number {
        let price = this.calculateLensesPrice(variant);
        if (isNaN(price)) {
            return NaN;
        }

        if (offer.reworking) {
            const reworkingPrice = offer.reworking.price;
            if (variant.symmetrical) {
                price += 2 * reworkingPrice;
            } else if (variant.leftLenses && variant.rightLenses) {
                price += 2 * reworkingPrice;
            } else {
                price += reworkingPrice;
            }
        }
        const lensesDeterminationPrice = offer.lensesDeterminationPrice;
        if (typeof lensesDeterminationPrice !== 'undefined') {
            price += lensesDeterminationPrice;
        }
        const materialFee = offer.materialFee;
        if (typeof materialFee !== 'undefined') {
            price += materialFee;
        }

        const discount = variant.discount;
        if (discount) {
            price -= discount;
        }

        return price;
    }

    private calculateEyeConfigurationPrice(configuration: EyeConfiguration): number {
        let price = 0;
        const lenses = configuration.lenses;
        if (lenses) {
            price += lenses ? preparePrice(lenses.price) : 0;
        }

        if (configuration.coating) {
            price += preparePrice(configuration.coating.price);
        }
        if (configuration.miscellaneous) {
            price += configuration.miscellaneous.reduce(
                (accumulator, misc) => accumulator + preparePrice(misc.price),
                0
            );
        }

        return price;
    }
}
