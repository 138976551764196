import { ClassTypeOptions, PropertyTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { PrismService } from '../Service/PrismService';
import { Serializable } from '../Service/Serializable';
import { ResultantPrism } from '../ValueObject/ResultantPrism';
import { RefractionSide } from './RefractionSide';

export const refractionProperties = {
    'side': '\t',
    'sphericalNegativeNotation': 'Sph',
    'cylindricalNegativeNotation': 'Cyl',
    'axis': 'Axe',
    'add': 'Add',
    'prism1': 'Prism1',
    'base1': 'Base1',
    'prism2': 'Prism2',
    'base2': 'Base2',
};

@ra(ClassTypeOptions.AddUnknownFields)
export class Refraction implements Serializable {
    @ra_property(Number)
    public spherical: number;

    @ra_property(Number)
    public cylindrical?: number;

    @ra_property(Number)
    public axis?: number;

    @ra_property(Number)
    public add?: number;

    @ra_property(Number)
    public prism1?: number;

    @ra_property(String)
    public base1?: string;

    @ra_property(Number)
    public prism2?: number;

    @ra_property(String)
    public base2?: string;

    @ra_property(Number)
    public vcc?: number;

    @ra_property(Number)
    public hsa?: number;

    @ra_property(Number)
    public nab?: number;

    @ra_property(String, 'side')
    private readonly _side: RefractionSide;

    @ra_property(PropertyTypeOptions.NoSerialization)
    private _resultantPrismCache ?: ResultantPrism;

    constructor(
        side: RefractionSide,
        spherical: number,
        cylindrical?: number,
        axis?: number,
        add?: number,
        prism1?: number,
        base1?: string,
        prism2?: number,
        base2?: string,
        vcc?: number,
        hsa?: number,
        nab?: number
    ) {
        this._side = this.normalizeSide(side);
        this.spherical = spherical;
        this.cylindrical = cylindrical;
        this.axis = axis;
        this.add = add;
        this.prism1 = prism1;
        this.base1 = base1;
        this.prism2 = prism2;
        this.base2 = base2;
        this.vcc = vcc;
        this.hsa = hsa;
        this.nab = nab;
    }

    get side(): RefractionSide {
        return this.normalizeSide(this._side);
    }

    public get sphericalNegativeNotation(): number {
        return this.transformToNegativeNotation(this.spherical, this.cylindrical).spherical;
    }

    public get cylindricalNegativeNotation(): number | undefined {
        return this.transformToNegativeNotation(this.spherical, this.cylindrical).cylindrical;
    }

    public get resultantPrism(): ResultantPrism | undefined {
        if (!this._resultantPrismCache) {
            this._resultantPrismCache = (new PrismService()).calculateResultantPrism(this);
        }

        return this._resultantPrismCache;
    }

    public serialize(): string {
        return [
            this._side,
            this.spherical,
            this.cylindrical,
            this.axis,
            this.add,
            this.prism1,
            this.base1,
            this.prism2,
            this.base2,
            this.vcc,
            this.hsa,
            this.nab,
        ]
            .join('_')
            .replace(/^_+/g, '')
            .replace(/_+$/g, '');
    };

    public toString = (): string => {
        return this.serialize();
    };

    private transformToNegativeNotation(
        spherical: number,
        cylindrical: number | undefined
    ): { spherical: number, cylindrical: number | undefined } {
        if (typeof cylindrical !== 'undefined' && cylindrical > 0) {
            return {spherical: spherical + cylindrical, cylindrical: -cylindrical};
        } else {
            return {spherical, cylindrical};
        }
    }

    private normalizeSide($side: string | RefractionSide): RefractionSide {
        if ($side === RefractionSide.Left || $side === 'left') {
            return RefractionSide.Left;
        }
        if ($side === RefractionSide.Right || $side === 'right') {
            return RefractionSide.Right;
        }
        throw new RangeError('Invalid side given');
    }
}
