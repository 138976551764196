import * as React from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { Offer } from '../../Model/Offer/Offer';
import { Question } from '../Modal/Question';
import { ExportButton } from './ExportButton';
import { PrintButton } from './PrintButton';

interface ExportBarState {
    showModal: boolean
}

export class ExportBar extends React.Component<{}, ExportBarState> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: {}, context: any) {
        super(props, context);

        this.handleExportOffer = this.handleExportOffer.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleExportAsNewOffer = this.handleExportAsNewOffer.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);

        this.state = {showModal: false};
    }

    public render() {
        return (
            <div className="toolbar toolbar-print-export-container">
                {this.renderInner()}
            </div>
        );
    }

    private renderInner() {
        if (!this.hasVariants()) {
            return null;
        }

        return <>
            {this.renderExportButton()}
            <PrintButton onClick={this.handlePrint}/>
            {this.renderQuestion()}
        </>;
    }

    private renderExportButton() {
        const offer = this.getOffer();
        const onExportClick = offer.isImported ? this.handleShowModal : this.handleExportOffer;
        const title = 'Offerte ' + offer.info + ' exportieren';

        return this.isExportEnabled() ? <ExportButton title={title} onClick={onExportClick}/> : null;
    }

    private isExportEnabled(): boolean {
        const offer = this.getOffer();

        return offer
            && offer.variants.length > 0
            && offer.variants.find(v => v.isMain) !== undefined;
    }

    private hasVariants(): boolean {
        const offer = this.getOffer();

        return offer && offer.variants.length > 0;
    }

    private handleExportOffer() {
        const offer = this.getOffer();
        console.debug(`[ExportBar] Export current offer ${offer.info}`);
        this.context.handler.exportOffer(offer);
        this.setState({showModal: false});
    }

    private handleExportAsNewOffer() {
        const handler = this.context.handler;
        const newOffer = Offer.buildNewOffer(this.getOffer());
        console.debug(`[ExportBar] Export next offer ${newOffer.info}`);
        handler.setOffer(newOffer);
        handler.exportOffer(newOffer);
        this.setState({showModal: false});
    }

    private renderQuestion() {
        return <Question show={this.state.showModal}
                         message={`Offerte überschreiben oder als neue Offerte exportieren?`}
                         onAnswer1={this.handleExportAsNewOffer}
                         onAnswer2={this.handleExportOffer}
                         answer1Label="Als neue Offerte"
                         answer2Label="Überschreiben"
                         answer1ClassName="export-confirm -confirm-no"
                         answer2ClassName="export-confirm -confirm-yes -danger"/>;
    }

    private handleShowModal() {
        this.setState({showModal: true});
    }

    private handlePrint() {
        this.context.handler.printOffer(this.getOffer());
    }

    private getOffer(): Offer {
        return this.context.handler.offer;
    }
}
