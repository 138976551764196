import * as React from 'react';
import { ServiceFeeOption } from '../../../../Model/Catalog/ServiceFeeOption';
import { Currency } from '../../../Formatter/Currency';
import { DetailProps } from './DetailProps';

interface ServiceFeeOptionDetailProps extends DetailProps {
    option: ServiceFeeOption,
}

export class ServiceFeeOptionDetail extends React.Component<ServiceFeeOptionDetailProps> {
    public render() {
        const option = this.props.option;
        if (option.timeRequired > 0) {
            return (
                <li>
                    {option.timeRequired}min Fassungsberatung,&nbsp;
                    <Currency showSymbol={true} value={option.price}/>
                </li>
            );
        } else {
            return null;
        }
    }
}
