import * as React from 'react';
import { AppContext, AppContextType } from '../AppContextType';
import { Header } from './Header';
import { ExportBar } from './Toolbar/ExportBar';
import { Toolbar } from './Toolbar/Toolbar';

export class AppHeader extends React.Component<{ big?: boolean }> {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        const handler = this.context.handler;
        const login = handler.login;

        if (login) {
            return (
                <Header {...this.props}>
                    <div className="grid-bottom"><Toolbar user={login}/></div>
                    <div className="grid-bottom"><ExportBar/></div>
                </Header>
            );
        } else {
            return (
                <Header {...this.props}>
                    <div className="grid-4 grid-bottom main-title"><h1>Federer Offerten</h1></div>
                    <div className="grid-4"/>
                </Header>
            );
        }
    }
}

