function is_numeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export class Base {
    public static BASE_OUTSIDE = 'outside';
    public static BASE_DOWN = 'down';
    public static BASE_INSIDE = 'inside';
    public static BASE_UP = 'up';
    private static instances = new Map<string, Base>();
    private readonly _value: string;

    private constructor($value: string) {
        this._value = $value;
    }

    private static instance($value): Base {
        if (Base.instances.has($value)) {
            return Base.instances.get($value) as Base;
        }
        const newBase = new Base($value);

        Base.instances.set($value, newBase);
        return newBase;
    }

    public static outside() {
        return Base.instance(Base.BASE_OUTSIDE);
    }

    public static down() {
        return Base.instance(Base.BASE_DOWN);
    }

    public static inside() {
        return Base.instance(Base.BASE_INSIDE);
    }

    public static up() {
        return Base.instance(Base.BASE_UP);
    }

    /**
     * Return a variant of Base that does not symbolize one of the Constants
     *
     * @param {string} $value
     * @return Base
     */
    public static variant($value: string) {
        return new Base($value);
    }

    public isHorizontal(): boolean {
        const $value = this._value;

        return $value === Base.BASE_OUTSIDE || $value === Base.BASE_INSIDE;
    }

    public isVertical(): boolean {
        const $value = this._value;

        return $value === Base.BASE_UP || $value === Base.BASE_DOWN;
    }

    /**
     * @param {string|Base} $rawBase
     * @return Base
     */
    public static fromRaw($rawBase: string | Base): Base {
        if ($rawBase instanceof Base) {
            return new Base($rawBase._value);
        }
        try {
            return Base.fromKeyword($rawBase);
        } catch ($exception) {
            return Base.variant($rawBase);
        }
    }

    public static fromKeyword($rawBase: string): Base {
        if (is_numeric($rawBase)) {
            throw new TypeError('Base keyword must not be numeric');
        }
        if ('' === $rawBase.trim()) {
            throw new TypeError('Base keyword must not be empty');
        }

        // Check if the raw value matches one of the keywords
        switch ($rawBase) {
            case Base.BASE_UP:
                return Base.up();

            case Base.BASE_DOWN:
                return Base.down();

            case Base.BASE_INSIDE:
                return Base.inside();

            case Base.BASE_OUTSIDE:
                return Base.outside();
        }

        const $firstChar = $rawBase.charAt(0).toUpperCase();
        switch ($firstChar) {
            case 'O':
                return Base.up();

            case 'U':
                return Base.down();

            case 'I' :
            case 'N':
                return Base.inside();

            case 'A' :
            case 'T':
                return Base.outside();
        }

        throw new TypeError(`No base found for keyword "${$rawBase}"`);
    }

    public get value(): string {
        return this._value;
    }
}
