/**
 * Mandatory Economy Option representing the work of the salesperson
 */
import { ra_property } from '@cundd/rest-adapter';
import { CatalogModelInterface } from './CatalogModelInterface';
import { EconomyOption } from './EconomyOption';

export class ServiceFeeOption extends EconomyOption implements CatalogModelInterface {
    public static pricePerMinute: number = 24 / 15;

    /**
     * Return the service fee Economy Options
     *
     * @param {number} timeRequired
     * @return {ServiceFeeOption}
     */
    public static getServiceFeeOption(timeRequired: number = 0): ServiceFeeOption {
        const price = ServiceFeeOption.pricePerMinute * timeRequired;

        return new ServiceFeeOption(
            100000,
            price,
            'Service Pauschale',
            'Minuten (Fassungsberatung, Reparaturen, Anpassung, usw.)',
            timeRequired
        );
    }

    /**
     * The time required for consulting, customization and repair
     */
    @ra_property(Number)
    public readonly timeRequired: number = 0;

    constructor(uid: string | number, price: number, name: string, description: string, timeRequired: number) {
        super(uid, price, name, description);
        this.name = 'Service Pauschale';
        this.timeRequired = timeRequired;
    }

    public withTimeRequired(timeRequired: number): ServiceFeeOption {
        return ServiceFeeOption.getServiceFeeOption(timeRequired);
    }
}

