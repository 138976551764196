import { Customer } from '../Customer/Customer';
import { RefractionPair } from '../Customer/RefractionPair';

export class CustomerRefractionError extends Error {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, CustomerRefractionError.prototype);
    }
}

/**
 * Check if the Refraction Pair belongs to the Customer
 *
 * @param {Customer} customer
 * @param {RefractionPair} refractionPair
 * @param {boolean} throwOnError
 * @return {boolean}
 * @throws {CustomerRefractionError} if the assertion fails and `throwOnError` is TRUE
 */
export function assertCustomerHasRefractionPair(
    customer: Customer,
    refractionPair: RefractionPair,
    throwOnError: boolean = true
): boolean {
    if (refractionPair.customerUid === customer.uid) {
        return true;
    }

    const message = 'Given Refraction Pair does not belong to given Customer';
    if (throwOnError) {
        throw new CustomerRefractionError(message);
    }

    console.error(message);

    return false;
}
