import { Repository } from '@cundd/rest-adapter';
import { MainHandler } from './MainHandler';
import { Provider } from './Model/Catalog/EconomyOption/Provider';
import { Frame } from './Model/Catalog/Frame';
import { Addition } from './Model/Catalog/Lenses/Addition';
import { Lenses } from './Model/Catalog/Lenses/Lenses';
import { Customer } from './Model/Customer/Customer';
import { DomainObject } from './Model/DomainObject';
import { Favourite } from './Model/Offer/Favourite';
import { Offer } from './Model/Offer/Offer';
import { PriceModel } from './Model/Offer/PriceModel';
import { Variant } from './Model/Offer/Variant';
import { EyeConfiguration } from './Model/Offer/Variant/EyeConfiguration';
import { Refraction } from './Model/Refraction';
import { Settings } from './Model/Settings/Settings';
import { FavouriteServiceInterface } from './Service/FavouriteServiceInterface';
import { RepositoryEnhancement } from './Service/RepositoryEnhancement';
import { ServiceLocator } from './ServiceLocator';

export class MainHandlerHelper {
    constructor(private handler: MainHandler, private serviceLocator: ServiceLocator) {
    }

    public loginUser(username: string) {
        return this.handler.loginUser(username);
    }

    public selectCustomer(uid: string | number) {
        const customerRepository: Repository<Customer> = this.serviceLocator.get('customerRepository');

        return customerRepository.findByIdentifier(uid).then((cu) => {
            if (cu) {
                this.handler.handleCustomerChange(cu);
            }
        });
    }

    public selectFrame(uid: string | number) {
        const frameRepository: Repository<Frame> = this.serviceLocator.get('frameRepository');

        return frameRepository.findByIdentifier(uid).then((cu) => {
            if (cu) {
                this.handler.handleFrameChange(cu);
            }
        });
    }

    public selectPriceModel(priceModel: PriceModel) {
        this.handler.handlePriceModelChange(priceModel);
    }

    public addEconomyOption(uid: number) {
        const economyOptionProvider: Provider = this.serviceLocator.get('economyOptionProvider');
        economyOptionProvider.retrieveOptions().then(r => {
            const handler = this.handler;
            const options = handler.offer.economyOptions;
            const economyOption = r.getEconomyOption(uid);

            console.log(economyOption);

            if (economyOption) {
                options.push(economyOption);
                const offer = handler.offer.withEconomyOptions(options);
                handler.setState({offer});
            }
        });
    }

    public addVariant(
        lensesUid: number,
        coatingUid?: string,
        miscellaneous?: string[],
        lensesUidRight?: number,
        coatingUidRight?: string,
        miscellaneousRight?: string[]
    ) {
        this.buildVariant(
            lensesUid,
            coatingUid,
            miscellaneous,
            lensesUidRight,
            coatingUidRight,
            miscellaneousRight
        )
            .then(variant => this.handler.handleAddVariant(variant));
    }

    public buildVariant(
        lensesUid: number,
        coatingUid?: string,
        miscellaneous?: string[],
        lensesUidLeft?: number,
        coatingUidLeft?: string,
        miscellaneousLeft?: string[]
    ): Promise<Variant> {
        const handler = this.handler;

        if (lensesUidLeft) {
            return Promise.all(
                [
                    this.buildEyeConfiguration(lensesUid, coatingUid, miscellaneous),
                    this.buildEyeConfiguration(lensesUidLeft, coatingUidLeft, miscellaneousLeft)
                ]
            )
                .then(data => {
                    const [right, left] = data;
                    const variantNumber = handler.offer.variants.length + 1;

                    return new Variant(variantNumber, 'Variante ' + variantNumber, right, left, false);
                });

        } else {
            return this.buildEyeConfiguration(lensesUid, coatingUid, miscellaneous)
                .then(eyeConfiguration => {
                    const variantNumber = handler.offer.variants.length + 1;

                    return new Variant(variantNumber, 'Variante ' + variantNumber, eyeConfiguration);
                });
        }
    }

    public buildEyeConfiguration(
        lensesUid: number,
        coatingUid?: string,
        miscellaneous?: string[]
    ): Promise<EyeConfiguration> {
        const lensesRepository: Repository<Lenses> = this.serviceLocator.get('lensesLensesRepository');
        const additionRepository: Repository<Addition> = this.serviceLocator.get('lensesAdditionRepository');

        const fetches: Promise<DomainObject | null | (DomainObject | null)[]>[] = [
            lensesRepository.findByIdentifier(lensesUid) as Promise<DomainObject | null>
        ];
        if (coatingUid) {
            fetches.push(additionRepository.findByIdentifier(coatingUid));
        }
        if (miscellaneous) {
            fetches.push((new RepositoryEnhancement(additionRepository)).findMultipleByIdentifier(miscellaneous));
        }

        return Promise.all(fetches)
            .then(values => {
                const lenses = values[0];
                if (!lenses || !(lenses instanceof Lenses)) {
                    throw new ReferenceError(`Lenses ${lensesUid} not found`);
                }

                let eyeConfiguration = (new EyeConfiguration())
                    .withLenses(lenses)
                    .withProduct(lenses.product);

                if (values.length > 1 && values[1] instanceof Addition) {
                    eyeConfiguration = eyeConfiguration.withCoating(values[1] as Addition);
                }
                if (values.length > 2 && Array.isArray(values[2])) {
                    eyeConfiguration = eyeConfiguration.withMiscellaneous(values[2] as Addition[]);
                }

                return eyeConfiguration;
            });
    }

    public setSetting<K extends keyof Settings>(setting: K, value: any) {
        const newSettings = new Settings();
        const setter = 'with' + setting.charAt(0).toUpperCase() + setting.slice(1);

        return this.handler.setState({settings: newSettings[setter](value)});
    }

    public setSettings(settings: Settings) {
        return this.handler.setState({settings});
    }

    public async loadOffer(offerUid: string | number): Promise<Offer | null> {
        const handler = this.handler;

        const offer = await handler.offerStorage.load('' + offerUid);
        if (offer) {
            console.debug(`[${this.constructor.name}] Loaded offer #${offerUid}`);
            handler.setOffer(offer);
            return offer;
        } else {
            console.warn(`[${this.constructor.name}] Could not load offer #${offerUid}`);
            return null;
        }
    }

    public loadFavourite(favourite: Favourite, refractions: Refraction[]): Promise<Variant> {
        const favouriteService: FavouriteServiceInterface = this.serviceLocator.get('favouriteService');

        return favouriteService.buildVariant(favourite, refractions).then(variantResult => {
            this.handler.handleAddVariant(variantResult.variant);

            return variantResult.variant;
        });
    }
}
