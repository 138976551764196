import { ClassTypeOptions, ra } from '@cundd/rest-adapter';

@ra(ClassTypeOptions.AddUnknownFields)
export class User {
    public readonly username: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly image: string;
    public readonly sorting: number;

    constructor(username?: string, firstName?: string, lastName?: string, image?: string, sorting?: number) {
        if (username) {
            this.username = username;
        }
        if (firstName) {
            this.firstName = firstName;
        }
        if (lastName) {
            this.lastName = lastName;
        }
        if (image) {
            this.image = image;
        }
        if (sorting) {
            this.sorting = sorting;
        }
    }
}
