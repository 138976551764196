import * as React from 'react';
import { CurrencyFormatter } from '../../Formatter/CurrencyFormatter';
import { Price } from '../../Price';

interface CurrencyProps {
    value: number | string | Price,
    trailingZeros?: boolean,
    showSymbol?: boolean
}

export class Currency extends React.Component<CurrencyProps> {
    public render() {
        const value = this.props.value;
        const trailingZeros = this.props.trailingZeros;

        if (value === null || value === undefined) {
            return null;
        }

        const formatter = new CurrencyFormatter();
        const format = formatter.format(value, typeof trailingZeros === 'undefined' ? false : trailingZeros);

        if (this.props.showSymbol) {
            return <>
                <span className="currency-symbol">CHF&nbsp;</span>
                <span className="currency-price">{format}</span>
            </>;
        } else {
            return format;
        }
    }
}
