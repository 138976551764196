import * as React from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { Customer } from '../../Model/Customer/Customer';
import { SearchSuggest } from '../Form/SearchSuggest';

export class CustomerSelectorView extends React.Component {
    public static contextType = AppContextType;
    public context: AppContext;

    public render() {
        return (
            <label className="customer-customer-selector">
                <h1>Kunde</h1>
                <SearchSuggest
                    onSelect={this.context.handler.handleCustomerChange as any}
                    getOptionName={this.getSuggestOptionName as any}
                    repository={this.context.serviceLocator.get('customerRepository')}/>
            </label>
        );
    }

    public getSuggestOptionName(item: Customer) {
        return item.firstName + ' ' + item.lastName + ', ' + item.uid;
    }
}
