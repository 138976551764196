import { Repository } from '@cundd/rest-adapter';
import * as React from 'react';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { Refraction } from '../../../../../Model/Refraction';
import { FindProductQueryParameters } from '../../../../../Query/FindProductQueryParameters';
import { OnSelectHandler, Suggest } from '../../../../Form/Suggest';
import { SideEnum } from '../../../SideEnum';
import { AbstractRow, ResultantPrismArgument } from './AbstractRow';
import { FormRowProps } from './FormRowProps';

interface ProductRowProps extends FormRowProps<Product> {
}

export class ProductRow extends AbstractRow<Product, ProductRowProps> {
    protected renderHeader(): JSX.Element {
        return <h4 className="center">Grundglas</h4>;
    }

    protected renderRightColumn(): JSX.Element {
        const props = this.props;
        const refraction = this.getMergedRightRefraction();
        if (!refraction) {
            return this.renderPlaceholderField('Keine Refraktion');
        }

        const resultantPrism = this.detectHigherResultantPrism();
        const variant = props.variant;

        return this.renderInput(
            props.onChangeRight,
            variant.rightProduct,
            variant.rightProduct,
            refraction,
            resultantPrism
        );
    }

    protected renderLeftColumn() {
        const props = this.props;
        const variant = props.variant;

        const refraction = this.getRefraction(SideEnum.Left);
        if (!refraction) {
            return this.renderPlaceholderField('Keine Refraktion');
        }

        if (variant.symmetrical) {
            const value = variant.leftProduct ? this.suggestOptionName(variant.leftProduct) : '';

            return this.renderDisabledValue(value);
        } else {
            return this.renderInput(
                props.onChangeLeft,
                variant.leftProduct,
                variant.leftProduct,
                refraction,
                refraction.resultantPrism
            );
        }
    }

    protected renderInput(
        onSelect: OnSelectHandler<Product>,
        value: Product | undefined,
        product: Product | undefined,
        refraction: Refraction,
        resultantPrism: ResultantPrismArgument
    ): JSX.Element {
        console.debug(`[${this.constructor.name}] Render input`, value);
        const additionalQueryParameters: FindProductQueryParameters = {
            resultantPrism,
            refraction
        };

        return <Suggest
            className="suggest lenses-suggest lenses-product-suggest"
            onSelect={onSelect}
            value={value}
            getOptionName={this.suggestOptionName as any}
            additionalQueryParameters={additionalQueryParameters}
            repository={this.getRepository() as any}/>;
    }

    protected suggestOptionName(item: Product): string {
        if (item.inhousePriceId && item.diameter) {
            return item.name + ' (⌀' + item.diameter + ')';
        }
        return item.name;
    }

    protected getRepository(): Repository<Product> {
        return this.context.serviceLocator.get('lensesProductRepository');
    }
}
