import { Clone } from 'iresults-ts-core';
import { Variant } from '../Model/Offer/Variant';
import { EyeConfiguration } from '../Model/Offer/Variant/EyeConfiguration';

function cloneIfSome<T extends Clone>(input: T | undefined): T | undefined {
    if (input) {
        return input.clone() as T;
    } else {
        return undefined;
    }
}

function cloneSlice<T extends Clone>(slice: T[]): T[] {
    return slice.map(i => i.clone());
}

export class VariantSymmetricalService {
    /**
     * Build an asymmetric version of the given Variant and fills the left Eye with values from the right Eye
     *
     * @param {Variant} variant
     */
    public buildAsymmetricalVariant(variant: Variant): Variant {
        const asymmetric = variant.withSymmetrical(false);
        const right = asymmetric.right;
        if (!right) {
            return asymmetric;
        }

        const originalLeft = asymmetric.left;
        if (!originalLeft) {
            return asymmetric._assignLeft(right.clone());
        }

        const lenses = originalLeft.lenses || cloneIfSome(right.lenses);
        const product = lenses ? lenses.product : undefined;
        const miscellaneous = originalLeft.miscellaneous && originalLeft.miscellaneous.length > 0
            ? originalLeft.miscellaneous
            : cloneSlice(right.miscellaneous);

        return asymmetric._assignLeft(new EyeConfiguration(
            product,
            originalLeft.coating || cloneIfSome(right.coating),
            miscellaneous,
            lenses,
        ));
    }
}
