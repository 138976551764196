import * as React from 'react';
import { Address } from '../../Model/Customer/Address';
import { Customer } from '../../Model/Customer/Customer';
import { Settings } from '../../Model/Settings/Settings';
import { UpperSectionStyle } from '../../Model/Settings/UpperSectionStyle';
import { RefractionSelectPopup } from './RefractionSelectPopup';

interface DetailWidgetProps {
    customer: Customer,
    settings: Settings
}

interface DetailWidgetState {
    showRefractionSelectPopup: boolean
}

export class DetailWidget extends React.Component<DetailWidgetProps, DetailWidgetState> {
    constructor(props: DetailWidgetProps, context: any) {
        super(props, context);
        this.handleShowRefractionSelectPopup = this.handleShowRefractionSelectPopup.bind(this);
        this.handleDismissRefractionSelectPopup = this.handleDismissRefractionSelectPopup.bind(this);
        this.state = {showRefractionSelectPopup: false};
    }

    public render() {
        const customer = this.props.customer;
        const address = customer.addresses[0];
        const settings = this.props.settings;

        return (
            <section className="customer-customer-detail-widget">
                <h1>Kunde</h1>
                {
                    settings.upperSectionStyle === UpperSectionStyle.DETAILED
                        ? this.renderDetailedAddress(customer, address)
                        : this.renderSmallAddress(customer)
                }
                {
                    this.state.showRefractionSelectPopup
                        ? <RefractionSelectPopup customer={customer}
                                                 onDismiss={this.handleDismissRefractionSelectPopup}/>
                        : null
                }
            </section>
        );
    }

    private renderDetailedAddress(customer: Customer, address: Address) {
        return <address>
            <h3>{customer.firstName} {customer.lastName}</h3>
            <div className="customer-address">{address.street} {address.houseNumber}</div>
            <div className="customer-location">{address.zip} {address.city}</div>
            <div className="customer-uid-row">
                <span className="customer-uid">KD-Nr. {customer.uid}</span>
                {this.renderRefractionButton()}
            </div>
        </address>;
    }

    private renderSmallAddress(customer: Customer) {
        return <address>
            <h3>{customer.firstName} {customer.lastName}</h3>
            <div className="customer-uid-row">
                <span className="customer-uid">KD-Nr. {customer.uid}</span>
                {this.renderRefractionButton()}
            </div>
        </address>;
    }

    private renderRefractionButton() {
        const refractionPairs = this.props.customer.refractionPairs;
        if ((refractionPairs && refractionPairs.length > 0) || this.props.customer.refractionPair) {
            return <a className="customer-refraction-button"
                      onClick={this.handleShowRefractionSelectPopup}><i className="material-icons">remove_red_eye</i></a>;
        } else {
            return null;
        }
    }

    private handleShowRefractionSelectPopup() {
        console.debug('[DetailWidget] Show Refraction Select Popup');
        this.setState(() => ({showRefractionSelectPopup: true}));
    }

    private handleDismissRefractionSelectPopup() {
        console.debug('[DetailWidget] Hide Refraction Select Popup');
        this.setState(() => ({showRefractionSelectPopup: false}));
    }
}
