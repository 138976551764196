import { SortEnd } from 'react-sortable-hoc';

export class SortableHelper {
    public static reorderElements<T>(input: T[], sortingUpdate: SortEnd): T[] {
        return SortableHelper.moveElements(input, sortingUpdate.oldIndex, sortingUpdate.newIndex);
    }

    public static moveElements<T>(input: T[], from: number, to: number): T[] {
        const output = input.slice();
        output.splice(
            to < 0 ? output.length + to : to,
            0,
            output.splice(from, 1)[0]
        );

        return output;
    }
}
