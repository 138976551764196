import { Price } from '../Price';

export class CurrencyFormatter {
    public format(value: number | string | Price, trailingZeros: boolean): string {
        if (value instanceof Price) {
            return this.format(value.valueOf(), trailingZeros);
        }
        if (typeof value === 'string') {
            return this.format(parseFloat(value), trailingZeros);
        }
        const hasDecimals = value % 1 !== 0;
        const numberOfDecimals = 2;
        if (hasDecimals || trailingZeros) {
            return Number(value.toFixed(numberOfDecimals)).toLocaleString('de-CH', {minimumFractionDigits: 2});
        } else {
            return Number(value.toFixed(0)).toLocaleString('de-CH') + '.-';
        }
    }
}
