import * as React from 'react';
import { GeneralRowProps } from './GeneralRowProps';

export abstract class AbstractGeneralRow<T, P extends GeneralRowProps<T> = GeneralRowProps<T>, S = {}> extends React.Component<P, S> {
    public render() {
        return (
            <div className="row-container lenses-configuration-row lenses-coating">
                <header className="grid-3 offset-1">{this.renderHeader()}</header>
                <div className="grid-2">
                    {this.renderInput()}
                </div>
            </div>
        );
    }

    protected abstract renderHeader(): JSX.Element;

    protected abstract renderInput(): JSX.Element;
}
