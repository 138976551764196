import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import { AppVersion } from './Components/AppVersion';
import { LoginActionView } from './Components/Login/LoginActionView';
import { Overview as LoginOverview } from './Components/Login/Overview';
import { ShowView as UserShowView } from './Components/Login/ShowView';
import { ClearActionView } from './Components/Offer/ClearActionView';
import { FavouritePopup } from './Components/Offer/Favourite/FavouritePopup';
import { OfferPopup } from './Components/Offer/Import/OfferPopup';
import { NewView } from './Components/Offer/NewView';
import { Config } from './Config';
import { HandlerContextProvider } from './HandlerContextProvider';
import { AppState, MainHandler } from './MainHandler';
import { AuthenticationService } from './Service/AuthenticationService';
import { ServiceLocator } from './ServiceLocator';

export interface AppProps {
    serviceLocator: ServiceLocator;
    handler: MainHandler;
}

interface AppStateLocal extends AppState {
    error?: Error | string
}

export class App extends React.Component<AppProps, AppStateLocal> {
    constructor(props: AppProps, context: any) {
        super(props, context);
        this.state = this.props.handler.state;
    }

    public render() {
        const classNames = [
            'main-container',
            'upper-section-style-' + this.state.settings.upperSectionStyle
        ];

        const authenticationService: AuthenticationService = this.props.serviceLocator.get('authenticationService');
        const config = new Config();
        const basePath = config.basePath;

        if (this.state.isAuthenticated) {
            return (
                <Router>
                    <HandlerContextProvider handler={this.props.handler} serviceLocator={this.props.serviceLocator}>
                        <div className={classNames.join(' ')}>
                            <AppVersion/>
                            <Route exact={true} path={`${basePath}/`} component={LoginOverview}/>
                            <Route exact={true} path={`${basePath}/login/login/:username`} component={LoginActionView}/>
                            <Route exact={true} path={`${basePath}/login/show/:username`} component={UserShowView}/>
                            <Route exact={true} path={`${basePath}/offer/new`} component={NewView}/>
                            <Route exact={true} path={`${basePath}/offer/clear`} component={ClearActionView}/>
                            <Route exact={true} path={`${basePath}/offer/import/show`} component={OfferPopup}/>
                            <Route exact={true} path={`${basePath}/offer/favourite/show`} component={FavouritePopup}/>
                        </div>
                    </HandlerContextProvider>
                </Router>
            );
        } else if (this.state.error) {
            const apiEndpoint = `${process.env.REACT_APP_API_URL}authorization`;

            return <div>
                Bitte gehen Sie auf <a href={apiEndpoint} target="_blank">{apiEndpoint}</a> und loggen Sie sich dort ein
            </div>;
        } else {
            authenticationService.authenticate()
                .then(() => this.setState({isAuthenticated: true}))
                .catch(() => {
                    this.setState({error: 'not authenticated'});
                });

            return null;
        }
    }

    public componentDidMount(): void {
        const config = new Config();
        if (config.isDevelopmentEnv) {
            window.onbeforeunload = () => {
                console.info('Reload not caught in development environment');
            };
        } else {
            // Add event listener to prevent accidental reload
            window.onbeforeunload = (event: any) => {
                event.preventDefault();
                event.returnValue = '';
            };
        }
    }
}
