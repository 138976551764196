import { ClassTypeOptions, PropertyTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { BaseModel } from '../../BaseModel';
import { Addition } from '../../Catalog/Lenses/Addition';
import { Lenses } from '../../Catalog/Lenses/Lenses';
import { Product } from '../../Catalog/Lenses/Product';
import { Variant } from '../Variant';
import { EyeConfigurationInterface } from './EyeConfigurationInterface';

/**
 * An EyeConfiguration defines the selected Lenses, Coating and miscellaneous Additions for one eye
 */
@ra(ClassTypeOptions.DenyUnknownFields)
export class EyeConfiguration extends BaseModel implements Clone, EyeConfigurationInterface<Addition, Lenses> {
    @ra_property(Product, 'product')
    protected _product?: Product;

    @ra_property(Addition, 'coating')
    protected _coating?: Addition;

    @ra_property(Lenses, 'lenses')
    protected _lenses?: Lenses;

    @ra_property(Addition, PropertyTypeOptions.Multiple, 'miscellaneous')
    protected _miscellaneous: Addition[];

    @ra_property('variant')
    protected _variant?: Variant;

    constructor(
        product?: Product,
        coating?: Addition,
        miscellaneous: Addition[] = [],
        lenses?: Lenses,
        variant?: Variant
    ) {
        super();
        this._variant = variant;
        this._product = product;
        this._coating = coating;
        this._miscellaneous = miscellaneous;
        this._lenses = lenses;
    }

    get product(): Product | undefined {
        return this._product;
    }

    get coating(): Addition | undefined {
        return this._coating;
    }

    get miscellaneous(): Addition[] {
        return this._miscellaneous;
    }

    // noinspection JSUnusedLocalSymbols
    set variant(v: Variant | undefined) {

    }

    get variant(): Variant | undefined {
        return this._variant;
    }

    get lenses(): Lenses | undefined {
        return this._lenses;
    }

    get description(): string {
        let description = '';
        if (this.lenses) {
            description += this.lenses.name + ' ';
        }
        if (this.coating) {
            description += 'mit ' + this.coating.name + ' ';
        }

        return description;
    }

    public withProduct(value: Product | undefined): EyeConfiguration {
        const clone = this.clone();
        clone._product = value;

        return clone;
    }

    public withCoating(value: Addition | undefined): EyeConfiguration {
        const clone = this.clone();
        clone._coating = value;

        return clone;
    }

    public withMiscellaneous(value: Addition[]): EyeConfiguration {
        const clone = this.clone();
        clone._miscellaneous = value;

        return clone;
    }

    public withAddedMiscellaneous(addition: Addition): EyeConfiguration {
        const miscellaneous = this.miscellaneous ? this.miscellaneous.slice() : [];
        miscellaneous.push(addition);

        return this.withMiscellaneous(miscellaneous);
    }

    public withLenses(value: Lenses | undefined): EyeConfiguration {
        const clone = this.clone();
        clone._lenses = value;

        return clone;
    }

    public clone<T extends Clone = this>(): this | T {
        return new EyeConfiguration(
            this.product,
            this.coating,
            this.miscellaneous,
            this.lenses,
            this._variant,
        );
    }
}
