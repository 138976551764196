import * as React from 'react';
import { AbstractGeneralRow } from './AbstractGeneralRow';
import { GeneralRowProps } from './GeneralRowProps';

interface ViewState {
    discount: number
}

export class DiscountRow extends AbstractGeneralRow<number, GeneralRowProps<number>, ViewState> {
    constructor(props: GeneralRowProps<number>, context: any) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        const discount = props.variant.discount;
        // noinspection SuspiciousTypeOfGuard
        this.state = {
            discount: typeof discount === 'number' ? discount : parseInt(discount, 10)
        };
    }

    protected renderHeader(): JSX.Element {
        return <h4 className="center">Preisreduktion</h4>;
    }

    protected renderInput() {
        const value = this.state.discount;
        const inputClassName = value >= 100 ? 'text-field-big' : 'text-field-normal';

        return <input type="text"
                      pattern="\d*"
                      min="0"
                      className={inputClassName}
                      value={value}
                      onChange={this.handleChange}/>;
    }

    private handleChange(event: any) {
        const discount = this.getDiscount(event.target.value);
        this.setState({discount});

        const props = this.props;
        props.onChange(discount, event);
    }

    private getDiscount(value: string): number {
        if (value === '') {
            return 0;
        }

        const result = parseInt(value, 10);

        return isNaN(result) ? 0 : result;
    }
}
