import { Serializable } from '../Service/Serializable';

type float = number;

export class ResultantPrism implements Serializable {
    /**
     * @var float
     */
    private readonly _prism: float;

    /**
     * @var float
     */
    private readonly _base: float;

    /**
     * ResultantPrism constructor
     *
     * @param {float} prism
     * @param {float} base
     */
    public constructor(prism: float, base: float) {
        this.assertNumber(prism);
        this.assertNumber(base);
        this._prism = prism;
        this._base = base;
    }

    public get prism(): float {
        return Math.round(this._prism * 10) / 10;
    }

    public getPrismPrecise(): float {
        return this.prism;
    }

    public get base(): float {
        return Math.round(this._base);
    }

    public getBasePrecise(): float {
        return this._base;
    }

    public serialize(): string {
        return this.prism + '_' + this.base;
    }

    private assertNumber(value: float | any) {
        if (typeof value !== 'number') {
            throw new TypeError('Expected value to be a number');
        }
    }
}
