import * as React from 'react';
import { SyntheticEvent } from 'react';
import { EconomyOption } from '../../../Model/Catalog/EconomyOption';
import { Currency } from '../../Formatter/Currency';
import { EconomyOptionProps } from './EconomyOptionProps';

interface CheckboxRowProps extends EconomyOptionProps {
    option: EconomyOption;
    checked: boolean;
    onChange: (option: EconomyOption, event: SyntheticEvent) => void;
}

export class CheckboxRow extends React.Component<CheckboxRowProps> {
    constructor(props: CheckboxRowProps, context: any) {
        super(props, context);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    public render() {
        const option = this.props.option;
        const checked = this.props.checked;

        return (
            <div className="row-container form-group">
                <label className="grid-10 form-field">
                    <input type="checkbox"
                           checked={checked}
                           onChange={this.handleInputChange}/>
                    <span className="label">{option.description}</span>,
                    <small>CHF <Currency value={option.price}/></small>
                </label>
                <div className="grid-2 price">
                    {checked ? <Currency value={option.price} trailingZeros={true}/> : null}
                </div>
            </div>
        );
    }

    private handleInputChange(event: any) {
        this.props.onChange(this.props.option, event);
    }
}
