import { History } from 'history';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Redirect } from 'react-router';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Config } from '../../../Config';
import { Favourite } from '../../../Model/Offer/Favourite';
import { Group } from '../../../Model/Offer/Favourite/Group';
import { VariantResult } from '../../../Model/Offer/VariantResult';
import { CollectionService } from '../../../Service/CollectionService';
import { FavouriteService } from '../../../Service/FavouriteService';
import { Popup, PopupStyle } from '../../Popup';
import { AbstractAutoLoadingView, AutoLoadingViewState, ComponentState } from '../AbstractAutoLoadingView';
import { VariantResultView } from '../Variant/VariantResultView';
import { FavouriteHandler } from './FavouriteView';
import { GroupView } from './GroupView';

export interface FavouritePopupProps {
    history: History
}

interface FavouritePopupState extends AutoLoadingViewState {
    selected: Favourite[]
}

export class FavouritePopup extends AbstractAutoLoadingView<FavouritePopupProps, FavouritePopupState> {
    public static contextType = AppContextType;
    public context: AppContext;
    private readonly favouriteService: FavouriteService;

    constructor(props: FavouritePopupProps, context: AppContext) {
        super(props, context);
        this.close = this.close.bind(this);
        this.handleClickLoad = this.handleClickLoad.bind(this);
        this.handleSelect = this.handleSelect.bind(this) as FavouriteHandler;
        this.handleUnselect = this.handleUnselect.bind(this) as FavouriteHandler;

        this.favouriteService = context.serviceLocator.get('favouriteService');

        this.state = {
            componentState: ComponentState.none,
            selected: []
        };
    }

    public renderContent() {
        const user = this.context.offer.login;
        const config = new Config();
        const basePath = config.basePath;

        if (!user) {
            return <Redirect to={`${basePath}/`}/>;
        }
        return <Popup style={PopupStyle.Fullscreen} onDismiss={this.close} header={this.renderHeader()}>
            <div className="favourite-overview-groups-container">
                <GroupView group={Group.SingleVision}
                           close={this.close}
                           selectedFavourites={this.state.selected}
                           onSelect={this.handleSelect}
                           onUnselect={this.handleUnselect}/>
                <GroupView group={Group.Varifocal}
                           close={this.close}
                           selectedFavourites={this.state.selected}
                           onSelect={this.handleSelect}
                           onUnselect={this.handleUnselect}/>
                <GroupView group={Group.Activity}
                           close={this.close}
                           selectedFavourites={this.state.selected}
                           onSelect={this.handleSelect}
                           onUnselect={this.handleUnselect}/>
                <GroupView group={Group.Sunglasses}
                           close={this.close}
                           selectedFavourites={this.state.selected}
                           onSelect={this.handleSelect}
                           onUnselect={this.handleUnselect}/>
                <GroupView group={Group.Special}
                           close={this.close}
                           selectedFavourites={this.state.selected}
                           onSelect={this.handleSelect}
                           onUnselect={this.handleUnselect}/>
            </div>
        </Popup>;
    }

    private close() {
        console.debug(`[${this.constructor.name}] Close Favourite Popup`);
        this.props.history.goBack();
    }

    private renderHeader() {
        const className = 'favourite-load discreet' + (this.state.selected.length > 0 ? ' -enabled' : ' -disabled');

        return <a role="button" className={className} onClick={this.handleClickLoad}>
            <i className="material-icons">open_in_browser</i> Laden
        </a>;
    }

    // noinspection JSUnusedLocalSymbols
    private handleSelect(favourite: Favourite, event: SyntheticEvent) {
        console.debug(`[${this.constructor.name}] Handle select Favourite`, favourite);
        this.setState(prev => {
            const selected = prev.selected.slice();
            selected.push(favourite);

            return {selected};
        });
    }

    // noinspection JSUnusedLocalSymbols
    private handleUnselect(favourite: Favourite, event: SyntheticEvent) {
        console.debug(`[${this.constructor.name}] Handle unselect Favourite`, favourite);
        this.setState(prev => {
            const selected = CollectionService.removeItemFromCollection(favourite, prev.selected);

            return {selected};
        });
    }

    private handleClickLoad(event: SyntheticEvent) {
        console.debug(`[${this.constructor.name}] Handle click load`, event);
        const handler = this.context.handler;
        const refractionPair = this.context.offer.refractionPair;

        this.favouriteService.buildVariants(this.state.selected, refractionPair).then(variantResults => {
            let highestVariantUid = handler.offer.detectHighestVariantUid();
            const resultsWithErrors: VariantResult[] = [];
            const variants = variantResults.map(
                result => {
                    // Assign an UID to the Variant
                    highestVariantUid += 1;
                    const variant = result.variant;
                    if (result.errors && result.errors.length > 0) {
                        resultsWithErrors.push(result);
                    }
                    variant.uid = '' + highestVariantUid;

                    return variant;
                }
            );

            handler.handleAddVariants(variants);
            VariantResultView({results: resultsWithErrors});
            this.close();
        });
    }
}
