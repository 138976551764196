import * as React from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Service } from '../../../Model/Service';
import { LensesDetermination } from './LensesDetermination';
import { MaterialFee } from './MaterialFee';
import { Reworking } from './Reworking';

interface AERowProps {
}

export class AEContainer extends React.Component<AERowProps, {}> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: AERowProps, context: AppContext) {
        super(props, context);
        this.handleLensesDetermination = this.handleLensesDetermination.bind(this);
        this.handleReworking = this.handleReworking.bind(this);
        this.handleMaterialFee = this.handleMaterialFee.bind(this);
    }

    public render() {
        const offer = this.context.handler.offer;

        return <div className="offer-ae-container">
            <Reworking onChange={this.handleReworking} offer={offer}/>
            <MaterialFee onChange={this.handleMaterialFee} offer={offer}/>
            <LensesDetermination onChange={this.handleLensesDetermination} offer={offer}/>
        </div>;
    }

    public handleLensesDetermination(lensesDeterminationPrice: number) {
        console.debug('[AE] Handle Lenses Determination Price change', lensesDeterminationPrice);
        const handler = this.context.handler;
        const offer = handler.offer;
        handler.setOffer(offer.withLensesDeterminationPrice(lensesDeterminationPrice));
    }

    public handleReworking(reworking: Service) {
        console.debug('[AE] Handle Reworking Price change', reworking);
        const handler = this.context.handler;
        const offer = handler.offer;
        handler.setOffer(offer.withReworking(reworking));
    }

    public handleMaterialFee(materialFee: number) {
        console.debug('[AE] Handle Material Fee Price change', materialFee);
        const handler = this.context.handler;
        const offer = handler.offer;
        handler.setOffer(offer.withMaterialFee(materialFee));
    }
}
