import { ApiError, ExecuteMethod, Repository } from '@cundd/rest-adapter';
import { LoggerInterface } from '../LoggerInterface';
import { RefractionPair } from '../Model/Customer/RefractionPair';
import { Favourite } from '../Model/Offer/Favourite';
import { FavouriteRequest } from '../Model/Offer/Favourite/FavouriteRequest';
import { Group } from '../Model/Offer/Favourite/Group';
import { FavouriteGroup } from '../Model/Offer/FavouriteGroup';
import { FavouriteGroupCollection } from '../Model/Offer/FavouriteGroupCollection';
import { VariantResult } from '../Model/Offer/VariantResult';
import { Refraction } from '../Model/Refraction';
import { User } from '../Model/User';
import { FavouriteGroupStorage } from './FavouriteGroupStorage';
import { FavouriteServiceInterface } from './FavouriteServiceInterface';

export class FavouriteService implements FavouriteServiceInterface {
    private temporaryStorage: Map<string, FavouriteGroupCollection>;
    private readonly logger: LoggerInterface;

    constructor(
        private variantResultRepository: Repository<VariantResult>,
        private favouriteGroupStorage: FavouriteGroupStorage,
        logger?: LoggerInterface
    ) {
        this.logger = logger || console;
        this.temporaryStorage = new Map();
    }

    public getFavouriteGroup(user: User, group: Group): Promise<FavouriteGroup | undefined> {
        return this.getFavouriteGroupAndCollection(user, group).then(r => r.group);
    }

    public appendFavourite(user: User, favourite: Favourite): Promise<FavouriteGroup> {
        const logger = this.logger;

        return this.getFavouriteGroupAndCollection(user, favourite.group).then(
            ({collection, group}) => {
                logger.debug(
                    `[FavouriteService] Append Favourite "${favourite.description}" to Group "${favourite.group}"`,
                    favourite
                );

                let modifiedGroup: FavouriteGroup;
                if (group) {
                    // Update the Group and store it
                    modifiedGroup = group.withAddedFavourite(favourite);
                } else {
                    // Create a fresh Group and store it
                    modifiedGroup = new FavouriteGroup(favourite.group, [favourite]);
                }

                let modifiedCollection: FavouriteGroupCollection;
                if (collection) {
                    modifiedCollection = collection;
                } else {
                    // console.debug(`Build a new FavouriteGroup Collection for group "${favourite.group}" and user "${user.username}"`);
                    modifiedCollection = new FavouriteGroupCollection(user.username);
                }
                this.storeGroupWithCollection(user, modifiedCollection, modifiedGroup);

                return modifiedGroup;
            }
        );
    }

    public removeFavourite(user: User, favourite: Favourite): Promise<FavouriteGroup> {
        return this.getFavouriteGroupAndCollection(user, favourite.group).then(
            ({collection, group}) => {
                if (!group || !collection) {
                    throw new ReferenceError('Group for favourite not found');
                }

                console.debug(
                    `[FavouriteService] Remove Favourite "${favourite.description}" from Group "${favourite.group}"`,
                    favourite
                );

                // Update the Group and store it
                const updatedGroup = group.withRemovedFavourite(favourite);
                this.storeGroupWithCollection(user, collection, updatedGroup);

                return updatedGroup;
            }
        );
    }

    public storeGroup(user: User, favouriteGroup: FavouriteGroup) {
        this.getFavouriteGroupCollection(user).then(collection => {
            if (collection) {
                this.storeGroupWithCollection(user, collection, favouriteGroup);
            } else {
                throw new ReferenceError('Favourite Group Collection not found');
            }
        });
    }

    public buildVariant(
        favourite: Favourite,
        refractionPair: RefractionPair | undefined | Refraction[],
        uid?: string | number
    ): Promise<VariantResult> {
        const requestBody: FavouriteRequest = favourite.clone() as FavouriteRequest;
        if (refractionPair === undefined) {
            // Noop
        } else if (refractionPair instanceof RefractionPair) {
            requestBody.refractions = refractionPair.right?.serialize() + ',' + refractionPair.left?.serialize();
        } else if (Array.isArray(refractionPair) && refractionPair.length > 0) {
            console.warn('Using buildVariant() with an array of Refractions is deprecated');

            requestBody.refractions = refractionPair.map(r => r.serialize()).join(',');
        }

        return this.variantResultRepository.execute('load', ExecuteMethod.POST, requestBody).then(variantResult => {
            if (variantResult instanceof VariantResult) {
                if (uid !== undefined) {
                    console.warn('Using buildVariant() with an UID is deprecated');
                }
                if (uid) {
                    variantResult.variant.uid = '' + uid;
                }
                return variantResult;
            }
            throw new ApiError('Could not transform Favourite into Variant', favourite);
        });
    }

    public buildVariants(
        favourites: Favourite[],
        refractionPair: RefractionPair | undefined | Refraction[]
    ): Promise<VariantResult[]> {
        const promises: Promise<VariantResult>[] = [];
        for (const favourite of favourites) {
            promises.push(this.buildVariant(favourite, refractionPair));
        }

        return Promise.all(promises);
    }

    private storeGroupWithCollection(
        user: User,
        collection: FavouriteGroupCollection,
        favouriteGroup: FavouriteGroup
    ) {
        if (!collection) {
            throw new ReferenceError('No Favourite Group Collection given');
        }
        const modifiedCollection = collection.withGroup(favouriteGroup);
        this.favouriteGroupStorage.store(modifiedCollection);
        this.temporaryStorage.set(user.username, modifiedCollection);
    }

    private getFavouriteGroupCollection(user: User): Promise<FavouriteGroupCollection | undefined> {
        if (this.temporaryStorage.has(user.username)) {
            return Promise.resolve(this.temporaryStorage.get(user.username));
        }
        return this.favouriteGroupStorage.load(user.username).then(favGroupCollection => {
            if (!favGroupCollection) {
                return undefined;
            }
            // Make sure the username is set
            if (!favGroupCollection.username) {
                favGroupCollection.username = user.username;
            }
            return favGroupCollection;
        });
    }

    private getFavouriteGroupAndCollection(user: User, group: Group): Promise<{
        collection: FavouriteGroupCollection | undefined,
        group: FavouriteGroup | undefined
    }> {
        return this.getFavouriteGroupCollection(user).then(favGroupCollection => {
            if (favGroupCollection) {
                this.temporaryStorage.set(user.username, favGroupCollection);

                return {
                    collection: favGroupCollection,
                    group: favGroupCollection.getGroup(group)
                };
            } else {
                return {
                    collection: undefined,
                    group: undefined,
                };
            }
        });
    }
}
