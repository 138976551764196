import { Deserializer, Serializer } from '@cundd/rest-adapter';
import { LoggerInterface } from '../LoggerInterface';
import { AsyncBrowserStorageAdapter } from './Storage/AsyncBrowserStorageAdapter';
import { StorageAdapterInterface } from './Storage/StorageAdapterInterface';

export abstract class AbstractStorage<T extends object> {
    private readonly storage: StorageAdapterInterface<T>;
    private readonly serializer: Serializer<T>;
    private readonly deserializer: Deserializer<T>;
    private readonly logger?: LoggerInterface;

    constructor(
        storage?: StorageAdapterInterface<T>,
        serializer?: Serializer<T>,
        deserializer?: Deserializer<T>,
        logger?: LoggerInterface
    ) {
        if (storage) {
            this.storage = storage;
        } else {
            this.storage = new AsyncBrowserStorageAdapter();
        }

        this.serializer = serializer || new Serializer<T>();
        this.deserializer = deserializer || new Deserializer<T>();
        this.logger = logger;
    }

    /**
     * Store the given object in the Browser's storage
     *
     * @param {T} instance
     * @return {this}
     */
    public store(instance: T): Promise<T | null> {
        return this.storage.setItem(
            this.getNamespace(),
            this.getInstanceUid(instance),
            this.serializer.serialize(instance),
            instance
        );
    }

    /**
     * Load the T with the given UID from the Browser's storage
     *
     * @param {string} uid
     * @return {T | null}
     */
    public load(uid: string): Promise<T | null> {
        return this.fetchRawValue(uid).then(storedValue => {
            if (typeof storedValue === 'string') {
                return this.deserializer.deserialize<T, T>(this.getType(), storedValue);
            } else if (storedValue) {
                return storedValue;
            } else {
                const warning = `[${this.constructor.name}] No entry found with UID #${uid}`;
                if (this.logger) {
                    this.logger.warn(warning);
                } else {
                    console.warn(warning);
                }

                return null;
            }
        });
    }

    /**
     * Return if an entry for the given UID exists
     *
     * @param {string} uid
     * @return {Promise<boolean | null>}
     */
    public has(uid: string): Promise<boolean | null> {
        return this.fetchRawValue(uid).then(v => v !== null);
    }

    /**
     * Return the storage namespace
     *
     * @return {string}
     */
    protected abstract getNamespace(): string;

    /**
     * Return the constructor function/class this storage manages
     *
     * @return {{new(...args: any[]): T}}
     */
    protected abstract getType(): new (...args: any[]) => T;

    protected buildTimeTag(): string {
        return (+new Date()).toFixed(0);
    }

    protected fetchRawValue(uid: string): Promise<T | string | null> {
        return this.storage.getItem(this.getNamespace(), uid);
    }

    protected getInstanceUid(instance: T) {
        return (instance as any)['uid'];
    }
}
