import { Offer } from '../Model/Offer/Offer';
import { Variant } from '../Model/Offer/Variant';
import { FramePriceCalculator } from './FramePriceCalculator';
import { VariantPriceCalculator } from './VariantPriceCalculator';

export class TotalPriceCalculator {
    private readonly framePriceCalculator: FramePriceCalculator;
    private readonly variantPriceCalculator: VariantPriceCalculator;

    constructor(
        framePriceCalculator?: FramePriceCalculator,
        variantPriceCalculator?: VariantPriceCalculator
    ) {
        this.framePriceCalculator = framePriceCalculator || new FramePriceCalculator();
        this.variantPriceCalculator = variantPriceCalculator || new VariantPriceCalculator();
    }

    /**
     * Calculate the total price for the Variant and the Frame in the Offer
     *
     * @param {Variant} variant
     * @param {Offer} offer
     * @return {number}
     */
    public calculatePriceForVariantAndOffer(variant: Variant, offer: Offer): number {
        const framePrice = this.framePriceCalculator.buildPriceForOffer(offer);
        const variantPrice = this.variantPriceCalculator.calculateTotalPrice(variant, offer);

        if (framePrice) {
            return framePrice.total + variantPrice;
        } else {
            return variantPrice;
        }
    }

    // private calculateAdditionalPrice(offer:Offer) {
    //
    //     if (offer.reworking) {
    //         const reworkingPrice = offer.reworking.price;
    //         if (variant.symmetrical) {
    //             price += 2 * reworkingPrice;
    //         } else if (variant.leftLenses && variant.rightLenses) {
    //             price += 2 * reworkingPrice;
    //         } else {
    //             price += reworkingPrice;
    //         }
    //     }
    //     const lensesDeterminationPrice = variant.lensesDeterminationPrice;
    //     if (typeof lensesDeterminationPrice !== 'undefined') {
    //         price += lensesDeterminationPrice;
    //     }
    //
    //     const discount = variant.discount;
    //     if (discount) {
    //         price -= discount;
    //     }
    //
    //     return price;
    // }
}
