import * as React from 'react';
import { BaseModel } from '../../Model/BaseModel';
import { Spinner, SpinnerType } from '../Spinner';
import { AbstractAjaxFormField, AjaxFormFieldProps } from './AjaxFormField';

const defaultSelectValue = 'default-select-value-ff4b2218-82f2-47cf-ab88-0ca1d319dea1-1';

interface AjaxSelectProps<T extends BaseModel> extends AjaxFormFieldProps<T> {
    searchTerm?: string,
    placeholderText?: string,
}

export class AjaxSelect<T extends BaseModel> extends AbstractAjaxFormField<T, AjaxSelectProps<T>> {
    constructor(props: AjaxSelectProps<T>, context: any) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        if (props.value) {
            this.state = {
                showSpinner: false,
                options: [props.value],
                value: props.value,
                stringValue: this.getStateStringValueForItem(props.value)
            };
        } else if (props.placeholderText) {
            this.state = {
                showSpinner: true,
                options: [] as T[],
                value: undefined,
                stringValue: defaultSelectValue
            };
        }
    }

    public render() {
        const props = this.props;
        const state = this.state;

        const options = this.filteredOptions;
        const value = this.isSelectedValueValid(state.stringValue, options)
            ? state.stringValue
            : defaultSelectValue;
        const showSpinner = state.showSpinner && !props.disableSpinner;
        if (value === null) {
            console.warn(`[${this.constructor.name}] Bad select value NULL found`);
        }

        return (
            <div className={props.className || 'ajax-select'}>
                <label className={props.labelClassName || 'ajax-select-inner select-control'}>
                    {props.children}
                    <select value={value} onChange={this.handleChange}>
                        {this.renderOptions(options)}
                    </select>
                    {showSpinner ? <Spinner type={SpinnerType.Custom}/> : null}
                </label>
            </div>
        );
    }

    public handleChange(event: React.FormEvent<HTMLSelectElement>) {
        const value = (event.target as HTMLSelectElement).value;
        this.setState({stringValue: value});

        const getOptionValueCallback = this.props.getOptionValue || this.defaultGetOptionValueCallback();
        const foundOption = this.filteredOptions.find((option: T) => {
            if (option.guid === value) {
                return true;
            }
            return getOptionValueCallback(option) === value;
        });

        if (foundOption) {
            this.handleChooseOption(foundOption, event);
        } else {
            console.error(`[${this.constructor.name}] No option matching "${value}" found`);
        }
    }

    public componentDidMount(): void {
        this.fetchOptions(this.props.searchTerm || '', this.props.additionalQueryParameters);
    }

    public componentDidUpdate(prevProps: AjaxSelectProps<T>) {
        const newProps = this.props;
        const currentSearchTerm = newProps.searchTerm;
        const additionalQueryParameters = newProps.additionalQueryParameters;

        if (currentSearchTerm !== prevProps.searchTerm) {
            // Search term changed -> fetch
            this.fetchOptions(currentSearchTerm || '', additionalQueryParameters);
            return;
        }

        const currentParams = newProps.additionalQueryParameters;
        const prevParams = prevProps.additionalQueryParameters;
        if (currentParams === prevParams) {
            // No need to fetch
            return;
        }
        if (currentParams && !prevParams) {
            // New params -> fetch
            this.fetchOptions(currentSearchTerm || '', additionalQueryParameters);
            return;
        }
        if (!currentParams && prevParams) {
            // Params have been deleted -> fetch
            this.fetchOptions(currentSearchTerm || '', additionalQueryParameters);
            return;
        }
        if (currentParams && prevParams && JSON.stringify(currentParams) !== JSON.stringify(prevParams)) {
            // Params changed -> fetch
            this.fetchOptions(currentSearchTerm || '', additionalQueryParameters);
            return;
        }
    }

    protected renderOptions(options: T[] | undefined): JSX.Element | null {
        if (!options || options.length === 0) {
            console.log(`[${this.constructor.name}] No options`);
            return null;
        }

        console.debug(`[${this.constructor.name}] Found ${options.length} options`, options);
        const props = this.props;
        const getItemKey = this.defaultGetItemKeyCallback();
        const getOptionValueCallback = props.getOptionValue || this.defaultGetOptionValueCallback();
        const getOptionNameCallback = props.getOptionName || this.defaultGetOptionNameCallback();

        const placeholderText = typeof props.placeholderText !== 'undefined' ? props.placeholderText : '';
        const placeholderOption = <option value={defaultSelectValue}
                                          defaultChecked={true}
                                          disabled={true}>{placeholderText}</option>;

        const itemRenderer = props.itemRenderer;
        if (itemRenderer) {
            return <>
                {placeholderOption}
                {options.map(item => <option key={getItemKey(item)}
                                             value={getOptionValueCallback(item)}>{getOptionNameCallback(item)}</option>)}
            </>;
        } else {
            return <>
                {placeholderOption}
                {options.map(item => <option key={getItemKey(item)}
                                             value={getOptionValueCallback(item)}>{getOptionNameCallback(item)}</option>)}
            </>;
        }
    }

    protected getStateStringValueForItem(item: T): string {
        return this.getOptionValue(item);
    }

    private isSelectedValueValid(stringValue: string | undefined, options: T[] | undefined): boolean {
        if (!options || typeof options.find !== 'function') {
            return false;
        }
        return undefined !== options.find(option => {
            return this.getOptionValue(option) === stringValue;
        });
    }
}
