import * as React from 'react';
import { Addition } from '../../../../../Model/Catalog/Lenses/Addition';
import { Lenses } from '../../../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { Refraction } from '../../../../../Model/Refraction';
import { FindAdditionQueryParameters } from '../../../../../Query/FindAdditionQueryParameters';
import { OnSelectHandler, Suggest } from '../../../../Form/Suggest';
import { SideEnum } from '../../../SideEnum';
import { AbstractProductDependantRow } from './AbstractProductDependantRow';
import { ResultantPrismArgument } from './AbstractRow';

type Coating = Addition;

export class CoatingRow extends AbstractProductDependantRow<Coating> {
    protected renderHeader(): JSX.Element {
        return <h4>Vergütung,<br/>Entspiegelung</h4>;
    }

    protected renderRightColumn(): JSX.Element {
        const props = this.props;
        const refraction = this.getMergedRightRefraction();
        if (!refraction) {
            return this.renderPlaceholderField('Keine Refraktion');
        }

        const resultantPrism = this.detectHigherResultantPrism();
        const variant = props.variant;
        if (variant.rightProduct && variant.rightLenses) {
            return this.renderInput(
                props.onChangeRight,
                variant.rightCoating,
                variant.rightProduct,
                refraction,
                resultantPrism,
                variant.rightLenses
            );
        } else {
            return this.renderPlaceholderField();
        }
    }

    protected renderLeftColumn(): JSX.Element {
        const props = this.props;
        const variant = props.variant;
        if (variant.symmetrical) {
            const value = variant.rightCoating ? this.suggestOptionName(variant.rightCoating) : '';

            return this.renderDisabledValue(value);
        }

        const refraction = this.getRefraction(SideEnum.Left);
        if (!refraction) {
            return this.renderPlaceholderField('Keine Refraktion');
        }

        // If the Variant is not symmetrical check if the Product has been selected
        if (variant.leftProduct && variant.leftLenses) {
            return this.renderInput(
                props.onChangeLeft,
                variant.leftCoating,
                variant.leftProduct,
                refraction,
                refraction.resultantPrism,
                variant.leftLenses
            );
        } else {
            return this.renderPlaceholderField();
        }
    }

    protected renderInput(
        onSelect: OnSelectHandler<Coating>,
        value: Coating | undefined,
        product: Product,
        refraction: Refraction,
        resultantPrism: ResultantPrismArgument,
        lenses: Lenses
    ): JSX.Element {
        console.debug(`[CoatingRow] Render input`, value);
        const additionalQueryParameters: FindAdditionQueryParameters = {
            refraction,
            isCoatingOrHs: true,
            lensesUid: lenses.uid,
            resultantPrism
        };
        if (product) {
            additionalQueryParameters.additionMaterial = product.additionMaterial;
        }

        return <Suggest
            className="suggest lenses-suggest lenses-coating-suggest"
            value={value}
            onSelect={onSelect}
            getOptionName={this.suggestOptionName as any}
            additionalQueryParameters={additionalQueryParameters}
            repository={this.getRepository() as any}/>;
    }
}
