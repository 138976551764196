import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { AbstractCatalogModel } from './AbstractCatalogModel';
import { CatalogModelInterface } from './CatalogModelInterface';

@ra(ClassTypeOptions.AddUnknownFields)
export class Collection extends AbstractCatalogModel implements CatalogModelInterface {
    @ra_property()
    public name: string;

    @ra_property()
    public price: number;
}
