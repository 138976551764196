import * as React from 'react';
import { Offer } from '../../../../Model/Offer/Offer';
import { Currency } from '../../../Formatter/Currency';
import { VariantViewProps } from '../VariantViewProps';

interface ViewProps extends VariantViewProps {
    offer: Offer
}

export class LensesDeterminationRow extends React.Component<ViewProps> {
    public render() {
        const price = this.props.offer.lensesDeterminationPrice;

        if (price) {
            return <tr>
                <td className="caption">Brillenglasbestimmung</td>
                <td className="value">
                    <Currency value={price} trailingZeros={true}/>
                </td>
            </tr>;
        } else {
            return null;
        }
    }
}
