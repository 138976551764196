import * as React from 'react';
import { CurrencyFormatter } from '../../../Formatter/CurrencyFormatter';
import { LensesDeterminationOption } from '../../../Model/Offer/Variant/LensesDeterminationOption';
import { AbstractAEElement, AEProps } from './AbstractAEElement';

const defaultSelectValue = 0.0;

export class LensesDetermination extends AbstractAEElement<number> {
    constructor(props: AEProps<number>, context: any) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
    }

    protected renderHeader(): JSX.Element {
        return <div title="Brillenglasbestimmung">Brillenglasbestimmung</div>;
    }

    protected renderInput() {
        return <div className="general-select">
            <label className="general-select-inner select-control">
                <select value={this.getValue()} onChange={this.handleChange}>
                    <option value={defaultSelectValue}/>
                    {this.renderOptions()}
                </select>
            </label>
        </div>;
    }

    protected renderOptions() {
        const formatter = new CurrencyFormatter();

        return LensesDeterminationOption.getOptions().map(
            o => <option key={o} value={o}>CHF {formatter.format(o, false)}</option>
        );
    }

    protected handleChange(event: React.FormEvent<HTMLSelectElement>) {
        const value = (event.target as any).value;
        if (value !== '') {
            this.props.onChange(parseFloat(value), event);
        }
    }

    protected getValue(): string | number {
        const price = this.props.offer.lensesDeterminationPrice;

        return price !== undefined && price !== null ? price : defaultSelectValue;
    }
}
