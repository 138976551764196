import { Offer } from '../Model/Offer/Offer';
import { AbstractStorage } from './AbstractStorage';

export class OfferStorage extends AbstractStorage<Offer> {
    protected getNamespace(): string {
        return 'spectacles-offer';
    }

    protected getType(): { new(...args: any[]): Offer } {
        return Offer;
    }
}
