export enum PriceModel {
    lifetime = 'lifetime',
    economy = 'economy',
}

const label = (p: PriceModel) => {
    if (p === PriceModel.lifetime) {
        return 'Lifetime';
    }
    if (p === PriceModel.economy) {
        return 'Economy';
    }
    throw new RangeError();
};
export { label };
