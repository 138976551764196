import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { CurrencyFormatter } from '../../../Formatter/CurrencyFormatter';
import { PriceModel } from '../../../Model/Offer/PriceModel';
import { Service } from '../../../Model/Service';
import { AdditionalQueryParameters } from '../../Form/AjaxFormField/AdditionalQueryParameters';
import { AjaxSelect } from '../../Form/AjaxSelect';
import { AbstractAEElement, AEProps } from './AbstractAEElement';

interface ViewState {
    options: Service[]
}

interface FindReworkingQueryParameters extends AdditionalQueryParameters {
    group?: number | undefined
    material?: string | undefined
    workshopService?: boolean | undefined
}

// @PROJECT_VALUE
enum Group {
    economy = 1728,
    lifetime = 1056,
}

export class Reworking extends AbstractAEElement<Service, AEProps<Service>, ViewState> {
    public static contextType = AppContextType;
    public context: AppContext;

    private alwaysShowTitle = true;

    constructor(props: AEProps<Service>, context: any) {
        super(props, context);
        this.handleSelect = this.handleSelect.bind(this);
        this.getOptionName = this.getOptionName.bind(this);
    }

    protected renderHeader(): JSX.Element {
        return <div>
            Atelier, Einschleif-<br/>
            arbeiten pro Seite
        </div>;
    }

    protected renderInput() {
        const group = this.getGroupForPriceModel();
        const material = this.getMaterial();
        const additionalQueryParameters: FindReworkingQueryParameters = {
            workshopService: true
        };
        if (group) {
            additionalQueryParameters.group = group;
        }
        if (material) {
            // additionalQueryParameters.material = material;
        }

        return <AjaxSelect
            onSelect={this.handleSelect as any}
            additionalQueryParameters={additionalQueryParameters}
            value={this.props.offer.reworking}
            repository={this.context.serviceLocator.get('serviceRepository')}
            getOptionName={this.getOptionName as any}
        />;
    }

    private handleSelect(item: Service, event: SyntheticEvent) {
        this.props.onChange(item, event);
    }

    private getOptionName(item: Service): string {
        const formatter = new CurrencyFormatter();
        const offer = this.context.offer;
        const priceModel = offer.priceModel;
        const hasFrame = offer.frame !== undefined;

        let name = 'CHF ' + formatter.format(item.price, false);
        if (typeof priceModel === 'undefined') {
            if (item.group === Group.lifetime) {
                name += ' / Lifetime';
            } else if (item.group === Group.economy) {
                name += ' / Economy';
            }
        }

        if (hasFrame && !this.alwaysShowTitle) {
            return name;
        } else {
            return name + ' / ' + item.name;
        }
    }

    private getGroupForPriceModel(): number | undefined {
        const priceModel = this.context.offer.priceModel;
        if (priceModel === PriceModel.economy) {
            return Group.economy;
        } else if (priceModel === PriceModel.lifetime) {
            return Group.lifetime;
        } else {
            return undefined;
        }
    }

    private getMaterial() {
        const frame = this.context.offer.frame;
        if (!frame) {
            return undefined;
        }

        const model = frame.model;
        if (model && model.material) {
            return model.material.name;
        } else {
            console.warn('Frame model or the model\'s material is not set');

            return undefined;
        }
    }
}
