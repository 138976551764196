import { Assert } from 'iresults-ts-core';
import { Result as BaseResult } from '../../Service/Result';
import { EconomyOption } from '../EconomyOption';
import { AggregateInterface } from './AggregateInterface';

interface StaticOptionData {
    name: string;
    description?: string;
}

/**
 * Result of loading Services from the API with methods to prepare the Economy Options
 */
export class Result extends BaseResult<EconomyOption> implements AggregateInterface {
    /**
     * Return the list of service related Economy Options
     *
     * @return {EconomyOption[]}
     */
    public getServiceOptions(): EconomyOption[] {
        // @PROJECT_VALUE
        return [
            this.buildEconomyOption(
                40617,
                'Bügel-Kürzung',
                'Bügel-Kürzung ohne kitten (pro Paar)'
            ),
            this.buildEconomyOption(
                40618,
                'Bügel-Kürzung',
                'Bügel-Kürzung inklusive kitten (pro Paar)'
            ),
            this.buildEconomyOption(
                40625,
                'Brillenabgabe'
            ),
            this.buildEconomyOption(
                40683,
                'Dynoptic Service Plus'
            )
        ].filter(o => !!o) as EconomyOption[];
    }

    /**
     * Return the list of extra material related Economy Options
     *
     * @return {EconomyOption[]}
     */
    public getExtraMaterialOptions(): EconomyOption[] {
        // @PROJECT_VALUE
        return [
            this.buildEconomyOption(
                40616,
                'Bügelenden'
            ),
            this.buildEconomyOption(
                40619,
                'Pads / Nasenauflage'
            ),
            this.buildEconomyOption(
                40620,
                'Nylorfaden / Lippe'
            ),

        ].filter(o => !!o) as EconomyOption[];
    }

    /**
     * Fetch the Economy Option with the given UID
     *
     * @param {number} optionUid
     * @return {EconomyOption | undefined}
     */
    public getEconomyOption(optionUid: number): EconomyOption | undefined {
        const data = this.getLabelsForEconomyOption(optionUid);

        return this.buildEconomyOption(optionUid, data.name, data.description);
    }

    /**
     * Return the fixed/static data for the Economy Options
     *
     * @param {number} uid
     * @return {StaticOptionData}
     */
    private getLabelsForEconomyOption(uid: number): StaticOptionData {
        // @PROJECT_VALUE
        Assert.isNumber(uid);
        const allData = {
            40616: {
                name: 'Bügelenden'
            },
            40619: {
                name: 'Pads / Nasenauflage'
            },
            40620: {
                name: 'Nylorfaden / Lippe'
            },
            40618: {
                name: 'Bügel-Kürzung',
                description: 'Bügel-Kürzung inklusive kitten (pro Paar)'
            },
            40625: {
                name: 'Brillenabgabe'
            },
        };

        return allData[uid];
    }

    private buildEconomyOption(
        optionUid: string | number,
        name: string,
        description?: string
    ): EconomyOption | undefined {
        const service = this.getService(optionUid);
        if (!service) {
            console.warn(`Service ${optionUid} for "${description || name}" could not be found`);

            return undefined;
        }

        const price = !Assert.isNumber(service.price) ? parseFloat(service.price) : service.price;

        return new EconomyOption(
            service.uid,
            price,
            name,
            description
        );
    }
}
