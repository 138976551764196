export class WindowHeightHelper {
    public run() {
        this.update();
        window.addEventListener('resize', () => {
            this.update();
        });
    }

    private update() {
        const vh = window.innerHeight;
        document.documentElement.style.setProperty('--viewport-height', `${vh}px`);
    }
}
