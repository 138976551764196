import * as React from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { Customer } from '../../Model/Customer/Customer';
import { UpperSectionStyle } from '../../Model/Settings/UpperSectionStyle';
import { DetailWidget as FrameDetailWidget } from '../Catalog/DetailWidget';
import { FrameSelectorView } from '../Catalog/FrameSelectorView';
import { CustomerSelectorView } from '../Customer/CustomerSelectorView';
import { DetailWidget as CustomerDetailWidget } from '../Customer/DetailWidget';

export class UpperSection extends React.Component {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: {}, context: any) {
        super(props, context);
        this.toggleUpperSectionStyle = this.toggleUpperSectionStyle.bind(this);
    }

    public render() {
        const handler = this.context.handler;
        const offer = handler.state.offer;
        const customer = offer.customer;
        const frame = offer.frame;
        const settings = handler.settings;
        const outerClassName = 'offer-new-section offer-new-upper-section '
            + (customer ? '-filled' : (!frame ? '-empty' : ''));

        return (
            <section className={outerClassName}>
                <div className="container offer-new-section-inner">
                    <div className="grid-9">
                        {frame
                            ? <FrameDetailWidget settings={settings} frame={frame}/>
                            : <FrameSelectorView/>}
                    </div>
                    <div className="grid-3">
                        {customer
                            ? <CustomerDetailWidget settings={settings} customer={customer}/>
                            : <CustomerSelectorView/>}
                    </div>
                </div>
                {this.renderToggleUpperSectionStyleButton(customer)}
            </section>
        );
    }

    private renderToggleUpperSectionStyleButton(customer: Customer | undefined) {
        if (customer) {
            const text = this.isCollapsed()
                ? <i className="material-icons">expand_more</i>
                : <i className="material-icons">expand_less</i>
            ;

            return <a role="button"
                      className="toggle-upper-section-style icon-button"
                      href="#"
                      onClick={this.toggleUpperSectionStyle}>{text}</a>;
        } else {
            return null;
        }
    }

    private toggleUpperSectionStyle() {
        const settings = this.context.handler.settings;

        this.context.handler.setSettings(
            settings.withUpperSectionStyle(
                this.isCollapsed()
                    ? UpperSectionStyle.DETAILED
                    : UpperSectionStyle.COLLAPSED
            )
        );
    }

    private isCollapsed(): boolean {
        return this.context.handler.settings.upperSectionStyle === UpperSectionStyle.COLLAPSED;
    }
}
