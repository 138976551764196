import { Lenses } from '../../../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { DomainObject } from '../../../../../Model/DomainObject';
import { Refraction } from '../../../../../Model/Refraction';
import { ResultantPrism } from '../../../../../ValueObject/ResultantPrism';
import { OnSelectHandler } from '../../../../Form/Suggest';
import { AbstractRow } from './AbstractRow';
import { FormRowProps } from './FormRowProps';

export abstract class AbstractProductDependantRow<T extends DomainObject,
    P extends FormRowProps<T> = FormRowProps<T>,
    S = {}>
    extends AbstractRow<T, P, S> {
    protected abstract renderInput(
        onSelect: OnSelectHandler<T>,
        value: T | undefined,
        product: Product,
        refraction: Refraction,
        resultantPrism: ResultantPrism,
        lenses: Lenses | undefined
    ): JSX.Element;
}
