import * as React from 'react';
import { Config } from './Config';
import { MainHandler } from './MainHandler';
import { Offer } from './Model/Offer/Offer';
import { Settings } from './Model/Settings/Settings';
import { User } from './Model/User';
import { ServiceLocator } from './ServiceLocator';

export class AppContext {
    public readonly serviceLocator: ServiceLocator;
    public readonly handler: MainHandler;
    public readonly config: Config;

    constructor(serviceLocator: ServiceLocator, handler: MainHandler, config?: Config) {
        this.serviceLocator = serviceLocator;
        this.handler = handler;
        this.config = config || new Config();
    }

    get offer(): Offer {
        return this.handler.offer;
    }

    get login(): User | undefined {
        return this.offer.login;
    }

    get settings(): Settings {
        return this.handler.settings;
    }

    get basePath(): string {
        return this.config.basePath;
    }
}

export const AppContextType = React.createContext((null as any) as AppContext);
