import { Repository } from '@cundd/rest-adapter';
import * as Sentry from '@sentry/browser';
import { AppContainer } from 'action-handler';
import { App, AppProps } from './App';
import { Config } from './Config';
import { DevHelper } from './DevHelper';
import { MainHandler } from './MainHandler';
import { MainHandlerHelper } from './MainHandlerHelper';
import { Provider } from './Model/Catalog/EconomyOption/Provider';
import { Frame } from './Model/Catalog/Frame';
import { Addition } from './Model/Catalog/Lenses/Addition';
import { Lenses } from './Model/Catalog/Lenses/Lenses';
import { Product } from './Model/Catalog/Lenses/Product';
import { Customer } from './Model/Customer/Customer';
import { Favourite } from './Model/Offer/Favourite';
import { FavouriteGroup } from './Model/Offer/FavouriteGroup';
import { FavouriteGroupCollection } from './Model/Offer/FavouriteGroupCollection';
import { Offer } from './Model/Offer/Offer';
import { Variant } from './Model/Offer/Variant';
import { VariantResult } from './Model/Offer/VariantResult';
import { Service } from './Model/Service';
import { User } from './Model/User';
import { unregister } from './registerServiceWorker';
import { AuthenticationService } from './Service/AuthenticationService';
import { BrowserStorage } from './Service/BrowserStorage';
import { CustomerOfferService } from './Service/CustomerOfferService';
import { ExportOffer } from './Service/Export/ExportOffer';
import { ExportService } from './Service/ExportService';
import { FavouriteGroupStorage } from './Service/FavouriteGroupStorage';
import { FavouriteService } from './Service/FavouriteService';
import { LatestOfferStorage } from './Service/LatestOfferStorage';
import { OfferStorage } from './Service/OfferStorage';
import { PrintService } from './Service/PrintService';
import { PrismService } from './Service/PrismService';
import { RestAdapterFactory } from './Service/RestAdapterFactory';
import { AsyncBrowserStorageAdapter } from './Service/Storage/AsyncBrowserStorageAdapter';
import { ServerStorageAdapter } from './Service/Storage/ServerStorageAdapter';
import { SpecialAdditionService } from './Service/Variant/SpecialAdditionService';
import { VariantBuilder } from './Service/VariantBuilder';
import { WindowHeightHelper } from './Service/WindowHeightHelper';
import { ServiceLocator } from './ServiceLocator';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: 'https://93bbabb8c6b74a1a975b2245e3dd64c8@o46841.ingest.sentry.io/5245920'});
}

const serviceLocator = new ServiceLocator();
serviceLocator.registerMultiple(
    {
        restAdapterFactory: () => {
            return new RestAdapterFactory(serviceLocator, serviceLocator.get('authenticationService'));
        },
        adapter: () => {
            const factory: RestAdapterFactory = serviceLocator.get('restAdapterFactory');

            return factory.get();
        },
        userRepository: () => {
            return new Repository(User, 'users', serviceLocator.get('adapter'));
        },
        customerRepository: () => {
            return new Repository(Customer, 'customer/customer', serviceLocator.get('adapter'));
        },
        frameRepository: () => {
            return new Repository(Frame, 'catalog/frame', serviceLocator.get('adapter'));
        },
        lensesProductRepository: () => {
            return new Repository(Product, 'catalog/lenses/product', serviceLocator.get('adapter'));
        },
        lensesLensesRepository: () => {
            return new Repository(Lenses, 'catalog/lenses/lenses', serviceLocator.get('adapter'));
        },
        lensesAdditionRepository: () => {
            return new Repository(Addition, 'catalog/lenses/addition', serviceLocator.get('adapter'));
        },
        serviceRepository: () => {
            return new Repository(Service, 'service', serviceLocator.get('adapter'));
        },
        favouriteRepository: () => {
            return new Repository(Favourite, 'documents/favourite', serviceLocator.get('adapter'));
        },
        favouriteGroupRepository: () => {
            return new Repository(FavouriteGroup, 'documents/favourite', serviceLocator.get('adapter'));
        },
        variantRepository: () => {
            return new Repository(Variant, 'offer/variant', serviceLocator.get('adapter'));
        },
        variantResultRepository: () => {
            return new Repository(VariantResult, 'offer/variant', serviceLocator.get('adapter'));
        },
        exportOfferRepository: () => {
            return new Repository(ExportOffer, 'spectacles/import', serviceLocator.get('adapter'));
        },
        offerLogRepository: () => {
            // Repository to track changes of Offers
            return new Repository(Offer, 'documents/offer', serviceLocator.get('adapter'));
        },
        economyOptionProvider: () => {
            return new Provider(serviceLocator);
        },
        handler: () => {
            return new MainHandler(serviceLocator);
        },
        abortController: () => {
            return new AbortController();
        },
        offerStorage: () => {
            // Alternatively use a RWSplitStorageAdapter:
            // const storageAdapter = new RWSplitStorageAdapter(
            //     new AsyncBrowserStorageAdapter(),
            //     new ServerStorageAdapter(serviceLocator.get('offerLogRepository')),
            //     true
            // );
            return new OfferStorage(new ServerStorageAdapter(serviceLocator.get('offerLogRepository')));
        },
        latestOfferStorage: () => {
            return new LatestOfferStorage(new AsyncBrowserStorageAdapter());
        },
        favouriteGroupStorage: () => {
            const r = new Repository(FavouriteGroupCollection, 'documents/favourite', serviceLocator.get('adapter'));

            return new FavouriteGroupStorage(new ServerStorageAdapter(r));
        },
        favouriteService: () => {
            return new FavouriteService(
                serviceLocator.get('variantResultRepository'),
                serviceLocator.get('favouriteGroupStorage')
            );
        },
        authenticationService: () => {
            return new AuthenticationService();
        },
        printService: () => {
            const restAdapterFactory: RestAdapterFactory = serviceLocator.get('restAdapterFactory');

            return new PrintService(restAdapterFactory.buildAdapterConfiguration());
        },
        exportService: () => {
            const restAdapterFactory: RestAdapterFactory = serviceLocator.get('restAdapterFactory');

            return new ExportService(restAdapterFactory.buildAdapterConfiguration());
        },
        customerOfferService: () => {
            return new CustomerOfferService(serviceLocator.get('exportOfferRepository'));
        },
        browserStorage: () => {
            return new BrowserStorage();
        },
        prismService: () => {
            return new PrismService();
        },
        variantBuilder: () => {
            return new VariantBuilder(serviceLocator.get('prismService'));
        },
        specialAdditionService: () => {
            return new SpecialAdditionService(
                serviceLocator.get('prismService'),
                serviceLocator.get('lensesAdditionRepository')
            );
        }
    }
);

const windowHeightHelper = new WindowHeightHelper();
windowHeightHelper.run();

const authenticationService: AuthenticationService = serviceLocator.get('authenticationService');
authenticationService.authenticate()
    .then(() => {
        const handler: MainHandler = serviceLocator.get('handler');
        const props: AppProps = {
            serviceLocator,
            handler
        };

        const appContainer = new AppContainer(handler);
        appContainer.render(App, document.getElementById('root') as HTMLElement, props);

        unregister();

        const handlerHelper = new MainHandlerHelper(handler, serviceLocator);
        const devHelper = new DevHelper(handlerHelper);

        window['Spectacles'] = {
            appContainer,
            serviceLocator,
            handlerHelper,
            devHelper,
        };

        const config = new Config();
        if (config.isDevelopmentEnv) {
            devHelper.initDev();
        }
    })
    .catch(() => {
        console.error('Access denied');
        // window.alert('Access denied');
    });
