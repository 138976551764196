import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext, AppContextType } from '../../AppContextType';
import { Customer } from '../../Model/Customer/Customer';
import { RefractionPair } from '../../Model/Customer/RefractionPair';
import { Popup, PopupStyle } from '../Popup';
import { Item } from './Refraction/Item';

export interface RefractionSelectPopupProps {
    customer: Customer
    onDismiss: () => void,
}

interface RefractionSelectPopupState {
    selected: RefractionPair
}

export class RefractionSelectPopup extends React.Component<RefractionSelectPopupProps, RefractionSelectPopupState> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: RefractionSelectPopupProps, context: AppContext) {
        super(props, context);
        this.close = this.close.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        // Throw if no Refraction Pairs could be found
        this.getRefractionPairs();

        const selected = context.offer.refractionPair;
        if (!selected) {
            throw new ReferenceError('Offer has no Refraction Pair defined');
        }

        console.debug(`[RefractionSelectPopup] Selected Refraction Pair ${selected.uid}`, selected);
        this.state = {selected};
    }

    public render() {
        const selected = this.state.selected;
        const refractionCanBeChanged = this.getRefractionCanBeChanged();
        const className = 'customer-refraction-pair-overview-container'
            + (refractionCanBeChanged ? '' : ' -locked');

        return <Popup style={PopupStyle.Fullscreen} onDismiss={this.close}>
            <div className={className}>
                <table>
                    {this.getRefractionPairs().map(
                        rp => <Item key={rp.uid}
                                    refractionPair={rp}
                                    canBeSelected={refractionCanBeChanged}
                                    isSelected={selected.equals(rp)}
                                    onClick={this.handleSelect}/>
                    )}
                </table>
            </div>
        </Popup>;
    }

    private getRefractionPairs(): RefractionPair[] {
        const customer = this.props.customer;
        const refractionPairs = customer.refractionPairs;
        if (refractionPairs && refractionPairs.length > 0) {
            return refractionPairs;
        }
        if (customer.refractionPair) {
            return [customer.refractionPair];
        }
        throw new ReferenceError('Customer has no Refraction Pairs');
    }

    private close() {
        console.debug(`[RefractionSelectPopup] Close Refraction Pair Popup`);
        this.props.onDismiss();
    }

    private handleSelect(refractionPair: RefractionPair, _event: SyntheticEvent) {
        console.debug(`[RefractionSelectPopup] Handle select Refraction Pair`, refractionPair);
        this.setState(() => ({selected: refractionPair}));

        this.context.handler.handleRefractionPairChange(refractionPair);
    }

    private getRefractionCanBeChanged(): boolean {
        return this.context.offer.variants.length === 0;
    }
}
