import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Offer } from '../../../Model/Offer/Offer';

export interface AEProps<T> {
    offer: Offer
    onChange: (value: T, event: SyntheticEvent) => void
}

export abstract class AbstractAEElement<T, P extends AEProps<T> = AEProps<T>, S = {}> extends React.Component<P, S> {
    public render() {
        return (
            <div className="ae-element">
                <header className="ae-label grid-6">{this.renderHeader()}</header>
                <div className="ae-control grid-6">{this.renderInput()}</div>
            </div>
        );
    }

    protected abstract renderHeader(): JSX.Element;

    protected abstract renderInput(): JSX.Element;
}
