import { Repository } from '@cundd/rest-adapter';
import { AjaxFetch } from '../../Components/Form/AjaxFormField/AjaxFetch';
import { unwrapNotNull } from '../../Helper/unwrap';
import { Addition } from '../../Model/Catalog/Lenses/Addition';
import { EyeConfiguration } from '../../Model/Offer/Variant/EyeConfiguration';
import { Refraction } from '../../Model/Refraction';
import { FindAdditionQueryParameters } from '../../Query/FindAdditionQueryParameters';
import { PrismService } from '../PrismService';

export class SpecialAdditionService {
    public static get PRISM_ADDITION_CODE() {return 'IPRISM';}

    // noinspection JSUnusedGlobalSymbols
    public static get CYLINDER_ADDITION_CODE() {return 'ICYLIN';}

    private readonly prismService: PrismService;
    private readonly lensesAdditionRepository: Repository<Addition>;

    constructor(prismService: PrismService, lensesAdditionRepository: Repository<Addition>) {
        this.lensesAdditionRepository = lensesAdditionRepository;
        this.prismService = prismService;
    }

    /**
     * Load the special Prism Addition if necessary
     *
     * If the Variant's Refraction Pair has a Prism, automatically add the Prism Addition
     *
     * @param refraction
     * @param eyeConfiguration
     */
    public async loadPrismAddition(
        refraction: Refraction,
        eyeConfiguration: EyeConfiguration,
    ): Promise<Addition | undefined> {
        if (this.shouldAddPrismAddition(refraction, eyeConfiguration)) {
            return this.fetchPrismAddition(refraction, eyeConfiguration);
        } else {
            return undefined;
        }
    }

    /**
     * Return if the special Prism Addition should be added to the Eye Configuration
     *
     * @param refraction
     * @param eyeConfiguration
     * @return boolean
     */
    public shouldAddPrismAddition(refraction: Refraction, eyeConfiguration: EyeConfiguration,): boolean {
        return this.prismService.hasPrism(refraction) && !this.eyeConfigurationHasPrismAddition(eyeConfiguration);
    }

    private eyeConfigurationHasPrismAddition(eyeConfiguration: EyeConfiguration): boolean {
        if (!eyeConfiguration.miscellaneous || 0 === eyeConfiguration.miscellaneous.length) {
            return false;
        }

        return undefined !== eyeConfiguration.miscellaneous.find(m => m.code === SpecialAdditionService.PRISM_ADDITION_CODE);
    }

    private async fetchPrismAddition(
        refraction: Refraction,
        eyeConfiguration: EyeConfiguration,
    ): Promise<Addition | undefined> {
        return this.suggestFetch(eyeConfiguration, refraction, 'Prismazuschlag');
    }

    private async suggestFetch(
        eyeConfiguration: EyeConfiguration,
        refraction: Refraction,
        searchTerm: string,
    ): Promise<Addition | undefined> {
        console.log(eyeConfiguration);
        const resultantPrism = this.prismService.calculateResultantPrism(refraction);
        const lenses = unwrapNotNull(eyeConfiguration.lenses);
        const product = lenses.product;

        const additionalQueryParameters: FindAdditionQueryParameters = {
            resultantPrism,
            refraction,
            lensesUid: lenses.uid,
            additionMaterial: product.additionMaterial,
        };

        const suggestFetch = new AjaxFetch(this.lensesAdditionRepository, searchTerm, additionalQueryParameters);

        return suggestFetch.run().then(r => r ? r[0] : undefined);
    }
}
