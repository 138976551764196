import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext, AppContextType } from '../../../AppContextType';
import { Addition } from '../../../Model/Catalog/Lenses/Addition';
import { Lenses } from '../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../Model/Catalog/Lenses/Product';
import { RefractionPair } from '../../../Model/Customer/RefractionPair';
import { Variant } from '../../../Model/Offer/Variant';
import { EyeConfiguration } from '../../../Model/Offer/Variant/EyeConfiguration';
import { CollectionService } from '../../../Service/CollectionService';
import { SpecialAdditionService } from '../../../Service/Variant/SpecialAdditionService';
import { VariantSymmetricalService } from '../../../Service/VariantSymmetricalService';
import { descriptionForSide, SideEnum } from '../SideEnum';
import { CoatingRow } from './Form/Eye/CoatingRow';
import { LensesRow } from './Form/Eye/LensesRow';
import { MiscellaneousRow } from './Form/Eye/MiscellaneousRow';
import { ProductRow } from './Form/Eye/ProductRow';
import { Footer } from './Form/Footer';
import { DiscountRow } from './Form/General/DiscountRow';

export interface VariantFormViewProp {
    variantNumber: number | string,
    variant: Variant,
    refractionPair: RefractionPair | undefined
    onClickSave?: (variant: Variant, event: SyntheticEvent) => void
}

export interface VariantFormViewState {
    variant: Variant,
    symmetrical: boolean,
}

export abstract class AbstractVariantForm<P extends VariantFormViewProp = VariantFormViewProp,
    S extends VariantFormViewState = VariantFormViewState>
    extends React.Component<P, S> {
    public static contextType = AppContextType;
    public context: AppContext;
    private readonly variantSymmetricalService: VariantSymmetricalService;
    private readonly specialAdditionService: SpecialAdditionService;

    constructor(props: P, context: AppContext) {
        super(props, context);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickSymmetrical = this.handleClickSymmetrical.bind(this);

        this.handleLensesProductChangeRight = this.handleLensesProductChangeRight.bind(this);
        this.handleCoatingChangeRight = this.handleCoatingChangeRight.bind(this);
        this.handleLensesMiscellaneousAddRight = this.handleLensesMiscellaneousAddRight.bind(this);
        this.handleLensesMiscellaneousRemoveRight = this.handleLensesMiscellaneousRemoveRight.bind(this);
        this.handleLensesLensesChangeRight = this.handleLensesLensesChangeRight.bind(this);

        this.handleLensesProductChangeLeft = this.handleLensesProductChangeLeft.bind(this);
        this.handleCoatingChangeLeft = this.handleCoatingChangeLeft.bind(this);
        this.handleLensesMiscellaneousAddLeft = this.handleLensesMiscellaneousAddLeft.bind(this);
        this.handleLensesMiscellaneousRemoveLeft = this.handleLensesMiscellaneousRemoveLeft.bind(this);
        this.handleLensesLensesChangeLeft = this.handleLensesLensesChangeLeft.bind(this);

        this.handleDiscountChange = this.handleDiscountChange.bind(this);

        this.variantSymmetricalService = new VariantSymmetricalService();
        this.specialAdditionService = context.serviceLocator.get('specialAdditionService');

        const variant = props.variant;
        this.state = {
            variant,
            symmetrical: variant.symmetrical
        } as S;
    }

    public render() {
        const variant = this.state.variant;

        return (
            <div className={this.getContainerClassName()}>
                <div className="offset-1">
                    {this.renderHeader()}
                </div>
                <section className="lenses-configuration lenses-configuration-eye">
                    <header className="row-container lenses-configuration-row">
                        <div className="grid-4 offset-4">
                            <h3>{descriptionForSide(SideEnum.Right)}</h3>
                        </div>
                        <div className="grid-4">
                            <h3>{descriptionForSide(SideEnum.Left)}</h3>
                            {this.renderSymmetricalButton()}
                        </div>
                    </header>
                    {this.renderEyeRows(variant)}
                </section>
                <section className="lenses-configuration lenses-configuration-general">
                    {this.renderGeneralRows(variant)}
                </section>

                <Footer variant={variant} onSaveClick={this.handleClickSave}/>
            </div>
        );
    }

    public componentDidUpdate(prevProps: P) {
        console.debug(`[${this.constructor.name}] Component did update `, prevProps, this.props);
    }

    protected abstract handleClickSave(event: SyntheticEvent): void;

    protected abstract renderHeader(): JSX.Element;

    protected abstract getContainerClassName(): string;

    protected renderEyeRows(variant: Variant) {
        const refractionPair = this.props.refractionPair;

        return <>
            <ProductRow variant={variant}
                        refractionPair={refractionPair}
                        onChangeRight={this.handleLensesProductChangeRight}
                        onChangeLeft={this.handleLensesProductChangeLeft}/>
            <LensesRow variant={variant}
                       refractionPair={refractionPair}
                       onChangeRight={this.handleLensesLensesChangeRight}
                       onChangeLeft={this.handleLensesLensesChangeLeft}/>
            <CoatingRow variant={variant}
                        refractionPair={refractionPair}
                        onChangeRight={this.handleCoatingChangeRight}
                        onChangeLeft={this.handleCoatingChangeLeft}/>
            <MiscellaneousRow variant={variant}
                              refractionPair={refractionPair}
                              onChangeRight={this.handleLensesMiscellaneousAddRight as any}
                              onAddRight={this.handleLensesMiscellaneousAddRight}
                              onRemoveRight={this.handleLensesMiscellaneousRemoveRight}
                              onChangeLeft={this.handleLensesMiscellaneousAddLeft as any}
                              onAddLeft={this.handleLensesMiscellaneousAddLeft}
                              onRemoveLeft={this.handleLensesMiscellaneousRemoveLeft}/>
        </>;
    }

    protected renderGeneralRows(variant: Variant) {
        return <>
            <DiscountRow variant={variant}
                         onChange={this.handleDiscountChange}/>
        </>;
    }

    protected renderSymmetricalButton() {
        if (this.state.symmetrical) {
            return (
                <button className="discreet icon-button lenses-configuration-symmetrical -disable"
                        onClick={this.handleClickSymmetrical}>
                    <i className="material-icons">link</i>
                </button>
            );

        } else {
            return (
                <button className="discreet icon-button lenses-configuration-symmetrical -enable"
                        onClick={this.handleClickSymmetrical}>
                    <i className="material-icons">link_off</i>
                </button>
            );
        }
    }

    protected handleLensesProductChangeRight(item: Product | undefined) {
        console.debug(`[${this.constructor.name}] Handle right Product change`, item);
        this.setState(prevState => {
            return {
                variant: prevState.variant
                    .withRightProduct(item)
                    .withRightLenses(undefined)
                    .withRightCoating(undefined)
                    .withRightMiscellaneous([])
            };
        });
    }

    protected handleLensesProductChangeLeft(item: Product | undefined) {
        console.debug(`[${this.constructor.name}] Handle left Product change`, item);
        this.setState(prevState => {
            return {
                variant: prevState.variant
                    .withLeftProduct(item)
                    .withLeftLenses(undefined)
                    .withLeftCoating(undefined)
                    .withLeftMiscellaneous([])
            };
        });
    }

    protected handleCoatingChangeRight(item: Addition | undefined) {
        console.debug(`[${this.constructor.name}] Handle right Coating change`, item);
        this.setState(prevState => {
            return {
                variant: prevState.variant.withRightCoating(item)
            };
        });
    }

    protected handleCoatingChangeLeft(item: Addition | undefined) {
        console.debug(`[${this.constructor.name}] Handle left Coating change`, item);
        this.setState(prevState => {
            return {
                variant: prevState.variant.withLeftCoating(item)
            };
        });
    }

    protected handleLensesMiscellaneousAddRight(item: Addition) {
        console.debug(`[${this.constructor.name}] Handle add right Lenses Miscellaneous`, item);
        this.setState(prevState => {
            const variant = prevState.variant;
            const misc = variant.rightMiscellaneous.slice();
            misc.push(item);

            return {
                variant: variant.withRightMiscellaneous(misc)
            };
        });
    }

    protected handleLensesMiscellaneousAddLeft(item: Addition) {
        console.debug(`[${this.constructor.name}] Handle add left Lenses Miscellaneous`, item);
        this.setState(prevState => {
            const variant = prevState.variant;
            const misc = variant.leftMiscellaneous.slice();
            misc.push(item);

            return {
                variant: variant.withLeftMiscellaneous(misc)
            };
        });
    }

    protected handleLensesMiscellaneousRemoveRight(item: Addition) {
        console.debug(`[${this.constructor.name}] Handle remove right Lenses Miscellaneous`, item);
        this.setState(prevState => {
            const variant = prevState.variant;
            const misc = CollectionService.removeItemFromCollection(item, variant.rightMiscellaneous);

            return {
                variant: variant.withRightMiscellaneous(misc)
            };
        });
    }

    protected handleLensesMiscellaneousRemoveLeft(item: Addition) {
        console.debug(`[${this.constructor.name}] Handle remove left Lenses Miscellaneous`, item);
        this.setState(prevState => {
            const variant = prevState.variant;
            const misc = CollectionService.removeItemFromCollection(item, variant.leftMiscellaneous);

            return {
                variant: prevState.variant.withLeftMiscellaneous(misc)
            };
        });
    }

    protected handleLensesLensesChangeRight(item: Lenses | undefined) {
        console.debug(`[${this.constructor.name}] Handle right Lenses change`, item);
        const refractionPair = (this.props as VariantFormViewProp).refractionPair;
        if (!refractionPair || !item) {
            return this.setRightLenses(item);
        }

        const specialAdditionService = this.specialAdditionService;
        const eyeConfiguration = (this.state.variant.right || new EyeConfiguration()).withLenses(item);
        const refraction = refractionPair.right;

        // Check if the special Prism Additions should be added to the Variant
        if (refraction && specialAdditionService.shouldAddPrismAddition(refraction, eyeConfiguration)) {
            specialAdditionService.loadPrismAddition(refraction, eyeConfiguration).then(addition => {
                if (addition) {
                    this.setState(prevState => {
                        return {variant: prevState.variant.withRightLenses(item).withAddedRightMiscellaneous(addition)};
                    });
                } else {
                    this.setRightLenses(item);
                }
            });
        } else {
            // No need to load the special Prism Addition from the server
            this.setRightLenses(item);
        }
    }

    protected setRightLenses(lenses: Lenses | undefined) {
        this.setState(prevState => {
            return {
                variant: prevState.variant.withRightLenses(lenses)
            };
        });
    }

    protected handleLensesLensesChangeLeft(item: Lenses | undefined) {
        console.debug(`[${this.constructor.name}] Handle left Lenses change`, item);
        const refractionPair = (this.props as VariantFormViewProp).refractionPair;

        if (!refractionPair || !item) {
            return this.setLeftLenses(item);
        }

        const specialAdditionService = this.specialAdditionService;
        const eyeConfiguration = (this.state.variant.left || new EyeConfiguration()).withLenses(item);
        const refraction = refractionPair.left;

        // Check if the special Prism Additions should be added to the Variant
        if (refraction && specialAdditionService.shouldAddPrismAddition(refraction, eyeConfiguration)) {
            specialAdditionService.loadPrismAddition(refraction, eyeConfiguration).then(addition => {
                if (addition) {
                    this.setState(prevState => {
                        return {variant: prevState.variant.withLeftLenses(item).withAddedLeftMiscellaneous(addition)};
                    });
                } else {
                    return this.setLeftLenses(item);
                }
            });
        } else {
            // No need to load the special Prism Addition from the server
            return this.setLeftLenses(item);
        }
    }

    protected setLeftLenses(lenses: Lenses | undefined) {
        this.setState(prevState => {
            return {
                variant: prevState.variant.withLeftLenses(lenses)
            };
        });
    }

    protected handleClickSymmetrical(event: SyntheticEvent) {
        console.debug(`[${this.constructor.name}] Toggle symmetrical`);
        event.preventDefault();

        if (this.state.variant.symmetrical) {
            this.makeAsymmetrical();
        } else {
            this.makeSymmetrical();
        }
    }

    protected handleDiscountChange(value: number) {
        console.debug(`[${this.constructor.name}] Handle Discount change`, value);
        this.setState(prevState => {
            return {
                variant: prevState.variant.withDiscount(value)
            };
        });
    }

    private makeSymmetrical() {
        this.setState(prevState => {
            const variant = prevState.variant;

            return {
                symmetrical: true,
                variant: variant.withSymmetrical(true)
            };
        });
    }

    private makeAsymmetrical() {
        this.setState(prevState => {
            const variant = prevState.variant;

            return {
                symmetrical: false,
                variant: this.variantSymmetricalService.buildAsymmetricalVariant(variant)
            };
        });
    }
}
