import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DomainObject } from '../../../Model/DomainObject';
import { AdditionalQueryParameters } from './AdditionalQueryParameters';
import { AjaxFetch } from './AjaxFetch';
import { AjaxFetchCancel } from './AjaxFetchCancel';
import { AjaxFormFieldProps } from './AjaxFormFieldProps';
import { AjaxFormFieldState } from './AjaxFormFieldState';
import { Filter } from './AjaxFormFieldTypes';

export abstract class AbstractAjaxFormField<T extends DomainObject,
    P extends AjaxFormFieldProps<T> = AjaxFormFieldProps<T>,
    S extends AjaxFormFieldState<T> = AjaxFormFieldState<T>>
    extends React.Component<P, S> {
    private lastFetch: AjaxFetch<T>;
    private debounceTimer: number;

    protected constructor(props: P, context: any) {
        super(props, context);
        this.handleChooseOption = this.handleChooseOption.bind(this);
        this.handleDidFetch = this.handleDidFetch.bind(this);
        this.handleFailedFetch = this.handleFailedFetch.bind(this);
        this.handleWillFetch = this.handleWillFetch.bind(this);

        this.state = {
            options: [] as T[],
            value: props.value,
            stringValue: props.value ? this.getStateStringValueForItem(props.value) : ''
        } as S;
    }

    public componentWillUnmount(): void {
        if (this.lastFetch) {
            this.lastFetch.cancel();
        }
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
    }

    /**
     * Render the collection of options
     *
     * @param {T[] | undefined} options
     * @return {JSX.Element | null}
     */
    protected abstract renderOptions(options: T[] | undefined): JSX.Element | null ;

    /**
     * Transform the selected item into the correct type for `state.stringValue`
     *
     * @param {T} item
     * @return {string}
     */
    protected abstract getStateStringValueForItem(item: T): string;

    protected fetchOptions(searchTerm: string, additionalQueryParameters: AdditionalQueryParameters | undefined) {
        const repository = this.props.repository;
        if (!repository) {
            console.warn(`[${this.constructor.name}] Repository is not set`);
            return;
        }
        this.debounceFetch(
            () => {
                // If a `fetch` is already running, mark it as obsolete
                if (this.lastFetch) {
                    console.debug(`[${this.constructor.name}] Cancel request #${this.lastFetch.requestId}`);
                    this.lastFetch.cancel();
                }

                this.handleWillFetch();

                const suggestFetch = new AjaxFetch(repository, searchTerm, additionalQueryParameters);
                suggestFetch.run()
                    .then((options: T[] | Map<string, T> | T | null) => {
                        console.debug(`[${this.constructor.name}] Request #${suggestFetch.requestId} succeeded`);
                        this.setState({options: options as T[]});
                        this.handleDidFetch(options as T[]);
                    })
                    .catch((e: any) => {
                        if (e instanceof AjaxFetchCancel) {
                            // Request has been canceled
                            console.debug(`[${this.constructor.name}] Request #${suggestFetch.requestId} has been canceled`);

                            return;
                        } else {
                            this.handleFailedFetch(e);
                            throw e;
                        }
                    })
                    .then(() => {/* ignore canceled requests */})
                ;
                this.lastFetch = suggestFetch;
            }
        );
    }

    protected debounceFetch(fetchCallback: () => void) {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = (setTimeout(fetchCallback, 500) as unknown) as number;
    }

    /**
     * Invoked when the fetch request finished successfully (after `setState()`)
     *
     * @param {T[]} options
     */
    protected handleDidFetch(options: T[]): void {
        this.setState({showSpinner: false});

        const callback = this.props.onDidFetch;
        if (callback) {
            callback(options);
        }
    }

    /**
     * Invoked when the fetch request failed
     *
     * @param {Error} error
     */
    protected handleFailedFetch(error: Error): void {
        this.setState({showSpinner: false});

        const callback = this.props.onFailedFetch;
        if (callback) {
            callback(error);
        }
    }

    /**
     * Invoked before the fetch request will be sent
     */
    protected handleWillFetch(): void {
        this.setState({showSpinner: true});

        const callback = this.props.onWillFetch;
        if (callback) {
            callback();
        }
    }

    /**
     * Select the given option
     *
     * @param {T} item
     * @param event
     */
    protected handleChooseOption(item: T, event: SyntheticEvent) {
        this.setState(
            {
                stringValue: this.getStateStringValueForItem(item),
                value: item
            }
        );

        const onSelect = this.props.onSelect;
        if (onSelect) {
            onSelect(item, event);
        }
    }

    /**
     * Return the name for the given option item
     *
     * `props.getOptionName()` will be used if defined
     *
     * @param {T} item
     * @return {string}
     */
    protected getOptionName(item: T): string {
        const callback = this.props.getOptionName || this.defaultGetOptionNameCallback();

        if (callback) {
            return '' + callback(item);
        }

        throw new TypeError('Could not get option name');
    }

    /**
     * Return the value for the given option item
     *
     * `props.getOptionValue()` will be used if defined
     *
     * @param {T} item
     * @return {string}
     */

    protected getOptionValue(item: T): string {
        const callback = this.props.getOptionValue || this.defaultGetOptionValueCallback();

        if (callback) {
            return '' + callback(item);
        }

        throw new TypeError('Could not get option value');
    }

    protected get filteredOptions(): T[] {
        const filter: Filter<T> | undefined = this.props.optionFilter;
        const allOptions = this.state.options;
        if (filter) {
            return allOptions.filter(filter);
        } else {
            return allOptions;
        }
    }

    protected defaultGetOptionNameCallback() {
        return (item: T) => {
            if (typeof (item as any)['name'] !== 'undefined') {
                return (item as any)['name'];
            } else {
                return item.uid;
            }
        };
    }

    protected defaultGetOptionValueCallback() {
        return (item: T) => {
            return item.uid;
        };
    }

    protected defaultGetItemKeyCallback(): (item: T) => string | number | undefined {
        return (item: T) => {
            if (typeof item['uid'] !== 'undefined') {
                return item.uid;
            } else if (typeof item['guid'] !== 'undefined') {
                return item.guid;
            } else if (typeof item['name'] !== 'undefined') {
                return item['name'];
            } else {
                return undefined;
            }
        };
    }
}
