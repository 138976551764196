import { unwrap, unwrapNotNull } from './Helper/unwrap';
import { MainHandlerHelper } from './MainHandlerHelper';

// noinspection JSUnusedGlobalSymbols
/**
 * Development version of `unwrap()` marked as deprecated to easier spot it's usage inside an IDE
 *
 * @param {T | undefined} input
 * @return {T}
 * @deprecated
 */
export function unwrapX<T>(input: T | undefined): T {
    return unwrap(input);
}

// noinspection JSUnusedGlobalSymbols
/**
 * Development version of `unwrapNotNull()` marked as deprecated to easier spot it's usage inside an IDE
 *
 * @param {T | null | undefined} input
 * @return {T}
 * @deprecated
 */
export function unwrapNotNullX<T>(input: T | null | undefined): T {
    return unwrapNotNull(input);
}

export class DevHelper {
    constructor(private handlerHelper: MainHandlerHelper) {
    }

    public initDev() {
        const handlerHelper = this.handlerHelper;
        handlerHelper.loginUser('test-user').then();
        // return;
        handlerHelper.selectCustomer(36000).then(); // Svea
        // handlerHelper.selectCustomer(18548).then();
        // handlerHelper.selectCustomer(26076).then(); // Philipp
        // handlerHelper.selectCustomer(47001).then();
        // handlerHelper.selectFrame(40001).then(() => handlerHelper.selectPriceModel(PriceModel.lifetime));
        // handlerHelper.selectFrame(76515).then(() => handlerHelper.selectPriceModel(PriceModel.lifetime));
        //     .then(() => handlerHelper.selectPriceModel(PriceModel.economy))
        //     .then(() => handlerHelper.addEconomyOption(40618))
        //     .then(() => handlerHelper.setSetting('upperSectionStyle', UpperSectionStyle.COLLAPSED));
        // handlerHelper.addVariant(
        //     /* right */ 29010, 'es-045-B', ['es-160-B', 'es-530-B', 'es-778-B', 'cz-ZUS-B'],
        //     /* left  */ 26593, 'es-237-B', ['es-160-B', 'es-FFF314-B']
        // );
        //
        // handlerHelper.addVariant(
        //     /* right */ 29010, 'es-045-B', ['es-160-B', 'es-778-B', 'cz-ZUS-B'],
        //     /* left  */ 26593, 'es-237-B', []
        // );
        // handlerHelper.addVariant(
        //     /* right */ 26887,
        // );
        // handlerHelper.addVariant(
        //     /* right */ 41416,
        // );
        //
        // handlerHelper.addVariant(
        //     /* both */ 46956, 'es-045-B', ['es-160-B', 'es-778-B', 'cz-ZUS-B'],
        // );
        //
        // handlerHelper.addVariant(
        //     /* right */ 46956, 'es-045-B', [],
        //     /* left  */ 46956, 'es-237-B', []
        // );
        //
        // handlerHelper.addVariant(
        //     /* right */ 46961, 'es-045-B', ['es-160-B', 'es-778-B', 'cz-ZUS-B'],
        //     /* left  */ 46965, 'es-237-B', ['es-160-B', 'es-FFF314-B']
        // );
        // handlerHelper.addVariant(
        //     /* right */ 46961, 'es-045-B', [],
        //     /* left  */ 46965, 'es-237-B', ['es-160-B', 'es-FFF314-B']
        // );

        // const offerLoadedPromise: Promise<Variant>[] = [];
        // const fav = Favourite.fromValues(
        //     'f1',
        //     Group.SingleVision,
        //     false,
        //     53613,
        //     'es-237-B',
        //     ['es-160-B', 'es-530-B', 'es-778-B', 'cz-ZUS-B'],
        //     54001,
        //     'es-237-B',
        //     ['es-160-B', 'es-FFF314-B']
        // );
        //
        // offerLoadedPromise.push(handlerHelper.loadFavourite(fav, []));
        // const fav2 = Favourite.fromValues(
        //     'f1',
        //     Group.SingleVision,
        //     true,
        //     53613,
        //     'es-237-B',
        //     ['es-160-B', 'es-530-B', 'es-778-B', 'cz-ZUS-B'],
        //     undefined,
        //     undefined,
        //     []
        // );
        // offerLoadedPromise.push(handlerHelper.loadFavourite(fav2, []));

        // Promise.all(offerLoadedPromise).then(
        //     () => {
        //         const printService: PrintService = serviceLocator.get('printService');
        //         printService.printOffer(handler.offer);
        //     }
        // );
    }
}
