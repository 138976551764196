import { VariantResult } from '../../../Model/Offer/VariantResult';

interface VariantResultProps {
    results: VariantResult[]
}

const errorMessage = {
    '1566987968': 'Vergütung #{addition} für Produkt "{lensesName}" nicht verfügbar',
    '1566987969': 'Vergütung #{addition} nicht gefunden',
    '1550846420': 'Glas #{lenses} nicht gefunden',
    '1550846430': 'Glas #{lenses} ist nicht für Refraktion {side} erhältlich',
};

function translateError(code: string, data: {}): string {
    let translation = errorMessage[code];
    if (translation === undefined) {
        return '';
    }

    for (const key in data) {
        const regex = new RegExp('{' + key + '}', 'g');
        translation = translation.replace(regex, '' + data[key]);
    }

    return translation.replace(/{[^}]*}/g, '');
}


export function VariantResultView(props: VariantResultProps) {
    const results = props.results;
    const errorMessage: string[] = [];

    for (const result of results) {
        if (result.errors && result.errors.length > 0) {
            errorMessage.push('Variante: "' + result.variant.description + '"');
            for (const error of result.errors) {
                const translation = translateError('' + error.code, error.data);
                if (translation) {
                    errorMessage.push(' - ' + translation);
                } else {
                    errorMessage.push(' - ' + error.message);
                }
            }
        }
    }

    if (errorMessage.length > 0) {
        const headline = ''
            + (errorMessage.length > 1 ? 'Warnungen' : 'Warnung')
            + ' beim Laden '
            + (results.length > 1 ? 'von Varianten' : 'der Variante');

        window.alert(headline + '\n' + errorMessage.join('\n'));
    }
}

