import * as React from 'react';

export enum SpinnerType {
    Ring = 'ring',
    Dots = 'dots',
    Custom = 'custom',
}

export class Spinner extends React.Component<{ type: SpinnerType }> {
    public render() {
        return <div className="spinner">{this.renderInner(this.props.type)}</div>;
    }

    private renderInner(type: SpinnerType) {
        switch (type) {
            case SpinnerType.Ring:
                return <div className="spinner-inner lds-ring">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>;

            case SpinnerType.Dots:
                return <div className="spinner-inner lds-ellipsis">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>;

            case SpinnerType.Custom:
                return <div className="spinner-inner">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>;
        }
    }
}
