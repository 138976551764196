import * as React from 'react';
import { SyntheticEvent } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Favourite } from '../../../../Model/Offer/Favourite';
import { FavouriteViewProps } from '../FavouriteViewProps';

export type ClickHandler = (event: SyntheticEvent) => void;

interface ViewProps extends FavouriteViewProps {
    onRemove: ClickHandler,
    onLoad: ClickHandler,
    onSelect: ClickHandler,
    selected: boolean,
    favourite: Favourite,
}

const SortTextHandle = SortableHandle<{ favourite: Favourite }>(
    () => <div className="sort-handler"/>
    // Once `title` property is added: props => <h3 className="sort-handler">{props.favourite.title}</h3>
);

export class Header extends React.PureComponent<ViewProps> {
    public render() {
        const props = this.props;
        const selectClassName = 'favourite-header-item discreet select' + (props.selected ? ' -selected' : '');

        return <header>
            <SortTextHandle favourite={props.favourite}/>
            <span onClick={props.onSelect} className={selectClassName}/>

            <button onClick={props.onRemove} className="favourite-header-item icon-button favourite-remove">
                <i className="material-icons">delete</i>
            </button>
            <button onClick={props.onLoad} className="favourite-header-item icon-button -remove favourite-load">
                <i className="material-icons">open_in_browser</i>
            </button>
        </header>;
    }
}
