import * as React from 'react';
import { Refraction, refractionProperties } from '../../../../Model/Refraction';
import { Cell } from './Cell';
import { RowProps } from './RowProps';

export class Row extends React.PureComponent<RowProps> {
    public render() {
        const props = this.props;
        const refractionPair = props.refractionPair;
        const refraction = props.refraction;
        const side = refraction ? '-' + refraction.side : '';
        const className = 'customer-refraction-pair-item-row'
            + (props.isSelected ? ' -selected' : ' -not-selected')
            + ' ' + side;

        const rowKey = refractionPair.uid + '-row' + side;
        return <tr key={rowKey} className={className} onClick={props.onClick}>
            {Object.keys(refractionProperties).map(
                property => <td key={refractionPair.uid + '-column-header-' + property}>
                    <Cell refraction={refraction} property={property as keyof Refraction}/>
                </td>
            )}
        </tr>;
    }
}
