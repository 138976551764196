import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { CatalogModelInterface } from '../CatalogModelInterface';
import { AbstractLenses } from './AbstractLenses';
import { PriceGroup } from './PriceGroup';
import { Product } from './Product';

@ra(ClassTypeOptions.AddUnknownFields)
export class Lenses extends AbstractLenses implements CatalogModelInterface, Clone {
    @ra_property(PriceGroup)
    public priceGroup: PriceGroup;

    @ra_property()
    public description: string;

    @ra_property()
    public status: string;

    @ra_property()
    public nameInternal: string;

    @ra_property()
    public diameter: string;

    @ra_property()
    public priceMax: string;

    @ra_property()
    public priceAdditionMax: string;

    @ra_property()
    public priceAdditionMin: string;

    @ra_property()
    public name: string;

    get product(): Product {
        return Product.fromLenses(this);
    };

    get price(): number {
        return this.priceGroup ? this.priceGroup.salesPrice : NaN;
    }

    public getPrice(): number {
        return this.priceGroup ? this.priceGroup.salesPrice : NaN;
    }

    get x(): string {
        return 'x';
    }

    public clone<T extends Clone = this>(): this | T {
        const clone = new Lenses();

        clone.groupUid = this.groupUid;
        clone.colorName = this.colorName;
        clone.vendorUid = this.vendorUid;
        clone.additionMaterial = this.additionMaterial;
        clone.inhousePriceId = this.inhousePriceId;
        clone.nameInternal = this.nameInternal;
        clone.diameter = this.diameter;
        clone.priceGroup = this.priceGroup;
        clone.description = this.description;
        clone.status = this.status;
        clone.nameInternal = this.nameInternal;
        clone.diameter = this.diameter;
        clone.priceMax = this.priceMax;
        clone.priceAdditionMax = this.priceAdditionMax;
        clone.priceAdditionMin = this.priceAdditionMin;

        return clone;
    }
}
