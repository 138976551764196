import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { CredentialForm, CredentialSubmitCallback } from '../Components/Login/CredentialForm';

/**
 * Service to show a Login form
 *
 * The Authentication Controller renders a Login form independent from the rest of the React application
 */
export class AuthenticationController {
    private ref: CredentialForm;
    private readonly outlet: Element;


    constructor(outlet: Element) {
        this.outlet = outlet;
    }

    /**
     * Display the form and register the given submit handler
     * @param onSubmit
     */
    public showForm(onSubmit: CredentialSubmitCallback): this {
        ReactDOM.render(
            <CredentialForm onSubmit={onSubmit} ref={r => this.ref = r as CredentialForm}/>,
            this.outlet
        );

        return this;
    }

    /**
     * Remove the form from the DOM
     */
    public hideForm() {
        ReactDOM.unmountComponentAtNode(this.outlet);
    }

    /**
     * Display a error message
     *
     * @param error
     */
    public setError(error: string): this {
        this.ref.setState({error});

        return this;
    }

    /**
     * Clear the error message
     */
    public removeError(): this {
        this.ref.setState({error: undefined});

        return this;
    }
}
