import { ExecuteMethod, Repository } from '@cundd/rest-adapter';
import { StorageAdapterInterface } from './StorageAdapterInterface';

export class ServerStorageAdapter<T> implements StorageAdapterInterface<T> {
    constructor(private readonly repository: Repository<T>) {
    }

    public getItem(namespace: string, uid: string): Promise<T | string | null> {
        return this.repository.execute(uid).then(r => {
            return this.extractResult(r);
        }).catch(() => null);
    }

    public setItem<I = T>(namespace: string, uid: string, serialized: string, item: I): Promise<T | null> {
        return this.repository.execute(uid, ExecuteMethod.POST, serialized).then(r => {
            return this.extractResult(r);
        });
    }

    private extractResult(r: T[] | Map<string, T> | T | null) {
        if (Array.isArray(r)) {
            return r.length === 0 ? null : r[0];
        }
        if (r instanceof Map) {
            return r.values()[0] || null;
        }

        return r;
    }
}
