import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AbstractVariantForm } from './AbstractVariantForm';

export class NewVariant extends AbstractVariantForm {
    protected renderHeader(): JSX.Element {
        return <h1>Neue Variante {this.props.variantNumber}</h1>;
    }

    protected getContainerClassName(): string {
        return 'variant-form variant-new';
    }

    protected handleClickSave(event: SyntheticEvent) {
        console.debug('[New Variant] Save');
        event.preventDefault();
        const variant = this.state.variant;
        this.context.handler.handleAddVariant(variant);

        if (this.props.onClickSave) {
            this.props.onClickSave(variant, event);
        }
    }
}
