import { AdapterConfiguration, FetchCallback, Serializer } from '@cundd/rest-adapter';
import { FramePrice } from '../../Model/Offer/FramePrice';
import { Offer } from '../../Model/Offer/Offer';
import { PriceModel } from '../../Model/Offer/PriceModel';
import { VariantPrice } from '../../Model/Offer/VariantPrice';
import { FramePriceCalculator } from '../FramePriceCalculator';
import { VariantPriceCalculator } from '../VariantPriceCalculator';
import { ExportOffer } from './ExportOffer';
import { ExportOfferMeta } from './ExportOfferMeta';

export abstract class AbstractExportService {
    private readonly serializer: Serializer<Offer>;
    private readonly adapterConfiguration: AdapterConfiguration;

    constructor(
        adapterConfiguration: AdapterConfiguration,
        serializer?: Serializer<ExportOffer>
    ) {
        this.adapterConfiguration = adapterConfiguration;
        this.serializer = serializer || new Serializer<ExportOffer>();
    }

    protected abstract getApiPath(exportOffer: ExportOffer): string ;

    protected abstract handleResponse(exportOffer: ExportOffer, promise: Promise<Response>): void;

    protected performExport(offer: Offer) {
        const meta = this.buildOfferMeta(offer);
        const exportOffer: ExportOffer = new ExportOffer(offer, meta);

        const serialized = this.serializer.serialize(exportOffer);
        const uri = this.adapterConfiguration.endpoint.toString() + 'spectacles/' + this.getApiPath(exportOffer);
        this.handleResponse(exportOffer, this.sendPost(uri, serialized));
    }

    private buildOfferMeta(offer: Offer) {
        const variantPrices = this.calculateVariantPrices(offer);
        const framePrices = this.calculateFramePrices(offer);

        return new ExportOfferMeta(framePrices.framePriceLifetime, framePrices.framePriceEconomy, variantPrices);
    }

    private sendPost<T>(uri: string, body: T): Promise<Response> {
        const config = this.adapterConfiguration;

        const requestSettings = Object.assign(
            {},
            {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: typeof body !== 'string' ? JSON.stringify(body) : body
            },
            config.requestSettings
        );
        return this.fetch(uri, requestSettings);
    }

    private fetch(uri: string, requestSettings: RequestInit) {
        const fetchCallback: FetchCallback | undefined = this.adapterConfiguration.fetchCallback;

        let xhrPromise;
        if (fetchCallback) {
            // Use the custom fetch callback
            xhrPromise = fetchCallback(uri, requestSettings);
        } else {
            // Use the JavaScript Fetch API (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)
            xhrPromise = fetch(uri, requestSettings);
        }

        return xhrPromise;
    }

    private calculateFramePrices(offer: Offer): { framePriceLifetime: FramePrice | undefined, framePriceEconomy: FramePrice | undefined } {
        const framePriceCalculator = new FramePriceCalculator();

        const framePriceLifetime = framePriceCalculator.buildPriceForOffer(offer.withPriceModel(PriceModel.lifetime));
        const framePriceEconomy = framePriceCalculator.buildPriceForOffer(offer.withPriceModel(PriceModel.economy));

        return {framePriceLifetime, framePriceEconomy};
    }

    private calculateVariantPrices(offer: Offer): Map<string, VariantPrice> {
        const variantPrices = new Map();
        const framePriceCalculator = new VariantPriceCalculator();

        for (const variant of offer.variants) {
            variantPrices.set(variant.uid, framePriceCalculator.buildPriceForVariant(variant,offer));
        }

        return variantPrices;
    }
}
