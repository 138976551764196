import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { BaseModel } from '../BaseModel';
import { Addition } from '../Catalog/Lenses/Addition';
import { Lenses } from '../Catalog/Lenses/Lenses';
import { Product } from '../Catalog/Lenses/Product';
import { RefractionSide } from '../RefractionSide';
import { Service } from '../Service';
import { EyeConfiguration } from './Variant/EyeConfiguration';
import { VariantInterface } from './VariantInterface';

/**
 * A Variant defines the core of a Lenses Offer
 *
 * It consists of the `EyeConfiguration`s (selected Lenses, Coating and miscellaneous Additions per eye)
 * and also reworking and lenses determination prices.
 *
 * The Variant my contain `EyeConfiguration`s for the right or left eye, or both.
 * If the Variant is defined as `symmetrical` the configuration of the right eye also applies to the left eye
 */
@ra(ClassTypeOptions.DenyUnknownFields)
export class Variant
    extends BaseModel
    implements Clone, VariantInterface<Addition, Lenses, EyeConfiguration> {
    @ra_property(Service, 'reworking')
    protected _reworking?: Service;

    @ra_property('lensesDeterminationPrice')
    protected _lensesDeterminationPrice?: number;

    @ra_property(EyeConfiguration, 'right')
    protected _right: EyeConfiguration;

    @ra_property(EyeConfiguration, 'left')
    protected _left?: EyeConfiguration;

    @ra_property('symmetrical')
    protected _symmetrical: boolean;

    @ra_property('isMain')
    protected _isMain: boolean;

    @ra_property('discount')
    protected _discount: number;

    @ra_property('title')
    protected _title: string;

    constructor(
        uid: string | number,
        title: string,
        right?: EyeConfiguration,
        left?: EyeConfiguration,
        symmetrical: boolean = true,
        reworkingPrice?: Service,
        lensesDeterminationPrice?: number,
        isMain: boolean = false,
        discount: number = 0.0
    ) {
        super();
        this.uid = '' + uid;
        this._symmetrical = symmetrical;
        this._right = right || new EyeConfiguration();
        this._left = left;
        this._reworking = reworkingPrice;
        this._lensesDeterminationPrice = lensesDeterminationPrice;
        this._isMain = isMain;
        this._discount = discount;
        this._title = title;
    }

    get title(): string {
        return this._title;
    }

    get symmetrical(): boolean {
        return this._symmetrical;
    }

    get isMain(): boolean {
        return this._isMain;
    }

    get right(): EyeConfiguration | undefined {
        return this._right;
    }

    get left(): EyeConfiguration | undefined {
        return this._left;
    }

    get rightProduct(): Product | undefined {
        return this._right ? this._right.product : undefined;
    }

    get leftProduct(): Product | undefined {
        if (this._symmetrical) {
            return this.rightProduct;
        } else if (this._left && this._left.product) {
            return this._left.product;
        } else {
            return undefined;
        }
    }

    get rightLenses(): Lenses | undefined {
        return this._right ? this._right.lenses : undefined;
    }

    get leftLenses(): Lenses | undefined {
        if (this._symmetrical) {
            return this.rightLenses;
        } else if (this._left && this._left.lenses) {
            return this._left.lenses;
        } else {
            return undefined;
        }
    }

    get rightCoating(): Addition | undefined {
        return this._right ? this._right.coating : undefined;
    }

    get leftCoating(): Addition | undefined {
        if (this._symmetrical) {
            return this.rightCoating;
        } else if (this._left && this._left.coating) {
            return this._left.coating;
        } else {
            return undefined;
        }
    }

    get rightMiscellaneous(): Addition[] {
        return this._right ? this._right.miscellaneous : [];
    }

    get leftMiscellaneous(): Addition[] {
        if (this._symmetrical) {
            return this.rightMiscellaneous;
        } else if (this._left && this._left.miscellaneous) {
            return this._left.miscellaneous;
        } else {
            return [];
        }
    }

    /**
     * @deprecated Fetch Reworking from Offer instead. Will be removed in version 2.0
     */
    get reworking(): Service | undefined {
        return this._reworking;
    }

    /**
     * @deprecated Fetch Lenses Determination Price from Offer instead. Will be removed in version 2.0
     */
    get lensesDeterminationPrice(): number | undefined {
        return this._lensesDeterminationPrice;
    }

    get discount(): number {
        const discount = this._discount;
        // noinspection SuspiciousTypeOfGuard
        return typeof discount === 'number' ? discount : 0.0;
    }

    get description(): string {
        const right = this._right;
        const left = this._left;
        if (!right && !left) {
            return '';
        }
        if (this.symmetrical) {
            return right.description;
        }

        let description = '';
        if (right) {
            description += RefractionSide.Right + ': ' + right.description + ' ';
        }
        if (left) {
            description += RefractionSide.Left + ': ' + left.description + ' ';
        }

        return description.trim();
    }

    public withTitle(title: string): Variant {
        const clone = this.clone();
        clone._title = title;

        return clone;
    }

    public withIsMain(flag: boolean): Variant {
        const clone = this.clone();
        clone._isMain = flag;

        return clone;
    }

    /**
     * @deprecated
     * @param value
     */
    public withLensesDeterminationPrice(value: number): Variant {
        return this.clone();
    }

    /**
     * @deprecated
     */
    public withoutLensesDeterminationPrice(): Variant {
        return this.clone();
    }

    public withSymmetrical(value: boolean): this {
        const clone = this.clone();
        clone._symmetrical = value;

        return clone;
    }

    public withRightProduct(value: Product | undefined): this {
        const clone = this.clone();
        clone._right = clone._right.withProduct(value);

        return clone;
    }

    public withLeftProduct(value: Product | undefined): this {
        const clone = this.clone();
        clone._left = (clone._left || new EyeConfiguration()).withProduct(value);

        return clone;
    }

    public withRightCoating(value: Addition | undefined): this {
        const clone = this.clone();
        clone._right = clone._right.withCoating(value);

        return clone;
    }

    public withLeftCoating(value: Addition | undefined): this {
        const clone = this.clone();
        clone._left = (clone._left || new EyeConfiguration()).withCoating(value);

        return clone;
    }

    public withRightLenses(value: Lenses | undefined): this {
        const clone = this.clone();
        clone._right = clone._right.withLenses(value);

        return clone;
    }

    public withLeftLenses(value: Lenses | undefined): this {
        const clone = this.clone();
        clone._left = (clone._left || new EyeConfiguration()).withLenses(value);

        return clone;
    }

    public withRightMiscellaneous(value: Addition[]): this {
        const clone = this.clone();
        clone._right = clone._right.withMiscellaneous(value);

        return clone;
    }

    public withAddedRightMiscellaneous(addition: Addition): this {
        const clone = this.clone();
        clone._right = clone._right.withAddedMiscellaneous(addition);

        return clone;
    }

    public withLeftMiscellaneous(value: Addition[]): this {
        const clone = this.clone();
        clone._left = (clone._left || new EyeConfiguration()).withMiscellaneous(value);

        return clone;
    }

    public withAddedLeftMiscellaneous(addition: Addition): this {
        const clone = this.clone();
        clone._left = (clone._left || new EyeConfiguration()).withAddedMiscellaneous(addition);

        return clone;
    }

    /**
     * @deprecated
     * @param value
     */
    public withReworking(value: Service): Variant {
        return this.clone();
    }

    /**
     * @deprecated
     */
    public withoutReworking(): Variant {
        return this.clone();
    }

    public withDiscount(discount: number): Variant {
        const clone = this.clone();
        clone._discount = discount;

        return clone;
    }

    public isValid(): boolean {
        return this.rightLenses !== undefined || this.leftLenses !== undefined;
    }

    public clone<T extends Clone = this>(): this | T {
        return new Variant(
            this.uid,
            this._title,
            this._right,
            this._left,
            this.symmetrical,
            this._reworking,
            this._lensesDeterminationPrice,
            this.isMain,
            this.discount,
        );
    }

    /**
     * @internal
     * @deprecated Will be removed in version 2.0
     * @param value
     */
    public _assignLensesDeterminationPrice(value: number | undefined): this {
        this._lensesDeterminationPrice = value;

        return this;
    }

    /**
     * @internal
     * @deprecated Will be removed in version 2.0
     * @param value
     */
    public _assignReworking(value: Service | undefined): this {
        this._reworking = value;

        return this;
    }

    /**
     * @internal
     * @param left
     */
    public _assignLeft(left: EyeConfiguration): this {
        this._left = left;

        return this;
    }
}
