import * as React from 'react';
import { Currency } from '../../../Formatter/Currency';
import { VariantViewProps } from '../VariantViewProps';

export class DiscountRow extends React.Component<VariantViewProps> {
    public render() {
        const discount = this.props.variant.discount;

        if (discount) {
            return <tr className="header">
                <th className="caption">Preisreduktion</th>
                <td className="value"><Currency value={-1 * discount} trailingZeros={true}/></td>
            </tr>;
        } else {
            return null;
        }
    }
}
