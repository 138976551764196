import * as React from 'react';
import { getGroupName, Group } from '../../../Model/Offer/Favourite/Group';

export interface GroupItemProps {
    group: Group,
    onSelectGroup: (group: Group) => void
}

export class GroupItem extends React.Component<GroupItemProps> {
    constructor(props: GroupItemProps, context: any) {
        super(props, context);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
    }

    public render() {
        const g = this.props.group;

        return <li onClick={this.handleSelectGroup}
                   className="favourite-button-add-group">{getGroupName(Group[g])}</li>;
    }

    private handleSelectGroup() {
        this.props.onSelectGroup(this.props.group);
    }
}
