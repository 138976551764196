import * as React from 'react';

export interface ModalProps {
    show: boolean
    className?: string
}

export class Modal extends React.Component<ModalProps> {
    public render() {
        const props = this.props;
        if (!props.show) {
            return null;
        }

        const className = 'modal-body ' + props.className;

        return <div className="modal-overlay">
            <div className={className}>
                {props.children}
            </div>
        </div>;
    }
}
