import { PropertyTypeOptions, ra_property } from '@cundd/rest-adapter';
import { FramePrice } from '../../Model/Offer/FramePrice';
import { VariantPrice } from '../../Model/Offer/VariantPrice';

export class ExportOfferMeta {
    @ra_property(FramePrice)
    public framePriceLifetime: FramePrice | undefined;

    @ra_property(FramePrice)
    public framePriceEconomy: FramePrice | undefined;

    @ra_property(VariantPrice, PropertyTypeOptions.Multiple)
    public variantPrices: Map<string, VariantPrice>;

    constructor(
        framePriceLifetime: FramePrice | undefined,
        framePriceEconomy: FramePrice | undefined,
        variantPrices: Map<string, VariantPrice>
    ) {
        this.framePriceLifetime = framePriceLifetime;
        this.framePriceEconomy = framePriceEconomy;
        this.variantPrices = variantPrices;
    }
}
