import { ra_property } from '@cundd/rest-adapter';
import { Assert } from 'iresults-ts-core';
import { AbstractCatalogModel } from './Catalog/AbstractCatalogModel';
import { CatalogModelInterface } from './Catalog/CatalogModelInterface';

export class Service extends AbstractCatalogModel implements CatalogModelInterface {
    @ra_property()
    public readonly description: string;

    @ra_property()
    public readonly group: number;

    @ra_property()
    public name: string;

    @ra_property('workshopService')
    public readonly workshopService: boolean;

    @ra_property('price')
    private readonly _price: number;

    constructor(
        uid: string | number,
        price: number,
        name: string,
        description?: string,
        workshopService: boolean = false
    ) {
        super();
        Assert.isNumber(price);
        this.uid = '' + uid;
        this._price = price;
        this.name = name;
        this.description = typeof description !== 'undefined' ? description : name;
        this.workshopService = workshopService;
    }

    get price(): number {
        // noinspection SuspiciousTypeOfGuard
        return typeof this._price !== 'number' ? parseFloat(this._price) : this._price;
    }

    public equals<T>(other: T): boolean {
        if (other instanceof Service) {
            return this.uid === other.uid;
        }
        return false;
    }
}
