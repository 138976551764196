import { Repository } from '@cundd/rest-adapter';
import { GuidService } from '../../../Service/GuidService';
import { AdditionalQueryParameters } from './AdditionalQueryParameters';
import { AjaxFetchCancel } from './AjaxFetchCancel';

export class AjaxFetch<T> {
    private static counter: number = 0;
    public readonly requestId: string;
    private obsolete: boolean;

    constructor(
        private readonly repository: Repository<T>,
        private readonly searchTerm: string,
        private readonly additionalQueryParameters?: AdditionalQueryParameters
    ) {
        AjaxFetch.counter += 1;

        this.obsolete = false;
        this.requestId = GuidService.sharedInstance.uuidv4() + '-' + AjaxFetch.counter;
        console.log('[AjaxFetch] counter ' + this.requestId);
    }

    public run(): Promise<T[] | Map<string, T> | T | null> {
        console.debug(`[AjaxFetch #${this.requestId}] Fetch options from repository ${this.repository}`);

        const subPath = this.buildRequestUri();

        return this.repository
            .execute<T, T>(subPath)
            .then((options: T[] | Map<string, T> | T | null) => {
                if (this.obsolete) {
                    console.debug(`[AjaxFetch #${this.requestId}] Request for "${subPath}" is obsolete/canceled`);
                    throw new AjaxFetchCancel();
                }
                const length = options ? (options as T[]).length : 'undefined';
                console.debug(`[AjaxFetch #${this.requestId}] Found ${length} options  for "${subPath}"`);

                return options;
            });
    }

    public cancel() {
        this.obsolete = true;

        return this;
    }

    private buildRequestUri(): string {
        const subPath = 'find/?q=' + encodeURIComponent(this.searchTerm);
        if (this.additionalQueryParameters) {
            return subPath + '&' + this.prepareAdditionalQueryParameters(this.additionalQueryParameters);
        } else {
            return subPath;
        }
    }

    private prepareAdditionalQueryParameters(obj: AdditionalQueryParameters) {
        return Object.keys(obj)
            .map((key) => {
                const value = obj[key];
                if (typeof value === 'object' && typeof value.serialize === 'function') {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(value.serialize());
                }
                if (value === undefined) {
                    return undefined;
                }
                return encodeURIComponent(key) + '=' + encodeURIComponent(value as string);
            })
            .filter(v => v !== undefined)
            .join('&');
    }
}
