import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Modal } from './Modal';

export interface QuestionProps {
    show: boolean
    message?: string
    answer1Label: string
    answer2Label: string
    answer1ClassName?: string
    answer2ClassName?: string
    onAnswer1: (event: SyntheticEvent) => void
    onAnswer2: (event: SyntheticEvent) => void
}

export class Question extends React.PureComponent<QuestionProps> {
    public render() {
        const props = this.props;
        if (!props.show) {
            return null;
        }

        const answer1ClassName = props.answer1ClassName || '';
        const answer2ClassName = props.answer2ClassName || '';

        return <Modal show={true}>
            <div className="modal-content">
                {props.message || props.children}
            </div>
            <div className="modal-footer">
                <button className={answer1ClassName} onClick={props.onAnswer1}>
                    {props.answer1Label}
                </button>
                <button className={answer2ClassName} onClick={props.onAnswer2}>
                    {props.answer2Label}
                </button>
            </div>
        </Modal>;
    }
}
