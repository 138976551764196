import * as React from 'react';
import { AppContext, AppContextType } from '../../AppContextType';

interface OfferInfoProps {
}

export class OfferInfo extends React.Component<OfferInfoProps, {}> {
    public static contextType = AppContextType;
    public context!: AppContext;

    public render() {
        const offer = this.context.offer;

        return <div className="offer-info">Offerte {offer.info}</div>;
    }
}
