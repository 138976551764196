export class LensesDeterminationOption {
    public static getOptions(): number[] {
        return [
            50,
            40,
        ];
    }

    public static getDefault(): number {
        return 50;
    }
}
