import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DomainObject } from '../../../Model/DomainObject';
import { AjaxFormFieldProps } from './AjaxFormFieldProps';
import { OptionNameFetcher } from './AjaxFormFieldTypes';

interface AjaxFormFieldItemProps<T extends DomainObject> extends AjaxFormFieldProps<T> {
    item: T;
    onChooseOption: (item: T, event: SyntheticEvent) => void;
    getOptionName: OptionNameFetcher<T>
}

/**
 * A single Option for select-based AJAX fields
 */
export class AjaxOption<T extends DomainObject> extends React.Component<AjaxFormFieldItemProps<T>> {
    constructor(props: Readonly<AjaxFormFieldItemProps<T>>) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        const item = this.props.item;
        const getOptionNameCallback: OptionNameFetcher<T> = this.props.getOptionName;

        return <li key={item.uid} onClick={this.handleClick}>{getOptionNameCallback(item)}</li>;
    }

    private handleClick(event: React.MouseEvent<HTMLElement>) {
        this.props.onChooseOption(this.props.item, event);
    }
}
