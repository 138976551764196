import { Repository } from '@cundd/rest-adapter';
import * as React from 'react';
import { Lenses } from '../../../../../Model/Catalog/Lenses/Lenses';
import { Product } from '../../../../../Model/Catalog/Lenses/Product';
import { Refraction } from '../../../../../Model/Refraction';
import { FindLensesQueryParameters } from '../../../../../Query/FindLensesQueryParameters';
import { AjaxSelect } from '../../../../Form/AjaxSelect';
import { OnSelectHandler } from '../../../../Form/Suggest';
import { SideEnum } from '../../../SideEnum';
import { AbstractProductDependantRow } from './AbstractProductDependantRow';
import { ResultantPrismArgument } from './AbstractRow';
import { FormRowProps } from './FormRowProps';

interface LensesRowState {
    suggestions: Lenses[]
}

export class LensesRow extends AbstractProductDependantRow<Lenses, FormRowProps<Lenses>, LensesRowState> {
    protected renderHeader(): JSX.Element {
        return <h4 className="center">Durchmesser</h4>;
    }

    protected renderRightColumn(): JSX.Element {
        const props = this.props;
        const refraction = this.getMergedRightRefraction();
        if (!refraction) {
            return this.renderPlaceholderField();
        }

        const resultantPrism = this.detectHigherResultantPrism();
        const variant = props.variant;

        if (variant.rightProduct) {
            return this.renderInput(
                props.onChangeRight,
                variant.rightLenses,
                variant.rightProduct,
                refraction,
                resultantPrism
            );
        } else {
            return this.renderPlaceholderField();
        }
    }

    protected renderLeftColumn() {
        const props = this.props;
        const variant = props.variant;
        const rightLenses = variant.leftLenses;
        if (variant.symmetrical) {
            const value = rightLenses ? this.suggestOptionName(rightLenses) : '';

            return this.renderDisabledValue(value);
        }

        const refraction = this.getRefraction(SideEnum.Left);

        // If the Variant is not symmetrical check if the Product has been selected
        if (!variant.leftProduct) {
            return this.renderPlaceholderField();
        } else if (!refraction) {
            return this.renderPlaceholderField();
        } else {
            return this.renderInput(
                props.onChangeLeft,
                rightLenses,
                variant.leftProduct,
                refraction,
                refraction.resultantPrism
            );
        }
    }

    protected renderInput(
        onSelect: OnSelectHandler<Lenses>,
        value: Lenses | undefined,
        product: Product,
        refraction: Refraction,
        resultantPrism: ResultantPrismArgument
    ): JSX.Element {
        console.debug(`[${this.constructor.name}] Render input`, value);
        if (!refraction) {
            throw new ReferenceError('Missing argument "refraction"');
        }

        const additionalQueryParameters: FindLensesQueryParameters = {
            groupUid: product.uid,
            vendorUid: product.vendorUid,
            refraction,
            resultantPrism
        };

        return <AjaxSelect
            className="ajax-select lenses-suggest lenses-lenses-suggest"
            onSelect={onSelect}
            value={value}
            placeholderText="Durchmesser wählen"
            getOptionName={this.suggestOptionName as any}
            additionalQueryParameters={additionalQueryParameters}
            repository={this.getRepository() as any}/>;
    }

    protected suggestOptionName(item: Lenses) {
        return `${item.diameter}`;
    }

    protected getRepository(): Repository<Lenses> {
        return this.context.serviceLocator.get('lensesLensesRepository');
    }
}
