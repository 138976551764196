import * as React from 'react';
import { PrintIcon } from './PrintIcon';

interface PrintButtonProps {
    onClick: () => void
}

export class PrintButton extends React.PureComponent<PrintButtonProps> {
    public render() {
        return <button className="item discreet print-export-button -print" onClick={this.props.onClick}>
            <span className="sr-only">Drucken</span>
            <PrintIcon/>
        </button>;
    }
}
