import { Clone } from 'iresults-ts-core';
import { UpperSectionStyle } from './UpperSectionStyle';

export class Settings implements Clone {
    get upperSectionStyle(): UpperSectionStyle {
        return this._upperSectionStyle;
    }

    public static get default(): Settings {
        return new Settings();
    }

    private _upperSectionStyle: UpperSectionStyle;

    constructor(upperSectionStyle: UpperSectionStyle = UpperSectionStyle.DETAILED) {
        this._upperSectionStyle = upperSectionStyle;
    }

    public withUpperSectionStyle(upperSectionStyle: UpperSectionStyle): Settings {
        const clone = this.clone();
        clone._upperSectionStyle = upperSectionStyle;

        return clone;
    }

    public clone<T extends Clone = this>(): this | T {
        return new Settings(this._upperSectionStyle);
    }
}
