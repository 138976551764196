import * as React from 'react';
import { Currency } from '../../../Formatter/Currency';
import { DetailProps } from './DetailProps';

export class EconomyOptionDetail extends React.Component<DetailProps> {
    public render() {
        const option = this.props.option;

        return <li>{option.name},&nbsp;<Currency showSymbol={true} value={option.price}/></li>;
    }
}
