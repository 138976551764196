import * as React from 'react';
import { Frame } from '../../../Model/Catalog/Frame';
import { PriceModel } from '../../../Model/Offer/PriceModel';
import { Economy } from '../PriceModelBox/Economy';
import { Lifetime } from '../PriceModelBox/Lifetime';
import { AbstractDetailWidget } from './AbstractDetailWidget';

export class Detailed extends AbstractDetailWidget {
    protected getSectionClass(): string {
        return 'catalog-frame-detail-widget -detailed';
    }

    protected renderPricing(): JSX.Element {
        const frame = this.props.frame;

        return <>
            {this.renderLifetime(frame)}
            {frame.priceEconomy > 0 ? this.renderEconomy(frame) : null}
        </>;
    }

    private renderLifetime(frame: Frame) {
        return <div className="row-container">
            <Lifetime status={this.getStatusForPriceModel(PriceModel.lifetime)}
                      frame={frame}/>
        </div>;
    }

    private renderEconomy(frame: Frame) {
        return <div className="row-container">
            <Economy status={this.getStatusForPriceModel(PriceModel.economy)}
                     frame={frame}/>
        </div>;
    }
}
