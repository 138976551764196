import * as React from 'react';

interface ExportButtonProps {
    onClick: () => void
    title?: string
}

export class ExportButton extends React.PureComponent<ExportButtonProps> {
    public render() {
        return <button className="item discreet print-export-button -export"
                       title={this.props.title}
                       onClick={this.props.onClick}>
            <span className="sr-only">Exportieren</span>
            <i className="material-icons">arrow_forward</i>
        </button>;
    }
}
