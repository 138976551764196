import * as React from 'react';
import { AppContext, AppContextType } from './AppContextType';
import { MainHandler } from './MainHandler';
import { ServiceLocator } from './ServiceLocator';

interface HandlerProps {
    handler: MainHandler;
    serviceLocator: ServiceLocator;
    children: JSX.Element;
}

export class HandlerContextProvider extends React.Component<HandlerProps> {
    public render() {
        const appContext = new AppContext(this.props.serviceLocator, this.props.handler);

        return (
            <AppContextType.Provider value={appContext}>
                {this.props.children}
            </AppContextType.Provider>
        );
    }
}
