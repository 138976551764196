import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext } from '../../../AppContextType';
import { Frame } from '../../../Model/Catalog/Frame';
import { ServiceFeeOption } from '../../../Model/Catalog/ServiceFeeOption';
import { Currency } from '../../Formatter/Currency';
import { EconomyOptionProps } from './EconomyOptionProps';

interface ServiceFeeProps extends EconomyOptionProps {
    option: ServiceFeeOption;
    onChange: (option: ServiceFeeOption, timeRequired: number, event: SyntheticEvent) => void;
}

export class ServiceFeeRow extends React.Component<ServiceFeeProps, { timeRequired: number }> {
    constructor(props: ServiceFeeProps, context: AppContext) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            timeRequired: props.option.timeRequired
        };
    }

    public render() {
        const value = this.state.timeRequired * ServiceFeeOption.pricePerMinute;
        const inputClassName = value >= 100 ? 'text-field-big' : 'text-field-normal';
        const frame = this.props.frame as Frame;
        const label = 'Minuten (' + (frame.isCustomerFrame ? 'Richtservice' : 'Fassungsberatung') + ', usw.)';

        return (
            <div className="row-container form-group">
                <label className="grid-10 form-field">
                    <input type="text"
                           pattern="\d*"
                           min="0"
                           step="5"
                           className={inputClassName}
                           value={this.state.timeRequired}
                           onChange={this.handleChange}/>
                    <span className="label">{label}</span>,
                    <small>CHF <Currency value={ServiceFeeOption.pricePerMinute * 15}/> / 15 min</small>
                </label>
                <div className="grid-2 price"><Currency value={value} trailingZeros={true}/></div>
            </div>
        );
    }

    private handleChange(event: any) {
        const timeRequired = this.getTimeRequired(event.target.value);
        this.setState({timeRequired});

        const props = this.props;
        props.onChange(props.option, timeRequired, event);
    }

    private getTimeRequired(value: string) {
        if (value === '') {
            return 0;
        }

        const result = parseInt(value, 10);

        return isNaN(result) ? 0 : result;
    }
}
