import * as React from 'react';
import { EyeConfiguration } from '../../../../Model/Offer/Variant/EyeConfiguration';
import { Currency } from '../../../Formatter/Currency';
import { descriptionForSide, shortDescriptionForSide, SideEnum } from '../../SideEnum';
import { VariantViewProps } from '../VariantViewProps';

export class LensesAndCoatingRow extends React.Component<VariantViewProps> {
    public render() {
        const variant = this.props.variant;
        if (variant.symmetrical && variant.isValid()) {
            return <>
                {this.renderHeader(SideEnum.Both)}
                {this.renderRows(variant.right, SideEnum.Both)}
            </>;
        } else {
            return <>
                {this.renderHeader()}
                {this.renderRows(variant.right, SideEnum.Right)}
                {this.renderRows(variant.left, SideEnum.Left)}
            </>;
        }
    }

    private renderRows(eye: EyeConfiguration | undefined, side: SideEnum) {
        const prefix = this.getPrefix(side);
        const captionClassName = 'caption' + (prefix ? ' -with-prefix' : '');

        if (!eye) {
            return null;
        }

        return <>
            {
                eye.lenses
                    ? <tr>
                        <td className={captionClassName}>
                            {prefix} {eye.lenses.name}
                        </td>
                        <td className="value">
                            {side === SideEnum.Both ? '2 x ' : ''}
                            <Currency value={eye.lenses.price} trailingZeros={true}/>
                        </td>
                    </tr>
                    : null
            }
            {
                eye.coating
                    ? <tr>
                        <td className={captionClassName}>
                            {prefix} {eye.coating.name}
                        </td>
                        <td className="value">
                            {side === SideEnum.Both ? '2 x ' : ''}
                            <Currency value={eye.coating.price} trailingZeros={true}/>
                        </td>
                    </tr>
                    : null
            }
        </>;
    }

    private renderHeader(side: SideEnum = SideEnum.Both) {
        return <tr className="header">
            <th colSpan={2}>Glas &amp; Entspiegelung {descriptionForSide(side)}</th>
        </tr>;
    }

    private getPrefix(side: SideEnum): JSX.Element | null {
        if (side === SideEnum.Both) {
            return null;
        } else {
            return <strong>{shortDescriptionForSide(side)}</strong>;
        }
    }
}
