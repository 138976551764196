import * as React from 'react';
import { AbstractAEElement, AEProps } from './AbstractAEElement';

interface ViewState {
    materialFee: number
}

export class MaterialFee extends AbstractAEElement<number, AEProps<number>, ViewState> {
    constructor(props: AEProps<number>, context: any) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            materialFee: props.offer.materialFee || 0
        };
    }

    protected renderHeader(): JSX.Element {
        return <div title="Material- / Dienstleistungsaufwand">Material- / Dienst&shy;leistungsaufwand</div>;
    }

    protected renderInput() {
        const value = this.state.materialFee;
        const inputClassName = value >= 100 ? 'text-field-big' : 'text-field-normal';

        return <input type="text"
                      pattern="\d*"
                      min="0"
                      className={inputClassName}
                      value={value}
                      onChange={this.handleChange}/>;
    }

    private handleChange(event: any) {
        const materialFee = this.getMaterialFee(event.target.value);
        this.setState({materialFee});

        const props = this.props;
        props.onChange(materialFee, event);
    }

    private getMaterialFee(value: string): number {
        if (value === '') {
            return 0;
        }

        const result = parseFloat(value);

        return isNaN(result) ? 0 : result;
    }
}
