import * as React from 'react';
import { Frame } from '../../../Model/Catalog/Frame';
import { FrameImageService } from '../../../Service/FrameImageService';

export interface FrameImageProps {
    frame: Frame;
    wrapperClassName?: string;
}

interface FrameImageState {
    hasError: boolean;
}

export class FrameImage extends React.Component<FrameImageProps, FrameImageState> {
    constructor(props: FrameImageProps, context: any) {
        super(props, context);
        this.handleLoadError = this.handleLoadError.bind(this);
        this.state = {
            hasError: false
        };
    }

    public render() {
        if (this.state.hasError) {
            return null;
        }

        const props = this.props;
        const frame = props.frame;
        if (!frame.pictures || frame.pictures.length === 0) {
            return null;
        }

        const imageService = new FrameImageService();
        const picture = imageService.getMainImage(frame);
        if (!picture) {
            return null;
        }
        const uri = `${process.env.REACT_APP_API_URL}picture/${picture.uid}`;

        const imageTag = <img src={uri} alt={frame.collection.name} onError={this.handleLoadError}/>;

        const wrapperClassName = props.wrapperClassName;
        if (wrapperClassName) {
            return <div className={wrapperClassName}>{imageTag}</div>;
        } else {
            return imageTag;
        }
    }

    private handleLoadError() {
        this.setState({hasError: true});
    }
}
