import * as React from 'react';
import { EyeConfiguration } from '../../../../Model/Offer/Variant/EyeConfiguration';
import { Currency } from '../../../Formatter/Currency';
import { descriptionForSide, shortDescriptionForSide, SideEnum } from '../../SideEnum';
import { VariantViewProps } from '../VariantViewProps';

interface ViewProps extends VariantViewProps {
    showEmpty?: boolean
}

export class MiscellaneousRow extends React.Component<ViewProps> {
    public render() {
        const props = this.props;
        const variant = props.variant;

        const right = variant.right;
        const left = variant.left;

        const hasRightMisc = right ? right.miscellaneous.length > 0 : false;
        const hasLeftMisc = left ? left.miscellaneous.length > 0 : false;

        if (!hasRightMisc && !hasLeftMisc) {
            return props.showEmpty ? <>{this.renderHeader()}{this.renderEmptyRow()}</> : null;
        }

        if (variant.symmetrical && variant.isValid()) {
            return <>
                {this.renderHeader(SideEnum.Both)}
                {this.renderRows(right, SideEnum.Both)}
            </>;
        } else {
            return <>
                {this.renderHeader()}
                {this.renderRows(right, SideEnum.Right)}
                {this.renderRows(left, SideEnum.Left)}
            </>;
        }
    }

    private renderRows(eye: EyeConfiguration | undefined, side: SideEnum) {
        const prefix = this.getPrefix(side);
        const captionClassName = 'caption' + (prefix ? ' -with-prefix' : '');

        if (eye && eye.miscellaneous.length > 0) {
            return <>
                {eye.miscellaneous.map(m => {
                    return <tr key={m.code}>
                        <td className={captionClassName}>{prefix} {m.name}</td>
                        <td className="value">
                            {side === SideEnum.Both ? '2 x ' : ''}
                            <Currency value={m.price} trailingZeros={true}/>
                        </td>
                    </tr>;
                })}
            </>;
        } else {
            return null;
        }
    }

    private renderEmptyRow() {
        return <>
            <tr>
                <td className="caption">&nbsp;</td>
                <td className="value">
                    <Currency value={0.0} trailingZeros={true}/>
                </td>
            </tr>
        </>;
    }

    private renderHeader(side: SideEnum = SideEnum.Both) {
        return <tr className="header">
            <th colSpan={2}>Diverses {descriptionForSide(side)}</th>
        </tr>;
    }

    private getPrefix(side: SideEnum): JSX.Element | null {
        if (side === SideEnum.Both) {
            return null;
        } else {
            return <strong>{shortDescriptionForSide(side)}</strong>;
        }
    }
}
