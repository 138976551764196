import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Addition } from '../../../../../Model/Catalog/Lenses/Addition';
import { DomainObject } from '../../../../../Model/DomainObject';

export type OnClickHandler<T extends DomainObject> = (item: T, event: SyntheticEvent) => void;

interface MiscellaneousItemProps {
    addition: Addition,

    onClick: OnClickHandler<Addition>
}

export class MiscellaneousItem extends React.Component<MiscellaneousItemProps> {
    constructor(props: MiscellaneousItemProps, context: any) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        return <li className="lenses-miscellaneous-item">
            <i className="material-icons">check</i>
            <span>{this.props.addition.name}</span>
            <a className="lenses-miscellaneous-remove material-icons" onClick={this.handleClick}>add_circle</a>
        </li>;
    }

    private handleClick(event: SyntheticEvent) {
        this.props.onClick(this.props.addition, event);
    }
}
