import * as React from 'react';
import { SyntheticEvent } from 'react';
import { AppContext } from '../../../AppContextType';
import { RefractionPair } from '../../../Model/Customer/RefractionPair';
import { ColumnHeaderRow } from './Table/ColumnHeaderRow';
import { HeaderRow } from './Table/HeaderRow';
import { Row } from './Table/Row';

export interface ItemProps {
    refractionPair: RefractionPair,
    isSelected: boolean
    canBeSelected: boolean
    onClick: (refractionPair: RefractionPair, event: SyntheticEvent) => void
}

export class Item extends React.PureComponent<ItemProps> {
    constructor(props: ItemProps, context: AppContext) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        const props = this.props;
        const refractionPair = props.refractionPair;
        const right = refractionPair.right;
        const left = refractionPair.left;
        const canBeSelected = props.canBeSelected;
        const selected = props.isSelected;
        const className = (selected ? '-selected' : '') + (canBeSelected ? '' : ' -locked');

        return <>
            <tbody key={refractionPair.uid} className={className}>
            <HeaderRow refractionPair={refractionPair} isSelected={selected} onClick={this.handleClick}/>
            <ColumnHeaderRow refractionPair={refractionPair} isSelected={selected} onClick={this.handleClick}/>
            <Row refraction={right} refractionPair={refractionPair} isSelected={selected} onClick={this.handleClick}/>
            <Row refraction={left} refractionPair={refractionPair} isSelected={selected} onClick={this.handleClick}/>
            </tbody>
            <tbody key={refractionPair.uid + '-spacer'} className='spacer'>
            <tr>
                <td colSpan={9}>&nbsp;</td>
            </tr>
            </tbody>
        </>;
    }

    private handleClick(event: SyntheticEvent) {
        const props = this.props;
        if (props.canBeSelected) {
            props.onClick(props.refractionPair, event);
        } else {
            console.debug('[Item] Refraction changing is locked');
        }
    }
}
