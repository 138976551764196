import { ra_property } from '@cundd/rest-adapter';
import { BaseModel } from './BaseModel';

export class Picture extends BaseModel {
    @ra_property()
    public type: string;

    @ra_property()
    public key: string;

    @ra_property()
    public path: string;
}
