import { ClassTypeOptions, ra, ra_property } from '@cundd/rest-adapter';
import { Clone } from 'iresults-ts-core';
import { BaseModel } from '../../BaseModel';

@ra(ClassTypeOptions.AddUnknownFields)
export class PriceGroup extends BaseModel implements Clone {
    @ra_property()
    public costPrice: number;

    @ra_property()
    public salesPrice: number;

    @ra_property()
    public salesPriceL2: number;

    @ra_property()
    public recommendedRetailPrice: number;

    public clone<T extends Clone = this>(): this | T {
        const clone = new PriceGroup();

        clone.costPrice = this.costPrice;
        clone.salesPrice = this.salesPrice;
        clone.salesPriceL2 = this.salesPriceL2;
        clone.recommendedRetailPrice = this.recommendedRetailPrice;

        return clone;
    }
}
