import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Favourite } from '../../../Model/Offer/Favourite';
import { Confirm } from '../../Modal/Confirm';
import { Header } from './Box/Header';
import { Table } from './Box/Table';
import { FavouriteViewProps } from './FavouriteViewProps';

export type FavouriteHandler = (favourite: Favourite, event: SyntheticEvent) => void;

interface ViewProps extends FavouriteViewProps {
    onClickRemove: FavouriteHandler,
    onClickLoad: FavouriteHandler,
    onSelect: FavouriteHandler,
    onUnselect: FavouriteHandler,
    selected: boolean,
}

interface FavouriteViewState {
    showModal: boolean
}

export class FavouriteView extends React.Component<ViewProps, FavouriteViewState> {
    constructor(props: ViewProps, context: any) {
        super(props, context);
        this.handleClickLoad = this.handleClickLoad.bind(this);
        this.handleClickRemove = this.handleClickRemove.bind(this);
        this.handleClickSelect = this.handleClickSelect.bind(this);
        this.handleModalClickNo = this.handleModalClickNo.bind(this);
        this.handleModalClickYes = this.handleModalClickYes.bind(this);

        this.state = {
            showModal: false
        };
    }

    public render() {
        const props = this.props;
        const favourite = props.favourite;
        // noinspection SuspiciousTypeOfGuard
        if (!(favourite instanceof Favourite)) {
            const type = typeof favourite === 'object' ? (favourite as object).constructor.name : typeof favourite;
            throw new TypeError(`Property 'favourite' must be an instance of 'Favourite', '${type}' given`);
        }

        return <>
            {this.renderModal(props)}
            <div className="favourite-box">
                <Header onLoad={this.handleClickLoad}
                        onSelect={this.handleClickSelect}
                        onRemove={this.handleClickRemove}
                        selected={props.selected}
                        favourite={props.favourite}/>

                <Table favourite={favourite}/>
            </div>
        </>;
    }

    private renderModal(props: ViewProps) {
        return <Confirm show={this.state.showModal}
                        onCancel={this.handleModalClickNo}
                        onConfirm={this.handleModalClickYes}
                        cancelClassName="favourite-remove-confirm -confirm-no"
                        confirmClassName="favourite-remove-confirm -confirm-yes -danger">
            <p>
                Soll der Favorit <strong>{props.favourite.description}</strong> gelöscht werden?
            </p>
        </Confirm>;
    }

    private handleClickRemove() {
        this.setState({showModal: true});
    }

    private handleClickLoad(event: SyntheticEvent) {
        const props = this.props;
        props.onClickLoad(props.favourite, event);
    }

    private handleClickSelect(event: SyntheticEvent) {
        const props = this.props;
        const favourite = props.favourite;

        if (props.selected) {
            props.onUnselect(favourite, event);
        } else {
            props.onSelect(favourite, event);
        }
    }

    private handleModalClickNo() {
        this.setState({showModal: false});
    }

    private handleModalClickYes(event: SyntheticEvent) {
        const props = this.props;
        props.onClickRemove(props.favourite, event);
        this.setState({showModal: false});
    }
}
