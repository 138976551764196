import { AbstractServiceProvider } from '../../Service/AbstractServiceProvider';
import { EconomyOption } from '../EconomyOption';
import { AggregateInterface } from './AggregateInterface';
import { Result } from './Result';

export class Provider extends AbstractServiceProvider<EconomyOption, Result> implements AggregateInterface {
    get allServices(): EconomyOption[] {
        return this.getResult().allServices;
    }

    get length(): number {
        return this.getResult().length;
    }

    public getService(serviceUid: string | number): EconomyOption | undefined {
        return this.getResult().getService(serviceUid);
    }

    public getExtraMaterialOptions(): EconomyOption[] {
        return this.getResult().getExtraMaterialOptions();
    }

    public getServiceOptions(): EconomyOption[] {
        return this.getResult().getServiceOptions();
    }

    protected getSearchTerm(): string {
        return '';
    }

    protected getResult() {
        return new Result(this.services);
    }
}
