import * as React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { Credentials } from '../../ValueObject/Credentials';

export type CredentialSubmitCallback = (credentials: Credentials) => void;

interface CredentialFormProps {
    onSubmit: CredentialSubmitCallback
}

interface CredentialFormState {
    username: string
    password: string
    error?: string
}

export class CredentialForm extends React.Component<CredentialFormProps, CredentialFormState> {
    constructor(props: CredentialFormProps, context: any) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.state = {
            username: '',
            password: '',
            error: undefined
        };
    }

    public render() {
        return <div className="login-credentials">
            <form onSubmit={this.handleSubmit} className="login-credentials-form">
                {this.renderError()}
                <label>
                    <span className="sr-only">Username</span>
                    <input type="text"
                           value={this.state.username}
                           onChange={this.handleUsernameChange}
                           placeholder="Username"/>
                </label>
                <label>
                    <span className="sr-only">Password</span>
                    <input type="password"
                           value={this.state.password}
                           onChange={this.handlePasswordChange}
                           placeholder="Password"/>
                </label>
                <input type="submit" value="Absenden"/>
            </form>
        </div>;
    }

    private handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.props.onSubmit(new Credentials(this.state.username, this.state.password));
    }

    private handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({username: event.target.value});
    }

    private handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({password: event.target.value});
    }

    private renderError() {
        const error = this.state.error;
        if (!error) {
            return null;
        }

        return <div className="login-credentials-error login-error">{error}</div>;
    }
}
