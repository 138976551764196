import * as React from 'react';
import { ReactNode, SyntheticEvent } from 'react';
import { Header } from './Header';

export enum PopupStyle {
    Overlay = 0,
    Fullscreen = 1,
}

interface PopupProps {
    onDismiss: (event: SyntheticEvent | Event) => void,
    dismissTransitionDuration?: number,
    style?: PopupStyle,
    header?: ReactNode,
}

interface PopupState {
    dismissing: boolean
}

export class Popup extends React.Component<PopupProps, PopupState> {
    public static defaultProps: Partial<PopupProps> = {
        dismissTransitionDuration: 330
    };

    private unmountTimeout: number | NodeJS.Timeout;
    private _domElement: HTMLDivElement | null;

    constructor(props: PopupProps, context: any) {
        super(props, context);
        this.state = {
            dismissing: false
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    public render() {
        const dismissing = this.state.dismissing;
        const className = ['popup'];
        if (!dismissing) {
            className.push('-showing');
        } else {
            className.push('-dismissing');
        }
        const style = this.props.style;
        if (style === PopupStyle.Overlay || style === undefined) {
            className.push('-overlay');
        } else if (style === PopupStyle.Fullscreen) {
            className.push('-fullscreen');
        }

        return (
            <div ref={r => this._domElement = r} className={className.join(' ')}>
                <div className="popup-inner">
                    <Header className="popup-header">
                        {this.props.header}
                        <a role="button" className="popup-dismiss-button" onClick={this.handleDismiss}>
                            <i className="material-icons">close</i>
                        </a>
                    </Header>
                    <div className="popup-content-wrapper">
                        <div className="popup-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="popup-dismiss-area" onClick={this.handleDismiss}/>
            </div>
        );
    }

    public componentDidMount(): void {
        document.addEventListener('keyup', this.handleKeyUp);
        document.documentElement.classList.add('popup-visible');
    }

    public componentWillUnmount(): void {
        document.documentElement.classList.remove('popup-visible');
        clearTimeout(this.unmountTimeout as any);
    }

    public handleDismiss(event: SyntheticEvent | Event) {
        this.setState({dismissing: true});
        document.removeEventListener('keyup', this.handleKeyUp);

        const dismissTimeout = this.props.dismissTransitionDuration || 0;
        setTimeout(
            () => {
                this.checkIfUnmounted();
                document.documentElement.classList.remove('popup-visible');
                this.props.onDismiss(event);
            },
            dismissTimeout
        );
    }

    private checkIfUnmounted() {
        this.unmountTimeout = setTimeout(
            () => console.warn('Popup instance has not been unmounted', this._domElement, this),
            2000
        );
    }

    private handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.handleDismiss(event);
        }
    };
}
