import { ra_property } from '@cundd/rest-adapter';
import { Offer } from '../../Model/Offer/Offer';
import { ExportOfferMeta } from './ExportOfferMeta';

export class ExportOffer {
    @ra_property(Offer)
    public offer: Offer;

    @ra_property(ExportOfferMeta)
    public meta: ExportOfferMeta;

    constructor(offer: Offer, meta: ExportOfferMeta) {
        this.offer = offer;
        this.meta = meta;
    }
}
