function storageAvailable(type: ('localStorage' | 'sessionStorage')) {
    let storage: Storage | null = null;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);

        return true;
    } catch (e) {
        // noinspection PointlessBooleanExpressionJS
        return e instanceof DOMException && (
                // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && (storage as Storage).length !== 0);
    }
}

/**
 * Wrapper around the Browser Storage
 */
export class BrowserStorage {
    private readonly storage: Storage;

    constructor() {
        if (storageAvailable('localStorage')) {
            this.storage = localStorage;
        } else if (storageAvailable('sessionStorage')) {
            this.storage = sessionStorage;
        } else {
            throw new TypeError('No storage available');
        }
    }

    public getItem(namespace: string, uid: string): string | null {
        return this.storage.getItem(namespace + '/' + uid);
    }

    public hasItem(namespace: string, uid: string): boolean {
        return !!this.storage.getItem(namespace + '/' + uid);
    }

    public setItem(namespace: string, uid: string, serialized: string): void {
        this.storage.setItem(namespace + '/' + uid, serialized);
    }
}
