/**
 * Service to fetch exported Offers for a Customer
 */
import { Repository } from '@cundd/rest-adapter';
import { Customer } from '../Model/Customer/Customer';
import { ExportOffer } from './Export/ExportOffer';

export class CustomerOfferService {
    constructor(private readonly exportOfferRepository: Repository<ExportOffer>) {
    }

    public findByCustomer(customer: Customer): Promise<ExportOffer[]> {
        return this.exportOfferRepository.execute(customer.uid).then(r => this.extractResult(r));
    }

    private extractResult<T>(r: T[] | Map<string, T> | T | null): T[] {
        if (Array.isArray(r)) {
            return r;
        }
        if (r instanceof Map) {
            return Array.from(r.values());
        }

        if (r) {
            return [r];
        } else {
            return [];
        }
    }
}
