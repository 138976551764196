import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Offer } from '../../../Model/Offer/Offer';
import { Confirm } from '../../Modal/Confirm';

export interface OfferRowProps {
    offer: Offer
    onClick: (event: SyntheticEvent, offer: Offer) => void
}

interface OfferRowState {
    showModal: boolean
}

export class OfferRow extends React.Component<OfferRowProps, OfferRowState> {
    constructor(props: OfferRowProps, context: any) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
        this.handleModalCancel = this.handleModalCancel.bind(this);
        this.handleModalConfirm = this.handleModalConfirm.bind(this);

        this.state = {
            showModal: false
        };
    }

    public render() {
        const offer = this.props.offer;

        return <tr>
            <td>
                {this.renderConfirm()}
                <button className="icon-button -remove favourite-load" onClick={this.handleClick}>
                    <i className="material-icons">arrow_downward</i>
                </button>
            </td>
            <td>
                {this.renderDate(offer)}
            </td>
            <td>
                {this.renderFrame(offer)}
            </td>
            <td>
                {this.renderVariants(offer)}
            </td>
        </tr>;
    }

    private renderDate(offer: Offer): string {
        return offer.createdAt.toLocaleString();
    }

    private renderFrame(offer: Offer) {
        const frame = offer.frame;
        if (!frame) {
            return '';
        }

        if (frame.isCustomerFrame) {
            return frame.collection.name + ': ' + frame.model.name;
        } else {
            return frame.collection.name + ': ' + frame.model.name + ', ' + frame.colorVariant;
        }
    }

    private renderVariants(offer: Offer): string {
        return '' + (offer.variants ? offer.variants.length : 0);
    }

    private renderConfirm() {
        return <Confirm show={this.state.showModal}
                        message={`Offerte laden? Die aktuelle Offerte wird gelöscht`}
                        onCancel={this.handleModalCancel}
                        onConfirm={this.handleModalConfirm}
                        cancelClassName="favourite-remove-confirm -confirm-no"
                        confirmClassName="favourite-remove-confirm -confirm-yes -danger"/>;
    }

    private handleModalCancel() {
        this.setState({showModal: false});
    }

    private handleModalConfirm(event: SyntheticEvent) {
        const props = this.props;
        props.onClick(event, props.offer);
        this.setState({showModal: false});
    }

    private handleClick() {
        this.setState({showModal: true});
    }
}
