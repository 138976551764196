import { Base } from '../Enum/Base';
import { unwrapNotNull } from '../Helper/unwrap';
import { Refraction } from '../Model/Refraction';
import { RefractionSide } from '../Model/RefractionSide';
import { ResultantPrism } from '../ValueObject/ResultantPrism';

type float = number

function assert(assertion: boolean, error: string) {
    if (!assertion) {
        throw new Error(error);
    }
}

interface NormalizedUserInput {
    prism1: float,
    base1: string,
    prism2: float,
    base2: string,
}

enum DefinedPrism {
    /**
     * Prism+Base 1 and Prism+Base 2 are **not** defined
     */
    None,
    /**
     * Only Prism+Base 1 are defined
     */
    P1,
    /**
     * Only Prism+Base 2 are defined
     */
    P2,
    /**
     * Prism+Base 1 and Prism+Base 2 are defined
     */
    Both
}

/**
 * Prism Service
 *
 * Service to calculate the Resultant Prism
 */
export class PrismService {
    private static _sharedInstance: PrismService;

    /**
     * Return a shared instance
     *
     * @return PrismService
     */
    public static sharedInstance(): PrismService {
        if (!PrismService._sharedInstance) {
            PrismService._sharedInstance = new PrismService();
        }

        return PrismService._sharedInstance;
    }

    /**
     * Calculate the Resultant Prism for the Refraction
     *
     * If `prism2` and `base2` are not set the `prism1` and `base1` will be returned
     *
     * @param {Refraction} refraction
     * @return ResultantPrism
     */
    public calculateResultantPrism(refraction: Refraction): ResultantPrism | undefined {
        const side = refraction.side;
        const definedPrism = this.detectDefinedPrism(refraction);

        // Prism+Base 1 and Prism+Base 2 are not defined
        if (definedPrism === DefinedPrism.None) {
            return undefined;
        }

        // Only Prism+Base 1 are defined
        if (definedPrism === DefinedPrism.P1) {
            return new ResultantPrism(
                refraction.prism1 as number,
                this.convertBaseToDegree(this.convertBaseKeyword(unwrapNotNull(refraction.base1), false) as Base, side)
            );
        }

        // Only Prism+Base 2 are defined
        if (definedPrism === DefinedPrism.P2) {
            return this.calculateResultantPrism(
                new Refraction(
                    refraction.side,
                    refraction.spherical,
                    refraction.cylindrical,
                    refraction.axis,
                    refraction.add,
                    refraction.prism2,  // ▲
                    refraction.base2,   // | Swap Prism+Base 1 with Prism+Base 2
                    refraction.prism1,  // |
                    refraction.base1,   // ▼
                    refraction.vcc,
                    refraction.hsa,
                    refraction.nab,
                )
            );
        }

        const r = this.normalizeUserInput(refraction);
        const prism1 = r.prism1;
        const normalizedBase1 = r.base1;
        const prism2 = r.prism2;
        const normalizedBase2 = r.base2;

        const convertedBase1 = this.convertHorizontalBase(side, normalizedBase1);
        const convertedBase2 = this.convertVerticalBase(normalizedBase2);
        assert(
            convertedBase1 !== convertedBase2,
            `Converted bases should not be the same ("${convertedBase1}" / "${convertedBase2}" vs "${normalizedBase1}" / "${normalizedBase2}")`,
        );
        const $prism = this.calculatePrism(prism1, prism2);
        const $base = this.calculateBase(side, prism1, prism2, convertedBase1, convertedBase2);

        return new ResultantPrism($prism, $base);
    }

    /**
     * Return if a prism is defined for the Refraction
     *
     * @param {Refraction} refraction
     * @return boolean
     */
    public hasPrism(refraction: Refraction): boolean {
        return DefinedPrism.None !== this.detectDefinedPrism(refraction);
    }

    /**
     * Calculate the Resultant Prism for the Refraction
     *
     * If `prism2` and `base2` are not set the `prism1` and `base1` will be returned
     *
     * @param {Refraction} refraction
     * @return ResultantPrism
     */
    private detectDefinedPrism(refraction: Refraction): DefinedPrism {
        const base1 = refraction.base1;
        const base2 = refraction.base2;
        const base1IsDefined = undefined !== base1 && null !== base1;
        const base2IsDefined = undefined !== base2 && null !== base2;

        // Prism+Base 1 and Prism+Base 2 are **not** defined
        if (!base1IsDefined && !base2IsDefined) {
            return DefinedPrism.None;
        }

        // Prism+Base 1 and Prism+Base 2 are defined
        if (base1IsDefined && base2IsDefined) {
            return DefinedPrism.Both;
        }

        // Only Prism+Base 1 are defined
        if (base1IsDefined && !base2IsDefined) {
            return DefinedPrism.P1;
        }

        // Only Prism+Base 2 are defined
        if (!base1IsDefined && base2IsDefined) {
            return DefinedPrism.P2;
        }
        throw new RangeError();
    }

    private normalizeUserInput(refraction: Refraction): NormalizedUserInput {
        const inputBase1Raw = refraction.base1 as string;

        // 'Wurde zuerst Seitenprisma oder Höhenprisma eingegeben?
        const b = this.convertBaseKeyword(inputBase1Raw, true);
        const inputBase1IsVertical = (b && b.isVertical()) || inputBase1Raw === '90' || inputBase1Raw === '270';
        if (inputBase1IsVertical) {
            return {
                prism1: unwrapNotNull(refraction.prism2),
                base1: unwrapNotNull(refraction.base2),
                prism2: unwrapNotNull(refraction.prism1),
                base2: inputBase1Raw,
            };

        } else {
            return {
                prism1: unwrapNotNull(refraction.prism1),
                base1: inputBase1Raw,
                prism2: unwrapNotNull(refraction.prism2),
                base2: unwrapNotNull(refraction.base2),
            };
        }
    }

    private convertVerticalBase(inputBase: string | Base): Base {
        const base = this.convertBaseKeyword(inputBase, true);
        if (base !== undefined) {
            return base;
        }
        if (typeof inputBase !== 'string') {
            const typeOfBase = typeof inputBase;
            const description = (typeOfBase !== 'object' ? typeOfBase : (inputBase === null ? 'NULL' : (inputBase as object).constructor.name));
            throw new TypeError(`Could not build a Base instance from type '${description}'`);
        }

        if (inputBase === '90') {
            return Base.up();
        }
        if (inputBase === '270') {
            return Base.down();
        }

        return Base.variant(inputBase);
    }

    private calculatePrism($prism1: float, $prism2: float): float {
        return Math.sqrt((Math.pow($prism1, 2)) + (Math.pow($prism2, 2)));
    }

    private convertHorizontalBase(side: string, inputBase: string | Base): Base {
        const base = this.convertBaseKeyword(inputBase, true);
        if (base !== undefined) {
            return base;
        }

        if (typeof inputBase !== 'string') {
            throw new TypeError(`Could not build a Base instance from type '${typeof inputBase}'`);
        }

        if (side === RefractionSide.Right) {// Refraction::SIDE_RIGHT
            if (inputBase === '0') {
                return Base.inside();
            }
            if (inputBase === '180') {
                return Base.outside();
            }
        } else {
            if (inputBase === '180') {
                return Base.inside();
            }
            if (inputBase === '0') {
                return Base.outside();
            }
        }

        return Base.variant(inputBase);
    }

    private convertBaseKeyword(base: string | Base, graceful: boolean): Base | undefined {
        if (base instanceof Base) {
            return base;
        }
        try {
            return Base.fromKeyword(base);
        } catch (exception) {
            if (graceful) {
                return undefined;
            } else {
                throw exception;
            }
        }
    }

    private calculateBase(side: RefractionSide, prism1: float, prism2: float, base1: Base, base2: Base): float {
        // 'Basislage
        let calculatedBase = Math.atan(prism2 / prism1);
        // Convert from arc to degree
        calculatedBase = calculatedBase * (180 / Math.PI);

        if (side === RefractionSide.Right) {
            return this.transformBaseForRight(calculatedBase, base1, base2);
        } else if (side === RefractionSide.Left) {
            return this.transformBaseForLeft(calculatedBase, base1, base2);
        } else {
            throw new RangeError(`Invalid side "${side}" given`);
        }
    }

    private convertBaseToDegree(base: Base, side: RefractionSide) {
        this.assertBaseInstance(base);
        if (side === RefractionSide.Right) {
            switch (base.value) {
                case Base.BASE_OUTSIDE:
                    return 180;
                case Base.BASE_INSIDE:
                    return 0;
                case Base.BASE_UP:
                    return 90;
                case Base.BASE_DOWN:
                    return 270;
            }
        } else {
            switch (base.value) {
                case Base.BASE_OUTSIDE:
                    return 0;
                case Base.BASE_INSIDE:
                    return 180;
                case Base.BASE_UP:
                    return 90;
                case Base.BASE_DOWN:
                    return 270;
            }
        }
        throw new TypeError(`Unexpected base value "${base.value}" detected`);
    }

    private assertBaseInstance(base: Base | any) {
        if (!(base instanceof Base)) {
            throw new TypeError(`Expected argument to be a valid Base instance`);
        }
    }

    private transformBaseForRight(calculatedBase: float, base1: Base, base2: Base): float {
        let base: undefined | float = undefined;
        if (base1 === Base.inside() && base2 === Base.up()) {
            base = calculatedBase;
        }

        if (base1 === Base.outside() && base2 === Base.up()) {
            base = 180 - calculatedBase;
        }

        if (base1 === Base.outside() && base2 === Base.down()) {
            base = 180 + calculatedBase;
        }

        if (base1 === Base.inside() && base2 === Base.down()) {
            base = 360 - calculatedBase;
        }

        if (undefined === base) {
            throw new TypeError(`Could not calculate base for bases "${base1}" and "${base2}"`);
        }

        return base;
    }

    private transformBaseForLeft(calculatedBase: float, base1: Base, base2: Base): float {
        let $base: number | undefined = undefined;
        if (base1 === Base.inside() && base2 === Base.up()) {
            $base = 180 - calculatedBase;
        }

        if (base1 === Base.outside() && base2 === Base.up()) {
            $base = calculatedBase;
        }

        if (base1 === Base.outside() && base2 === Base.down()) {
            $base = 360 - calculatedBase;
        }

        if (base1 === Base.inside() && base2 === Base.down()) {
            $base = 180 + calculatedBase;
        }
        if (undefined === $base) {
            throw new TypeError(`Could not calculate base for bases "${base1}" and "${base2}"`);
        }

        return $base;
    }
}
