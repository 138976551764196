import { Offer } from '../Model/Offer/Offer';
import { OfferStorage } from './OfferStorage';

const identifier = 'latest';

export class LatestOfferStorage extends OfferStorage {
    public load(): Promise<Offer | null> {
        return super.load(identifier);
    }

    public has(): Promise<boolean | null> {
        return super.has(identifier);
    }

    protected getInstanceUid(instance: Offer): string {
        return identifier;
    }
}
