import * as React from 'react';
import { AppHeader } from '../AppHeader';
import { AbstractAutoLoadingView } from './AbstractAutoLoadingView';
import { LowerSection } from './LowerSection';
import { MiddleSection } from './MiddleSection';
import { OfferInfo } from './OfferInfo';
import { UpperSection } from './UpperSection';

export class NewView extends AbstractAutoLoadingView {
    public renderContent() {
        return <>
            <AppHeader big={false}/>
            <main className="offer-new full-width">
                <OfferInfo/>
                <UpperSection/>
                <MiddleSection/>
                <LowerSection/>
            </main>
        </>;
    }
}
