import * as React from 'react';
import { Redirect } from 'react-router';
import { AppContext, AppContextType } from '../../AppContextType';
import { Config } from '../../Config';

export enum ComponentState {
    none,
    ok,
    redirect
}

export interface AutoLoadingViewState {
    componentState: ComponentState
}

/**
 * View that will check if a User is logged in and will otherwise try to load the latest Offer from the
 * browser storage
 */
export abstract class AbstractAutoLoadingView<P = {}, S extends AutoLoadingViewState = AutoLoadingViewState>
    extends React.Component<P, S> {
    public static contextType = AppContextType;
    public context: AppContext;

    constructor(props: P, context: any) {
        super(props, context);

        this.state = {
            componentState: ComponentState.none
        } as S;
    }

    public abstract renderContent(): JSX.Element;

    public render() {
        const componentState = this.state.componentState;
        if (componentState === ComponentState.redirect) {
            const config = new Config();
            const basePath = config.basePath;

            return <Redirect to={`${basePath}/`}/>;
        }

        if (componentState === ComponentState.ok) {
            return this.renderContent();
        }

        return null;
    }

    public componentDidMount(): void {
        const context = this.context;
        if (context.login) {
            // If a user is logged in we can proceed normally
            setTimeout(
                () => {
                    this.setState({componentState: ComponentState.ok});
                },
                10
            );
        } else {
            // If **no** user is logged in try to load the last Offer from the browser storage
            context.handler.latestOfferStorage.load().then(offer => {
                if (offer) {
                    console.info(`[AbstractAutoLoadingView] Did load Offer #${offer.guid}`, offer);
                    context.handler.setOffer(offer);
                    this.setState({componentState: ComponentState.ok});
                } else {
                    this.setState({componentState: ComponentState.redirect});
                }
            });
        }
    }
}
