import { Frame } from '../../../Model/Catalog/Frame';
import { PriceModel } from '../../../Model/Offer/PriceModel';
import { AbstractPriceModelBox, PriceModelBoxState } from './AbstractPriceModelBox';

export class Lifetime extends AbstractPriceModelBox {
    protected get priceModel(): PriceModel {
        return PriceModel.lifetime;
    }

    protected renderPopup(frame: Frame, state: PriceModelBoxState): JSX.Element | null {
        return null;
    }
}
