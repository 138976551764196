import * as React from 'react';
import { Favourite } from '../../../../Model/Offer/Favourite';
import { Entry } from '../../../../Model/Offer/Favourite/Entry';
import { shortDescriptionForSide, SideEnum } from '../../SideEnum';
import { FavouriteViewProps } from '../FavouriteViewProps';

const getNameOrEmpty = (entry: Entry | undefined): string => {
    return entry ? entry.description : '';
};

export class LensesAndCoatingRow extends React.PureComponent<FavouriteViewProps> {
    public render() {
        const favourite = this.props.favourite;

        if (favourite.symmetrical) {
            return <>
                {this.renderHeader()}
                {this.renderSymmetrical(favourite)}
            </>;
        } else {
            return <>
                {this.renderHeader()}
                {this.renderAsymmetrical(favourite)}
            </>;
        }
    }

    private renderHeader() {
        return <tr className="header">
            <th colSpan={2}>Glas &amp; Entspiegelung</th>
        </tr>;
    }

    private renderSymmetrical(favourite: Favourite) {
        const captionClassName = 'caption';

        return <>
            <tr>
                <td className={captionClassName} colSpan={2}>
                    {getNameOrEmpty(favourite.rightLenses)}
                </td>
            </tr>
            <tr>
                <td className={captionClassName} colSpan={2}>
                    {getNameOrEmpty(favourite.rightCoating)}
                </td>
            </tr>
        </>;
    }

    private renderAsymmetrical(favourite: Favourite) {
        const captionClassName = 'caption';

        const hasRight = favourite.rightLenses || favourite.rightCoating;
        const hasLeft = favourite.leftLenses || favourite.leftCoating;

        if (hasRight && hasLeft) {
            return <>
                <tr>
                    <th>{shortDescriptionForSide(SideEnum.Right)}</th>
                    <th>{shortDescriptionForSide(SideEnum.Left)}</th>
                </tr>
                <tr>
                    <td className={captionClassName}>
                        {getNameOrEmpty(favourite.rightLenses)}
                    </td>
                    <td className={captionClassName}>
                        {getNameOrEmpty(favourite.leftLenses)}
                    </td>
                </tr>
                <tr>
                    <td className={captionClassName}>
                        {getNameOrEmpty(favourite.rightCoating)}
                    </td>
                    <td className={captionClassName}>
                        {getNameOrEmpty(favourite.leftCoating)}
                    </td>
                </tr>
            </>;
        } else if (hasRight) {
            return this.renderSingleColumn(favourite.rightLenses, favourite.rightCoating, SideEnum.Right);
        } else if (hasLeft) {
            return this.renderSingleColumn(favourite.leftLenses, favourite.leftCoating, SideEnum.Left);
        } else {
            return null;
        }
    }

    private renderSingleColumn(lenses: Entry | undefined, coating: Entry | undefined, side: SideEnum) {
        const captionClassName = 'caption';

        return <>
            <tr>
                <th colSpan={2}>{shortDescriptionForSide(side)}</th>
            </tr>
            <tr>
                <td className={captionClassName} colSpan={2}>
                    {getNameOrEmpty(lenses)}
                </td>
            </tr>
            <tr>
                <td className={captionClassName} colSpan={2}>
                    {getNameOrEmpty(coating)}
                </td>
            </tr>
        </>;
    }
}
