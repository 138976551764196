import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Question } from './Question';

export interface ConfirmProps {
    show: boolean
    message?: string
    confirmLabel?: string
    cancelLabel?: string
    onConfirm: (event: SyntheticEvent) => void
    onCancel: (event: SyntheticEvent) => void
    confirmClassName?: string
    cancelClassName?: string

}

export class Confirm extends React.Component<ConfirmProps> {
    public render() {
        const props = this.props;
        if (!props.show) {
            return null;
        }

        const cancelClassName = props.cancelClassName || 'confirm-cancel';
        const confirmClassName = props.confirmClassName || 'confirm-confirm';

        return <Question
            show={true}
            answer1Label={props.cancelLabel || 'Nein'}
            answer2Label={props.confirmLabel || 'Ja'}
            onAnswer1={props.onCancel}
            onAnswer2={props.onConfirm}
            answer1ClassName={cancelClassName}
            answer2ClassName={confirmClassName}
        >{props.message || props.children}</Question>;
    }
}
