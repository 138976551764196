import * as React from 'react';
import { Offer } from '../../../../Model/Offer/Offer';
import { Currency } from '../../../Formatter/Currency';

interface ViewProps {
    offer: Offer
}

export class MaterialFeeRow extends React.PureComponent<ViewProps> {
    public render() {
        const props = this.props;
        const offer = props.offer;

        if (offer.materialFee) {
            return <tr>
                <td className="caption">Material- / Dienstleistungs&shy;aufwand</td>
                <td className="value">
                    <Currency value={offer.materialFee} trailingZeros={true}/>
                </td>
            </tr>;
        } else {
            return null;
        }
    }
}
