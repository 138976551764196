import { ClassTypeOptions, ra } from '@cundd/rest-adapter';
import { AbstractCatalogModel } from './AbstractCatalogModel';
import { CatalogModelInterface } from './CatalogModelInterface';
import { Material } from './Material';

@ra(ClassTypeOptions.AddUnknownFields)
export class Model extends AbstractCatalogModel implements CatalogModelInterface {
    public materialUid: string;
    public male: string;
    public female: string;
    public collectionUid: string;
    public material: Material;
    public name: string;
    public price: number;
}
