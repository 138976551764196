import * as React from 'react';
import { ReactChild } from 'react';
import { SortableContainer as HocSortableContainer, SortableContainerProps, SortableElement } from 'react-sortable-hoc';

export interface SortingContainerProps extends SortableContainerProps {
    className?: string
    childClassName?: string
}

interface MetaContainerProps extends SortingContainerProps {
    children: ReactChild[]
}

interface MetaItemProps extends SortingContainerProps {
    child: ReactChild
}

const SortableItem = SortableElement<MetaItemProps>(
    (props: MetaItemProps) => <div className={props.childClassName}>{props.child}</div>
);

const SortableContainerMeta = HocSortableContainer<MetaContainerProps>((props: MetaContainerProps) => {
    const {children, ...forwardProps} = props;

    return (
        <div className={props.className}>
            {children.map((child: any, index: number) => (
                <SortableItem key={`item-${index}`} index={index} child={child} {...forwardProps}/>
            ))}
        </div>
    );
});

export class SortableContainer extends React.Component<SortingContainerProps, {}> {
    public render() {
        const {children, ...forwardProps} = this.props;
        const preparedChildren: ReactChild[] = React.Children.map(children, child => child as ReactChild);

        return <SortableContainerMeta children={preparedChildren} axis="xy" {...forwardProps} />;
    }
}
